.payroll{
    @extend .pr-4;
    .payroll_filter_summary{
        @extend .fullWidth;
        @extend .d-flex;

        @media screen and (max-width: 1536px){
            .payroll_period{ max-width: 50%}
            .payroll_person{max-width: 50%}
            .payroll_summary{max-width: 50%}
            .payroll_period, .payroll_person, .payroll_summary{flex: 0 0 50%; margin-bottom: 1.5rem;}
        }

        // .payroll_filter{
        //     @extend .fullWidth;
        // }

        // .payroll_summary{
        //     @extend .fullWidth;
        //     max-width: 37rem;
        //     @extend .pl-4;
        // }

    }

    .payroll_summary_list{
        @extend .fullWidth;
        @extend .d-flex;
        h2{
            span{
                @extend .font_16;
                @extend .poppins_500;
            }
        }
        .payroll_options{
            ul{
                white-space: nowrap;
            }
        }
    }
    
}

.payroll_report{

    .payroll_report_header{
        @extend .fullWidth;
        display: flex;

        @media screen and (max-width: 768px) {
            flex-direction: column;
        }


        .back_to_payroll{
            min-width: 10rem;
            @media screen and (max-width: 768px) {
                margin-bottom: 2rem;
            }

            a{
                width: 6rem;
                height: 6rem;
                background: $gray;
                color: $black;
                @extend .font_22;

                @include d_flex_center;
                @extend .radius_full;

                &:hover{
                    color: $white;
                    background: $red_highlighted;
                }
            }
        }
        .payroll_report_filter{
            width: 100%;
        }
    }

    .generate_payroll{
        width: auto;
        @extend .d-inline-block;
    }
    h2{
        span{
            @extend .font_16;
            @extend .poppins_500;
        }
    }
}

.payroll_options{
    ul{
        text-align: right;
        li{
            margin-bottom: 1rem;
            white-space: nowrap;
            display: flex;
            align-items: center;
            justify-content: end;
        }
    }
    &.student_select{
        width: 62rem;
    }
}

.payroll_header {
    display: flex;
    justify-content: space-between;
    width: 100%;
    @media screen and (max-width: 425px) {
        flex-direction: column;
    }
    .btn_primary {
        height: 40px;
        width: 16rem;
    }
}

.margin{
    .get_margin {
        @media screen and (max-width: 425px) {
            margin-bottom: 10px;
        }
    }
}

.apr_div {
    display: flex;
    align-items: end;
}