.assigned_student_task {
  .card {
    background: $white;
    border: 0.1rem solid $light_gray  !important;
    padding: 1rem;

    &>ul {
      height: 30rem;
      overflow-y: auto;

      @media screen and (max-width: 767px) {
        height: 25rem;
      }

      @media screen and (max-width: 575px) {
        height: auto;
      }
    }
  }

  .assign_student {
    @extend .fullWidth;
    @extend .position-relative;

    [type="checkbox"] {
      @extend .position-absolute;
      left: 0;
      right: 0;
      top: 0;
      width: 100%;
      bottom: 0;
      height: 100%;
      opacity: 0;
    }

    .fullWidth {
      @extend .p-2;
      @extend .mb-2;
      @extend .radius_10;
    }

    [type="checkbox"]:checked+.fullWidth {
      background: $light_gray;
    }
  }
}

/* Calendar Design */
.rbc-calendar {
  .rbc-today {
    background: $white;
  }

  .rbc-time-header {
    .rbc-label {
      background: $red_highlighted;
      @include d_flex_center;
      color: $white;
      @extend .poppins_600;
      @extend .font_18;

      @media screen and (min-width: 1200px) {
        min-width: auto !important;
        max-width: 100% !important;
        width: 13rem !important;
      }
    }

    .rbc-today {
      background: $gray;
    }

    .rbc-row {
      min-height: 3rem;
    }
  }

  .rbc-time-content {
    .rbc-time-gutter {
      background: $gray;
    }

    .rbc-timeslot-group {
      border: 0;
      min-height: 7rem;
      min-width: 13rem;

      @media screen and (max-width: 1199px) {
        min-width: 1rem;
      }
    }

    .rbc-time-slot {
      @include d_flex_center;

      &:last-child {
        display: none;
      }
    }
  }

  .rbc-month-row {
    display: -ms-flexbox;
    display: flex;
    position: relative;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex: 1 0;
    flex: 1 0;
    -ms-flex-preferred-size: 0px;
    flex-basis: 0px;
    overflow: unset;
    min-height: 13rem;
  }

  .rbc-month-header {
    background: $gray;

    .rbc-header {
      padding: 15px 3px;
    }
  }

  .rbc-events-container {
    @extend .mr-0;

    .rbc-event {
      background-color: transparent;
      @extend .p-0;
      @extend .cursor_pointer;
      @extend .border-0;
    }

    .rbc-event-label {
      @extend .d-none;
    }

    .rbc-event-content {
      height: 100%;
      @include d_flex_center;

      &>div.fullWidth {
        height: 100%;

        .cal_label {
          height: 100%;
          @extend .fullWidth;
          @include d_flex_center;
          flex-direction: column;
          color: $black;

          span {
            @extend .d-flex;
            @extend .align-items-center;
          }
        }
      }
    }
  }
}

.lesson_list {
  max-height: 31rem;
  overflow-y: auto;
  padding-right: 0.5rem;
  padding-left: 0;

  .form-check {
    padding-left: 0;

    .form-check-label {
      padding-left: 0;
    }
  }

  .custom-control-label {
    padding: 1rem 1rem 1rem 4rem;
    @extend .radius_10;
    @extend .cursor_pointer;
    @extend .fullWidth;
    @extend .mb-2;

    &:before {
      left: 1rem;
      top: 1.3rem;
    }

    &:after {
      left: 0.9rem;
      top: 1.2rem;
    }
  }

  [type="checkbox"]:checked+.custom-control-label {
    background: rgba($red_highlighted, 0.1);
  }
}

.recuring_days_listing {
  li {
    &:nth-child(odd) {
      .recuring_days {
        background: rgba($light_orange, 0.2);
        @extend .d-flex;
        @extend .align-items-center;
        @extend .no_wrap;
      }
    }

    &:nth-child(even) {
      .recuring_days {
        background: rgba($border_green, 0.3);
        @extend .d-flex;
        @extend .align-items-center;
        @extend .no_wrap;
      }
    }

    .recuring_days {
      @extend .p-4;
      @extend .mb-4;
      @extend .radius_10;

      .recoring_day {
        @extend .font_20;
      }

      .action {
        a {
          color: $black;

          &:hover {
            color: $red_highlighted;
          }
        }
      }
    }
  }
}

// Full Calendar
.fc-theme-standard {
  @extend .position-relative;
  z-index: 0;
}

.rbc_header {
  .next_prev_days {
    display: flex;
    align-items: center;
    position: relative;

    .prev_day,
    .next_day {
      @extend .font_20;
      @extend .border-0;
      line-height: 2rem;
      background: transparent;
      @include transition(all 0.3s ease-in-out);

      &:hover {
        color: $red_highlighted;
      }
    }

    .show_day {
      @extend .font_20;

      @media screen and (max-width: 1536px) {
        font-size: 1.6rem;
      }

      & img+div {
        position: absolute;
        z-index: 9;
        margin-top: 1rem;
      }
    }

    @media screen and (max-width: 1366px) {
      margin-bottom: 2rem;
    }
  }

  .assigned_scheduling+div {
    position: absolute;
    top: 2.5rem;
    z-index: 9;
    margin-top: 1rem;
  }

  .month_day_week {
    border: 0.1rem solid $light_gray;
    @extend .radius_50;
    display: inline-block;

    button,
    a {
      @extend .border-0;
      background: transparent;
      padding: 0.8rem 2.5rem;
      min-width: 10rem;
      @extend .poppins_600;
      @extend .d-inline-block;
      @extend .text-center;
      color: $black;

      &.active {
        background: $red_highlighted;
        color: $white;
        @extend .radius_50;
      }

      @media screen and (max-width: 1440px) {
        padding: 0.8rem 0.5rem;
        min-width: 8rem;
      }
    }

    @media screen and (max-width: 1536px) {
      // zoom: 0.8;
      @include zoom(0.8);
    }

    @media screen and (max-width: 1366px) {
      margin-bottom: 2rem;
    }
  }

  .open_block_view {
    .badge {
      color: $active_inactive_btn_text_color;
      padding: 0.7rem 1.5rem;
      @extend .font_14;
      @extend .poppins_300;
      @extend .radius_5;
      letter-spacing: 0.05rem;

      @media screen and (max-width: 1536px) {
        // zoom: 0.9;
        @include zoom(0.9);
      }
    }

    .badge-danger {
      color: $white;

      @media screen and (max-width: 1536px) {
        // zoom: 0.9;
        @include zoom(0.9);
      }
    }

    .custom-switch {
      @include custom-switch;
    }

    .view_by {
      select {
        width: 25rem;
      }

      @media screen and (max-width: 1536px) {
        // zoom: 0.9;
        @include zoom(0.9);
      }

      @media screen and (max-width: 576px) {
        width: 100%;
        justify-content: center;
      }
    }

    @media screen and (max-width: 1366px) {
      margin-bottom: 2rem;
    }

    @media screen and (max-width: 576px) {
      flex-wrap: wrap;
      white-space: unset;
      justify-content: center;
    }
  }

  @media screen and (max-width: 1366px) {
    flex-wrap: wrap;
    justify-content: space-around;
  }
}
.weeklySchedual {
  .fc-timegrid-slots tr,
  .fc-daygrid-slots tr {
  height: 2.75rem !important;
  }
}

.fc {

  .fc-timegrid-axis-frame,
  .fc-daygrid-axis-frame {
    justify-content: center !important;
  }

  .fc-scrollgrid-sync-inner {
    @include d_flex_center;
    min-height: 7rem;
  }

  .fc-timegrid-slots tr,
  .fc-daygrid-slots tr {
    height: 5.5rem;
  }

  .fc-timegrid-slot-label,
  .fc-daygrid-slot-label {
    background: $gray;
  }

  .fc-timegrid-slot-label,
  .fc-daygrid-slot-label {
    border: none;
  }

  .fc-timegrid-slot-lane,
  .fc-daygrid-slot-lane {
    border-bottom: 0.2rem dashed $light_gray  !important;
  }

  .fc-timegrid-axis-frame,
  .fc-daygrid-axis-frame {
    @include d_flex_center;
    background: $red_highlighted;

    a {
      color: $white;
    }
  }

  .fc-day-today>div {
    background: $white  !important;
  }

  .fc-timegrid-event,
  .fc-daygrid-event {
    padding-left: 0.7rem;
    padding-top: 0.7rem;
    border-radius: 0 1.5rem 1.5rem 0;
    white-space: nowrap;
    overflow: hidden;

    &:before {
      content: "";
      height: 102%;
      right: -0.1rem;
      top: -0.1rem;
      background: rgba($white, 0.6);
      width: calc(100% - 0.4rem);
      @extend .position-absolute;
    }

    .fc-event-time {
      @extend .d-flex;
      color: $black;
      font-size: 11.5px;
      min-width: 10rem;
      align-items: center;

      &:before {
        content: "";
        background: url(../images/icon_clock_black.svg) no-repeat center;
        @extend .d-inline-block;
        background-size: 1.7rem;
        height: 1.6rem;
        width: 1.6rem;
        margin-right: 0.4rem;
      }

      // &::after {
      //   content: "";
      //   background: url(../images/xmark-solid.svg) no-repeat center;
      //   @extend .d-inline-block;
      //   background-size: 1.7rem;
      //   height: 1.6rem;
      //   width: 1.6rem;
      //   margin-right: 0.4rem;
      // }
    }

    .fc-event-title {
      color: $black;
      @extend .text-capitalize;
      font-weight: 600;
      font-size: 1.6rem;
    }
  }

  .fc-daygrid-day-frame {
    display: flex;
    flex-direction: column;

    .fc-daygrid-day-events {
      width: 100%;
      text-align: left;
    }

    .fc-daygrid-event {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      width: 100%;
      background: rgba(0, 0, 0, 0.6);

      .fc-daygrid-event-dot {
        display: none;
      }

      .fc-event-time {
        position: relative;
        z-index: 1;
      }

      .fc-event-title {
        position: relative;
        z-index: 1;
        white-space: normal;
        padding-right: 0.5rem;
      }
    }
  }
}

.weekly_assigned_student {
  @media screen and (max-width: 767px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}

/* Assigned / Unassigned Student */
.assigned_unassigned_student {
  width: 21rem;
  background: #ffffff;
  box-shadow: 0 0.4rem 5rem rgba(163, 163, 163, 0.25);
  border-radius: 1rem;
  padding: 1rem;

  @media screen and (max-width: 767px) {
    width: 100%;
    margin-bottom: 3rem;
  }

  .student_list_tab {
    // display: flex;
    // text-align: center;
    // align-items: center;
    // justify-content: space-between;

    li {
      font-size: 1.4rem;
      line-height: 2.2rem;
      flex: 0 0 50%;
      border-bottom: 0.1rem solid #ddd;
      padding: 1rem 0.5rem;
      @extend .poppins_600;
      @extend .cursor_pointer;
      @extend .d-flex;
      @extend .align-items-center;
      @extend .justify-content-space-between;
      // &:hover{
      //     background-color: $red_highlighted;
      //     color: $white;
      //     @extend .radius_5;
      // }

      &.active {
        background-color: $red_highlighted;
        color: $white;
        @extend .radius_5;

        i {
          transform: rotate(90deg);
        }
      }
    }
  }

  .student_list {
    display: none;

    &.active {
      display: block;
      height: 25rem;
      overflow: auto;

      @media screen and (max-width: 767px) {
        height: 17rem;
      }
    }

    li {
      margin-bottom: 1rem;
      word-break: break-all;
    }
  }
}

.assigned_unassigned_student+#cal {
  width: calc(100% - 21rem);
  margin-left: 3rem;

  @media screen and (max-width: 767px) {
    width: 100%;
    margin: 0;
  }
}

/* no_data_found */
.no_data_found {
  p {
    @extend .font_26;
    @extend .poppins_600;
    line-height: 3.9rem;
  }
}

.fc .fc-scrollgrid {
  table-layout: unset !important;
  @extend .table-responsive;
}

.fc-event-main {
  p {
    margin: 0;

    span {
      img {
        @extend .d-inline-block;
        background-size: 1.7rem;
        height: 1.6rem;
        width: 1.6rem;
        margin-right: 0.4rem;
      }
    }

    .time_picker {
      &::after {
        content: "";
        background: url(../images/xmark-solid.svg) no-repeat center !important;
        @extend .d-inline-block;
        background-size: 1.7rem;
        height: 1.6rem;
        width: 1.6rem;
        float: right;
        margin-right: 0.4rem;
        margin-top: 5px;

        @media (max-width:1200px) {
          margin-right: 5.4rem;
          margin-top: 16px;

        }
      }
    }

    .process_picker {
      &::after {
        content: "";
        background: url(../images/triangle-exclamation-solid.svg) no-repeat center !important;
        @extend .d-inline-block;
        background-size: 1.7rem;
        height: 1.6rem;
        width: 1.6rem;
        float: right;
        margin-right: 0.4rem;
        margin-top: 5px;

        @media (max-width:1200px) {
          margin-right: 5.4rem;
          margin-top: 16px;

        }
      }
    }

    .success_picker {
      &::after {
        content: "";
        background: url(../images/check-solid.svg) no-repeat center !important;
        @extend .d-inline-block;
        background-size: 1.7rem;
        height: 1.6rem;
        width: 1.6rem;
        float: right;
        margin-right: 0.4rem;
        margin-top: 5px;

        @media (max-width:1200px) {
          margin-right: 5.4rem;
          margin-top: 16px;

        }
      }
    }
  }
}

.fc-event-main {
  cursor: pointer;
  margin-top: -0.6rem;

  .half_time_zone {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: black;

    p {
      font-size: 1rem;
      line-height: 1.4rem;
    }
  }
}

.fc-timegrid-event .fc-event-main .clock_img {
  display: none;
}

.fc-timegrid-event .fc-event-main .success_picker::after {
  content: "";
  background: unset;
}

.fc-timegrid-event .fc-event-main .time_picker::after {
  content: "";
  background: unset;
}

.fc-col-header {
  thead {
    .fc-col-header-cell-cushion {
      font-size: 18px;
      overflow-x: hidden;
      text-overflow: ellipsis;
    }
  }
}
// .fc-scrollgrid {
//   min-width: 110rem;
//   overflow-x: auto;
// }
// .fc-direction-ltr{
//   overflow-x: auto;
// }
// .fc-timegrid-body{
//   table{
//     width: 100% !important;
//     float: left;
//   }
// }
// .fc-timegrid-cols{
//   table{
//     width: 100% !important;
//     float: left;
//   }
// }
.fc-timegrid-col-frame {
  .fc-timegrid-bg-harness {
    .half_time_zone {
      p {
        @media screen and(max-width: 767px) {
          opacity: 0;
        }
      }
    }
  }
}

.fc-timegrid-col-frame {
  .fc-timegrid-bg-harness {
    .fc-bg-event {
      p {
        @media screen and(max-width: 767px) {
          opacity: 0 !important;
        }
      }
    }
  }
}

.scrolled > thead {
  position: fixed;
  top: 0;
  background-color: #fff; 
  z-index: 999;
  @media screen and(max-width: 990px) {
    top: 54px;
  }
}