.students {

    .heading_area {
        @extend .heading_area;

        
        justify-content: space-between;
        .sort_by{
            max-width: 100%;
            justify-content: flex-start;
            .inner_search{
                
                @media screen and (max-width: 767px) {

                    order: unset;
                 
                       
                   
                }
                @media screen and (max-width: 414px) {
                    margin-bottom: 1rem;
                }
                
            }
            
        
            .sort_by select {
                max-width: 50rem;
               
            }
            select{
               

                @media screen and (max-width: 414px) {
                    margin-bottom: 1rem;
                }
            }
           
        }

    }

    .card_list {
        @extend .card_list;

        &.students_list {
            h4 {
                @extend .mt-3;
            }


            .student_details_part {
                text-align: left;
                padding-left: 2rem;

                h2 {
                    span {
                        @extend .font_14;
                        @extend .poppins_500;
                    }
                }
            }
        }
    }

}

.promotion_info {
    input {
        padding-left: 3rem;
    }

    span {
        top: .9rem;
        left: 2rem;
        position: absolute;
    }
}

.chart_student {
    .apexcharts-theme-light {
        width: 100% !important;
        max-width: 1000px !important;
        margin: auto;
    }
}