.view_profile {
    width: calc(100% - 45rem);
    @media screen and (max-width: 1680px){
        width: calc(100% - 40rem);
    }
    @media screen and (max-width: 1536px){
        width: calc(100% - 0rem);
    }

    h2{margin-bottom: 2rem;}

    .plandetail{
        display: flex;
        flex-direction: column;
    }

    .card{
        @extend .border-0;
        padding: 3.5rem;

        @media screen and (max-width: 768px){ padding: 3.5rem 1.5rem; }

        .sub_admin_profile_pic{
            position: relative;
            width: 12rem;
            @media screen and (max-width: 576px){ 
                width: 100%; 
                text-align: center;
                margin-bottom: 1.5rem;
            }

            img{
                @extend .profile_image_border_n_shadow;
                @extend .radius_full;
                @include img_object_fit_center;
            }
            img + [type="file"]{
                position: absolute;
                left: 0;
                top: 0;
                right: 0;
                bottom: 0;
                opacity: 0;
                width: 12rem;
                height: 12rem;
                @extend .radius_full;
            }
        }
        .heading_text{
            @extend .font_22;
            @extend .poppins_600;
            @extend .fullWidth;
            @extend .text-capitalize;
            line-height: 3.3rem;
            color: $black;

            span{
                color: $red_highlighted;
            }
        }
        .sub_admin_details{
            width: calc(100% - 12rem);
            @media screen and (max-width: 576px){ 
                width: 100%; 
                text-align: center;
                padding-left: 0 !important;
            }
        }
        p{
            color: $black;
            span{
                color: $light_black;
            }
        }

    }
}

.member_viewProfile{
    @media screen and (max-width: 767px) {
        flex-direction: column;
    }
}