.report {
    .report_type {
        @include boxShadow(0 0 1rem rgba($dark_black, .075));
        @extend .radius_20;
        padding: 4rem;
        @extend .mb-5;
        @extend .d-flex;

        @media screen and (max-width: 768px) {
            padding: 2rem 1rem;
        }

        @media screen and (max-width: 576px) {
            flex-direction: column;
            text-align: center;
        }
    }

    .reporting_icon {
        width: 20rem;
        @include d_flex_center;

        img {
            max-width: 100%;
        }

        @media screen and (max-width: 1536px) {
            width: 15rem;
        }

        @media screen and (max-width: 576px) {
            width: 100%;
            margin-bottom: 3rem;
        }
    }

    .reporting_para {
        width: calc(100% - 20rem);

        @media screen and (max-width: 1536px) {
            width: calc(100% - 15rem);
        }

        @media screen and (max-width: 576px) {
            width: 100%;
        }

        p {
            min-height: 15.5rem;
        }
    }
}

.attendance_details {

    @media screen and (max-width: 1640px) {
        // zoom: .9;
        @include zoom(.9);
    }

    @media screen and (max-width: 1440px) {
        // zoom: .8;
        @include zoom(.8);
    }

    @media screen and (max-width: 991px) {
        flex-wrap: wrap;
    }

    @media screen and (max-width: 576px) {
        // zoom: 1;
        @include zoom(1);
    }

    .teacher_profile {
        background: $maroon;
        max-width: 30rem;
        min-height: 25rem;
        padding: 3rem 0 1.5rem;
        @extend .radius_10;

        @media screen and (max-width: 1440px) and (min-width: 992px) {
            max-width: 25rem;
        }

        @media screen and (max-width: 991px) {
            margin-left: auto;
            margin-right: 1.5rem
        }

        @media screen and (max-width: 576px) {
            margin-right: auto;
        }

        img {
            height: 11rem;
            width: 11rem;
            border: .4rem solid $white;
            filter: drop-shadow(0 .5rem 1rem rgba($dark_black, .25));
            @include img_object_fit_center;
            @extend .radius_full;
        }

        h3 {
            @extend .font_22;
            color: $white;
            margin-top: 2rem;
        }

        p {
            @extend .font_18;
            color: $white;
        }
    }

    .reporting_details {
        min-height: 25rem;
        max-width: 30rem;
        padding: 1.5rem;
        @extend .radius_10;
        @include boxShadow(0 1rem 2rem rgba(255, 22, 22, .1));

        @media screen and (max-width: 1440px) and (min-width: 992px) {
            max-width: 25rem;
            padding: 1.5rem .5rem;
        }

        @media screen and (max-width: 991px) {
            margin-right: auto;
            margin-left: 1.5rem;
        }

        @media screen and (max-width: 576px) {
            margin-left: auto;
        }

        .reporting_chart {
            width: 100%;
            min-width: 100%;


            &>div {
                .apexcharts-canvas {
                    min-width: 100% !important;
                    min-height: 100px !important;

                    svg {
                        // path.apexcharts-pie-area,
                        // text.apexcharts-pie-label{
                        //   filter: none;
                        // }
                        height: 100px !important;
                        width: 100% !important;
                        zoom: 1.8;

                        foreignObject {
                            height: 100px !important;
                            width: 100% !important;

                            .apexcharts-legend {
                                position: relative !important;
                                float: right;
                                padding-right: 0;
                                display: block;
                                zoom: 0.525;

                                .apexcharts-legend-series {
                                    span {
                                        font-size: 14px !important;
                                    }
                                }
                            }

                        }

                        foreignObject+.apexcharts-inner {
                            transform: scale(1.5);
                            width: 100% !important;
                            height: 100px !important;

                            text {
                                fill: #000;
                            }

                            tspan {
                                font-size: 8px !important;
                            }
                        }

                    }
                }
            }

            // & > div:first-child { transform: scale(0.6); }
        }

        .reporting_list {
            width: 50%;
        }
    }

    .attendance_summary {
        max-width: calc(100% - 60rem);

        @media screen and (max-width: 1440px) and (min-width: 992px) {
            max-width: calc(100% - 50rem);
        }

        @media screen and (max-width: 991px) {
            max-width: calc(100% - 0rem);
        }

        h2 {
            small {
                font-family: poppins_500;
                @extend .font_16;

                @media screen and (max-width: 576px) {
                    float: left;
                    width: 100%;
                    line-height: 1rem;
                }
            }
        }

        .nav-link {
            @media screen and (max-width: 1440px) and (min-width: 992px) {
                font-size: 2rem;
            }
        }

        .lesson_schedule_table {
            @media screen and (max-width: 576px) {
                flex-direction: column;
                align-items: center;
                text-align: center;

                h2 {
                    line-height: 2.4rem;
                    margin-bottom: 0;
                }
            }
        }

    }
}

.payment_student_name {
    box-shadow: 0 .4rem 5rem rgba($dark_black, .1);
    padding: 1rem 0;
    @extend .radius_10;
    margin-top: .4rem;
    @extend .position-absolute;
    width: calc(100% - 1.4rem);
    z-index: 999;
    background: white;

    &:empty {
        display: none;
    }

    li {
        margin-bottom: 0;
        @extend .font_14;
        @extend .cur_point;
        padding: .7rem 1.5rem;
        @extend .text-capitalize;

        &:hover {
            background: $offWhite;
        }
    }
}