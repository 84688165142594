.dashboard_cards [class*="col-"],
.dashboard_cards .card {
  position: inherit;
}

.text-red,
.text_red {
  color: $red_highlighted;
}

.text-gray {
  color: $dark_gray;
}

.text-light-gray {
  color: $scroll_thumb;
}

.text-black {
  color: $black;
}

.bg_white {
  background: $white;
  border: 0.1rem solid $gray;
}

.bg_light_pink {
  background: $light_pink;
}

.fullWidth {
  float: left;
  width: 100%;
}

.transparent {
  background: transparent;
}

.flex-direction-column {
  flex-direction: column;
}

.justify-content-space-between {
  justify-content: space-between;
}

.justify-content-center {
  justify-content: center;
}

.justify-content-space-around {
  justify-content: space-around;
}

.justify-content-flex-end {
  justify-content: flex-end;
}

.validation_compulsory {
  color: $red_highlighted;
}

.no_wrap {
  white-space: nowrap;
  flex-wrap: nowrap;
}

.wrap {
  white-space: unset;
  flex-wrap: wrap;
  word-break: break-all;
}

.text-black_hover {
  @extend .text-black;

  &:hover {
    color: $red_highlighted;
  }
}

.ls_05 {
  letter-spacing: 0.05rem;
}

.disabled {
  pointer-events: none;
  opacity: 0.5;
}

.word_wrap {
  word-break: break-word;
}

.grayScale {
  filter: grayScale(100%);
}

.cursor_pointer,
[type="file"] {
  cursor: pointer;
}

.min_width_12 {
  min-width: 12rem;
}

.max_width_5rem {
  max-width: 5rem;

  .form-check-label {
    align-items: unset !important;
  }
}

.max_width_25rem {
  max-width: 25rem;
  word-break: break-all;
  float: left;
}

.img_object_fit_center {
  @include img_object_fit_center;
}

.bg-purple {
  background: $purple;
}

.border_bottom {
  border-bottom: 0.1rem solid $light_gray;
}

.border_dashed {
  border: 0.1rem dashed #afafaf !important;
}

.bg_white {
  background: $white !important;
}

.bg_black {
  background: $black;
}

.bg_red {
  background: $red_highlighted;
}

.badge-success {
  background: $green;
}

.badge-danger {
  background: $red_highlighted;
}

.badge-warning {
  background-color: $light_opacity_orange;
  color: $dark_opacity_orange;
  @extend .radius_5;
}

.flex-direction-row {
  flex-direction: row !important;
}

.zoom-xl {
  @media screen and (min-width: 1200px) and (max-width: 1536px) {
    // zoom: .85;
    @include zoom(0.85);
  }
}

.width_auto {
  width: auto !important;
  max-width: 100% !important;
  min-width: 0.1rem !important;
  display: inline-block !important;
}

.radius_0 {
  border-radius: 0;
}

.radius_5 {
  border-radius: 0.5rem;
}

.radius_10 {
  border-radius: 1rem;
}

.radius_20 {
  border-radius: 2rem;
}

.radius_50 {
  border-radius: 5rem;
}

.radius_full {
  border-radius: 100%;
}

.font_10 {
  font-size: 1rem;
}

.font_11 {
  font-size: 1.1rem;
  line-height: 1.4rem;
}

.font_12 {
  font-size: 1.2rem;
}

.font_13 {
  font-size: 1.3rem;
}

.font_14 {
  font-size: 1.4rem;
}

.font_15 {
  font-size: 1.5rem;
}

.font_16 {
  font-size: 1.6rem;

  @media screen and (max-width: 1080px) {
    font-size: 1.4rem;
  }
}

.font_17 {
  font-size: 1.7rem;
}

.font_18 {
  font-size: 1.8rem;

  @media screen and (max-width: 1366px) {
    font-size: 1.6rem;
  }
}

.font_20 {
  font-size: 2rem;
}

.font_22 {
  font-size: 2.2rem;
}

.font_24 {
  font-size: 2.4rem;
}

.font_25 {
  font-size: 2.5rem;

  @media screen and (max-width: 1366px) {
    font-size: 2rem;
    line-height: 2.6rem !important;
  }
}

.font_26 {
  font-size: 2.6rem;

  @media screen and (max-width: 1366px) {
    font-size: 2rem;
    line-height: 2.6rem !important;
  }
}

.font_30 {
  font-size: 3rem;
}

.font_32 {
  font-size: 3.2rem;
}

.font_36 {
  font-size: 3.6rem;
}

.font_40 {
  font-size: 4rem;
}

.font_42 {
  font-size: 4.2rem;

  @media screen and (max-width: 1666px) {
    font-size: 3.6rem;
  }

  @media screen and (max-width: 1366px) {
    font-size: 2.6rem;
  }
}

.font_55 {
  font-size: 5.5rem;
}

.font_56 {
  font-size: 5.6rem;
}

.font_70 {
  font-size: 7rem;
}

.z_index_9 {
  z-index: 9;
}

.z_index_8 {
  z-index: 8;
}

.z_index_7 {
  z-index: 7;
}

.z_index_6 {
  z-index: 6;
}

.z_index_5 {
  z-index: 5;
}

.z_index_4 {
  z-index: 4;
}

.z_index_3 {
  z-index: 3;
}

.z_index_2 {
  z-index: 2;
}

.z_index_1 {
  z-index: 1;
}

.mt-50 {
  margin-top: 5rem;
}

.mt-80 {
  margin-top: 8rem;
}

.mb-50 {
  margin-bottom: 5rem;
}

// AncherTag with red color
.red_anchor {
  color: $red_highlighted;

  &:hover {
    color: $black;
  }
}

textarea {
  @extend .radius_10;
  resize: none;
}

h1 {
  @extend .fullWidth;
  @extend .font_42;
  line-height: 6.3rem;
  color: $black;
  @extend .poppins_600;
}

h2 {
  @extend .fullWidth;
  @extend .font_26;
  line-height: 3.9rem;
  color: $black;
  @extend .poppins_600;
}

h3 {
  @extend .fullWidth;
  @extend .font_20;
  line-height: 3rem;
  @extend .poppins_500;

  &.bold {
    @extend .poppins_600;
  }
}

h4 {
  @extend .fullWidth;
  @extend .font_18;
  line-height: 2.7rem;
  @extend .poppins_600;
}

.form-control {
  border: 0.1rem solid $light_gray;
  box-sizing: border-box;
  @extend .radius_50;
  @extend .font_16;
  display: block;
  width: 100%;
  min-height: 4rem;
  padding: 0.375rem 0.75rem;
  padding-left: 2rem;
  @include transition_border_boxShadow(0.15s);
  @include form_validation;
}

.bg-overlay {
  @extend .position-fixed;
  width: 100%;
  @extend .cursor_pointer;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba($black, 0.6);
  @include transition(all 0.4s ease-in-out);
  @include boxShadow(0 1rem 2rem rgba($black, 0.2));

  @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
    background: linear-gradient(117.76deg,
        rgba($black, 0.7) -7.21%,
        rgba($black, 0.5) 118.08%);
    -webkit-backdrop-filter: blur(0.8rem);
    backdrop-filter: blur(0.8rem);
  }

  /* slightly transparent fallback for Firefox (not supporting backdrop-filter) */
  @supports not ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
    background: linear-gradient(117.76deg,
        rgba($black, 0.95) -7.21%,
        rgba($black, 0.95) 118.08%);
  }
}

// Single line ellipsis
.text_ellipsis {
  @include text_ellipsis;
}

// Multiline (2 Line) ellipsis
.multiline_ellipsis {
  @include multiline_ellipsis;
}

// Dropdown-menu
.dropdown-menu {
  -webkit-animation-name: growIn;
  animation-name: growIn;
  -webkit-animation-duration: 0.2s;
  animation-duration: 0.2s;
  -webkit-animation-timing-function: transform cubic-bezier(0.18, 1.25, 0.4, 1),
    opacity cubic-bezier(0, 1, 0.4, 1);
  animation-timing-function: transform cubic-bezier(0.18, 1.25, 0.4, 1),
    opacity cubic-bezier(0, 1, 0.4, 1);
}

// Buttons
button[disabled] {
  background: $scroll_thumb;
  border-color: $scroll_thumb;
  cursor: no-drop;

  &:hover {
    background: $scroll_thumb;
    border-color: $scroll_thumb;
  }
}

.btn_primary {
  background: $red_highlighted;
  border: 0.1rem solid $red_highlighted;
  box-sizing: border-box;
  @extend .radius_50;
  color: $white;
  min-width: 9rem;
  padding: 0.85rem 1.58rem;
  @extend .font_14;
  line-height: 2.1rem;
  @extend .poppins_600;
  white-space: nowrap;
  @extend .d-block;
  @include transition(all 0.3s ease-in-out);
  @extend .cursor_pointer;
  flex: none;

  // @extend .text-capitalize;
  &:hover {
    color: $white !important;
    background: $black !important;
    border-color: $black !important;
  }
}

.btn_black {
  background: $dark_gray;
  border: 0.1rem solid $dark_gray;
  box-sizing: border-box;
  @extend .radius_50;
  color: $white;
  min-width: 12rem;
  padding: 0.85rem 1.58rem;
  @extend .font_14;
  line-height: 2.1rem;
  @extend .poppins_600;
  white-space: nowrap;
  @extend .d-block;
  @include transition(all 0.3s ease-in-out);
  @extend .cursor_pointer;
  flex: none;

  &:hover {
    color: $white;
    background: $black;
    border-color: $black;
  }

  span {
    @extend .font_26;
    @extend .poppins_300;
  }

  @media screen and (max-width: 1024px) {
    min-width: 1rem;
  }
}

.btn_default {
  background: $white;
  border: 0.2rem solid $light_gray;
  box-sizing: border-box;
  @extend .radius_50;
  color: $dark_gray;
  min-width: 10rem;
  padding: 0.85rem 1.58rem;
  @extend .font_14;
  line-height: 2.1rem;
  @extend .poppins_600;
  white-space: nowrap;
  @extend .d-block;
  @include transition(all 0.3s ease-in-out);
  @extend .cursor_pointer;

  &:hover {
    color: $white;
    background: $black;
    border-color: $black;
  }
}

.btn_purple {
  background: $purple;
  border: 0;
  box-sizing: border-box;
  @extend .radius_50;
  color: $white;
  min-width: 11.5rem;
  padding: 0.8rem;
  @extend .font_14;
  line-height: 2.1rem;
  @extend .poppins_500;
  white-space: nowrap;
  @extend .d-inline-block;
  @include transition(all 0.3s ease-in-out);
  @extend .cursor_pointer;

  &:hover {
    color: $white;
    background: $black;
    border-color: $black;
  }
}

.active_green_button {
  background: $green;
  color: $active_inactive_btn_text_color;
  padding: 0.45rem 1rem;
  line-height: 2.1rem;
  @extend .font_14;
  border-radius: 0.3rem;
  @extend .text-center;
  text-decoration: none;
  @include transition(all 0.3s ease-in-out);
  @extend .cursor_pointer;

  &:hover {
    color: $white;
    background: $black;
    border-color: $black;
  }
}

.inactive_green_button {
  background: $dark_orange;
  color: $active_inactive_btn_text_color;
  padding: 0.45rem 1rem;
  line-height: 2.1rem;
  @extend .font_14;
  border-radius: 0.3rem;
  @extend .text-center;
  text-decoration: none;
  @include transition(all 0.3s ease-in-out);
  @extend .cursor_pointer;

  &:hover {
    color: $white;
    background: $black;
    border-color: $black;
  }
}

.paid_yellow_button {
  background: $light_orange;
  color: $active_inactive_btn_text_color;
  padding: 0.46rem 1.1rem;
  @extend .font_12;
  line-height: 1.8rem;
  border-radius: 0.3rem;
  @extend .text-center;
  text-decoration: none;
  @include transition(all 0.3s ease-in-out);
  @extend .cursor_pointer;

  &:hover {
    color: $white;
    background: $black;
    border-color: $black;
  }
}

.unpaid_pinkish_button {
  background: $pinkish;
  color: $active_inactive_btn_text_color;
  padding: 0.45rem 1rem;
  @extend .font_12;
  line-height: 1.8rem;
  border-radius: 0.3rem;
  @extend .text-center;
  text-decoration: none;
  @include transition(all 0.3s ease-in-out);
  @extend .cursor_pointer;

  &:hover {
    color: $white;
    background: $black;
    border-color: $black;
  }
}

.alert-danger {
  border: 0.1rem solid $red;
  @extend .font_14;
  color: $black;

  i {
    color: $warning;
  }

  span {
    color: $dark_black;
  }
}

// Online / Offline dots
.dot {
  height: 0.9rem;
  width: 0.9rem;
  @extend .radius_full;
  display: inline-block;
}

.green_dot {
  @extend .dot;
  background-color: $dark_green;
}

.red_dot {
  @extend .dot;
  background-color: $dark_red;
}

.white_dot {
  @extend .dot;
  background-color: $white !important;
  @include boxShadow(0 0 0 0.1rem $black);
}

.pink_dot {
  @extend .dot;
  background: $pink;
}

.blue_dot {
  @extend .dot;
  background: $light_blue;
}

.orange_dot {
  @extend .dot;
  background: $orange;
}

.list_unstyled {
  list-style: none;
}

select {
  // @extend .position-relative;
  border: 0.1rem solid $light_gray;
  box-sizing: border-box;
  @extend .font_14;
  line-height: 2.4rem;
  padding: 0.8rem 1.5rem;
  @extend .radius_50;
  width: 100%;
  @include appearance(none);
  background: url(../images/select_down_arrow.svg) no-repeat;
  background-position: calc(100% - 1rem) center;
}

// Arrow on sidebar navigation hover or active for submenu
.nav_link_after {
  content: "";
  background: url(../images/arrow_right_white.svg) no-repeat;
  @extend .position-absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: 2rem;
  z-index: 9;
  background-position: left center;

  @media screen and (max-width: 1280px) {
    content: unset;
  }
}

// Desktop: Welcome card background dot
.welcome_card_before {
  content: "";
  height: 1.4rem;
  width: 1.4rem;
  @extend .radius_full;
  background: $white;
  @extend .position-absolute;
  bottom: 4.4rem;
  left: 2.5rem;
}

.profile_image_border_n_shadow {
  border: 0.4rem solid $white;
  filter: drop-shadow(0 0.5rem 1rem rgba($dark_black, 0.25));
}

.inner_card {
  background: $gray;
  padding: 1rem 2rem;
  @extend .radius_10;
  margin-bottom: 2rem;
}

.card {
  background: $gray;
  padding: 2.5rem;
  @extend .radius_20;
  @extend .border-0;
  @include transition(all 0.3s ease-in-out);

  &:hover {
    background: $msg_sent_box_bg;
    @include boxShadow(0 1rem 2.4rem -2rem rgba($dark_black, 0.6));
  }

  @media screen and (max-width: 768px) {
    padding: 1.5rem;
  }
}

// See more :: card loader
.see_more {
  @extend .poppins_500;
  @extend .font_14;
  line-height: 2.1rem;
  @extend .mt-3;
  @extend .d-inline-block;
  @extend .text-center;
  color: $white;
  background: $dark_gray;
  @extend .radius_50;
  padding: 1rem 2.7rem;
  @include transition(all 0.3s ease-in-out);

  &:hover {
    color: $white;
  }
}

// Custome Checkbox Design
.custom-control-input:checked~.custom-control-label::before {
  border-color: $red_highlighted;
  background-color: $red_highlighted;
}

.custom-control-label {
  &::before {
    content: "";
    border-radius: 0.5rem;
    width: 1.8rem;
    height: 1.8rem;
    left: -3rem;
    border: 0.2rem solid $light_gray;
  }

  &::after {
    content: "";
    border-radius: 0.5rem;
    top: 0.15rem;
    left: -3.1rem;
    width: 2rem;
    height: 2rem;
  }
}

// End: Custome Checkbox Design

// Custome Checkbox Design on Radio Button
.custom-checkbox .custom-control-input:indeterminate~.custom-control-label::before {
  border-color: transparent;
  background-color: transparent;
  border: 0.2rem solid $light_gray;
}

// End: Custome Checkbox Design on Radio Button

// Custome Radio Button Design
.form-check-label {
  @extend .d-flex;
  @extend .align-items-center;

  .form-check-input {
    @extend .position-relative;
    @extend .mr-3;
    @include appearance(none);

    &:before {
      content: "";
      height: 1.8rem;
      width: 1.8rem;
      background: $white;
      float: left;
      border: 0.2rem solid $light_gray;
      @extend .radius_full;
    }

    &:after {
      content: unset;
      height: 1rem;
      width: 1rem;
      float: left;
      background: $red_highlighted;
      @extend .radius_full;
      position: absolute;
      left: 0.4rem;
      top: 0.4rem;
    }

    &:checked {
      &:before {
        content: "";
        height: 1.8rem;
        width: 1.8rem;
        background: $white;
        float: left;
        border: 0.2rem solid $red_highlighted;
        @extend .radius_full;
      }

      &:after {
        content: "";
        height: 1rem;
        width: 1rem;
        float: left;
        background: $red_highlighted;
        @extend .radius_full;
        position: absolute;
        left: 0.4rem;
        top: 0.4rem;
      }
    }
  }
}

// End: Custome Radio Button Design
.valid_feedback_icon {
  @include valid_feedback_icon;
}

.invalid_feedback_icon {
  @include invalid_feedback_icon;
}

.modal {
  &.show {
    // background: rgba(0, 0, 0, 0.4);
    @include transition(all 0.3s ease-in-out);
    @include boxShadow(0 1rem 2rem rgba($black, 0.2));

    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      background: linear-gradient(117.76deg,
          rgba($black, 0.7) -7.21%,
          rgba($black, 0.5) 118.08%);
      -webkit-backdrop-filter: blur(0.8rem);
      backdrop-filter: blur(0.8rem);
    }

    /* slightly transparent fallback for Firefox (not supporting backdrop-filter) */
    @supports not ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
      background: linear-gradient(117.76deg,
          rgba($black, 0.95) -7.21%,
          rgba($black, 0.95) 118.08%);
    }
  }
}

// Color Picker
.color_box {
  display: block;
  height: 2.9rem;
  width: 2.5rem;
  padding: 0;
  background: transparent;
}

.height-auto {
  height: auto !important;
  min-height: 0.1rem !important;
}

.react-datepicker-popper {
  z-index: 9;
}

.custom_action_modal {

  // width: 100%;
  // max-width: 48rem;
  // margin: 0 auto;
  // padding-bottom: 2rem;
  form {
    textarea {
      width: 100%;
      resize: none;
    }

    .btn_black {
      margin-right: 1.2rem;
    }

    .trigger_type {
      // font-weight: 600;
      // padding: 1rem 0;
    }
  }
}

.btn_play_pause {
  width: 100%;
  @extend .d-flex;
  @extend .align-items-center;
  max-width: 18rem;
  margin: 0 auto;

  .backward_btn {
    height: 3rem;
    width: 3rem;
    background: none;
    border: 0;

    img {
      width: 2rem;
      @include img_object_fit_center;
      transform: rotate(180deg);
      cursor: pointer;
    }
  }

  .forward_btn {
    height: 3rem;
    width: 3rem;
    background: none;
    border: 0;

    img {
      width: 2rem;
      @include img_object_fit_center;
      cursor: pointer;
    }
  }
}

.PhoneInputInput {
  border: 0;
}

.lesson_information {
  @extend .fullWidth;
  background: $gray;
}

.notification_setting {
  @extend .fullWidth;

  .email_select {
    @extend .fullWidth;
    flex-wrap: wrap;
  }

  .sms_select {
    @extend .fullWidth;
    flex-wrap: wrap;
  }

  .reminder_date_time {
    .form-control.invalid {
      border: 0.2rem solid #dadada;
    }
  }
}

.grid_list {
  max-width: 16.5rem;
  background: #ffffff;
  border: 1px solid #dadada;
  box-sizing: border-box;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  white-space: nowrap;
  padding: 0;

  .grid {
    border-right: 1px solid #dadada;
    padding: 1rem;
    color: black;
  }

  .list {
    padding: 1rem;
    color: black;
  }

  .active {
    color: #ff1616;
  }
}

.btn-success {
  box-sizing: border-box;
  color: #ffffff;
  min-width: 9rem;
  padding: 0.85rem 1.58rem;
  line-height: 2.1rem;
  white-space: nowrap;
  transition: all 0.3s ease-in-out;
  flex: none;
  border-radius: 2.5rem;
}

.tooltip {
  font-size: 15px;
  width: 100%;
  max-width: 230px;
  height: auto;
  margin-bottom: 0.5rem;
}

.tooltip-inner {
  text-align: left !important;
}

.form-group {
  .form-control {
    padding: 0.375rem 2.75rem;
  }
}

.back_arrow {
  display: inline-block !important;
  position: absolute;
  top: 3.8rem;
  left: 4rem;
  color: red;
  font-size: 18px;
  cursor: pointer;

  @media screen and (max-width: 1680px) {
    left: 0.5rem;
  }

  @media screen and (max-width: 991px) {
    display: none !important;
  }
}

.serch_history {
  width: 100%;
  max-width: 500px;
  border: 0.1rem solid #dadada;
  height: 380px;
  top: 40px;
  border-radius: 0 0 3rem 3rem;
  border-top: 0;
  background: white;
  z-index: 9999;

  @media screen and (max-width: 375px) {
    max-width: 450px;
    top: 60px;
    left: -15px;
  }

  .history_list {
    height: 370px;
    overflow: auto;
    width: 100%;

    @media screen and (max-width: 375px) {
      width: 430px;
    }

    span {
      i {
        font-size: 15px;
      }
    }

    p {
      font-size: 1.4rem;
      line-height: 2.4rem;
      color: #212529;
      text-overflow: ellipsis;
      overflow: hidden !important;
      white-space: nowrap;
      display: block;
      width: 100%;
    }
  }
}

.plan_information {
  width: 100%;
  float: left;
  padding: 60px;
  background: #f2f2f2;
  // .information{

  // }
}

.loremmore_page {
  .container {
    @extend .fullWidth;
    max-width: 1024px;

    .learnmore_details {
      background: $gray;
      padding: 3rem 9rem;
      border-radius: 2rem;

      .lorem_content {
        margin-bottom: 2.4rem;

        h2 {
          font-size: 2.4rem;
          line-height: 3.5rem;
          margin-bottom: 0.5rem;
          position: relative;

          span {
            @extend.position-absolute;
            left: -6rem;
            top: 0;
            width: 3.5rem;
            height: 3.5rem;
            @extend .d-flex;
            @extend .align-items-center;
            @extend .justify-content-center;

            img {
              width: 3.8rem;
              height: 3.8rem;
              // object-fit: cover;
              object-position: center;
            }
          }
        }
      }
    }
  }
}

.opacity_0 {
  opacity: 0;
}

.opacity_1 {
  opacity: 1;
}

.marchant_info {
  form {
    width: 100%;
    max-width: 840px;

    h2 {
      margin-bottom: 2.4rem;
    }
  }
}

.support_page {
  .col-xl-6 {
    padding: 0 40px;
  }

  .help_to_contect {
    margin-bottom: 30px;
  }
}

.upcomingLesson_List {
  width: 100%;
  float: left;
  // height: 350px;
  overflow: auto;
  padding: 10px 15px;
}

.help_contect {
  .assigned_unassigned_student {
    width: 100%;
    max-width: 35rem;

    @media screen and(max-width: 767px) {
      max-width: 100%;
    }

    .student_list_tab li:last-child {
      border-bottom: 0;
    }
  }

  .halp_details {
    width: calc(100% - 35rem);
    padding: 2rem 4rem;

    @media screen and(max-width: 767px) {
      width: 100%;
    }

    .content {
      display: none;

      &.active {
        display: block;
      }

      li {
        margin-bottom: 5px;
      }

      span {
        display: block;
        margin-bottom: 15px;
      }

      .text_red {
        color: #ff1616;
      }
    }
  }
}

.billing_image,
.billing_image img {
  visibility: hidden;
  height: 0;
  transition: all 0.8s ease-in-out;
}

.billing_image img {
  max-width: 100%;
}

.plan_detail:hover .billing_image {
  margin: 15px;
}

.plan_detail:hover .billing_image,
.plan_detail:hover .billing_image img {
  visibility: visible;
  height: 100px;
  transition: all 0.8s ease-in-out;
  width: 100%;
  max-width: 100px;
}

.see_details .badge:hover {
  border: 2px solid #e41414e3;
  background: #ffff;
  color: #e41414e3;
  transition: 0.2s all;
}

.see_details .badge {
  background: #e41414e3;
  border: 1px solid transparent;
  width: auto;
  min-height: 50px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  appearance: none;
  text-transform: capitalize;
  border-radius: 5px;
  font-size: 16px;
  line-height: 30px;
  color: #fff;
  font-weight: 400;
  text-align: center;
  cursor: pointer;
  padding: 5px 30px;
  box-shadow: inset 0 -5px 0 var(--primary-color);
  margin: 0 25px 25px 0;
  transition: 0.2s all;
}

.add_option {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;

  input {
    margin-right: 30px;
  }
}

.user_answer {
  align-items: center !important;
  margin-bottom: 20px !important;
}

.question_answer,
.category_box {
  margin-bottom: 0 !important;
  min-height: 0 !important;
}

.add_new_icon {
  width: 50px;
  height: 50px;
  float: left;
  background: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ff1616;
  font-size: 36px;
  border: 1px solid #dadada;
  transition: all 0.3s ease-in-out;
  border-radius: 10px;
  cursor: pointer;
}

.user_answer {
  .col-md-1 {
    @media screen and(max-width: 767px) {
      max-width: 5% !important;
    }
  }
}

.answer_input {
  @media screen and(max-width: 767px) {
    max-width: 100% !important;
  }
}

.add_icon {
  align-items: end;
  display: flex;

  @media screen and(max-width: 767px) {
    margin: 15px 0 0;
  }
}

.quiz_answer_list {
  margin: 25px 0 0;
}

.btn_member {
  margin: 15px 0;
}

.add_new_file_icon {
  width: 6rem;
  height: 6rem;
  float: left;
  background: #ffffff;
  border: 0.1rem dashed #0b0b0b;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ff1616;
  margin: 0 20px 2rem 0px;
  font-size: 3rem;
  border-radius: 1rem;
}

.file_design {
  width: 6rem;
  height: 6rem;
  left: 0;
  top: 0;
  opacity: 0;
  z-index: 99;
  position: absolute !important;
  cursor: pointer;
}

.btn_member {
  min-width: 120px;
}

.half_time_zone p {
  font-size: 0px;
}

// quiz category panel design
.category_box_quiz {
  max-width: 350px;
  border: 0.1rem solid #dadada;
  box-sizing: border-box;
  display: block;
  width: 100%;
  text-align: center;
  height: 100vh !important;
  border-radius: 25px;
  min-height: 500px !important;
  background: #ffffff;

  // padding: 3.5rem !important;
  &.question_list {
    max-width: unset;
  }
}



.category_box_quiz #content {
  padding-right: 0px !important;
  height: 100% !important;
  padding-bottom: 0px !important;
  overflow: auto;
}

.quiz_answer_box #content {
  padding-right: 0px !important;
  padding-bottom: 0px !important;
}

.red_anchor {
  color: #ffffff;
  background: #ff1616;
  padding: 1.5rem 2rem;
  margin-bottom: 13px;
  border-radius: 15px;
  word-break: break-all;
  cursor: pointer;

  &.cross_right_arrow {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.red_anchor:hover {
  color: #ffffff;
  background: #000000;
  border: 2px solid #ffffff;
}

.red_anchor:last-child {
  margin-bottom: 0px;
}

.red_anchor i {
  vertical-align: middle;
}

.quiz_answer_box {
  background: #f3f3f3;
  padding: 2.5rem;
  transition: all 0.3s ease-in-out;
  border-radius: 2rem;
  text-align: left;
  max-width: 600px;
  width: 100%;
  box-shadow: 0 1rem 2.4rem -2rem rgba(0, 0, 0, 0.6%);
  height: 100%;

  &.quiz_table_list {
    max-width: unset;
  }
}

.next_quiz {
  margin: 15px 0 0 !important;
  float: unset !important;
}

.quiz_answer_box ul {
  list-style: none;
  margin: 0;
  padding: 0 0 2.5rem 2.5rem;
}

.quiz_answer_box #content p {
  font-size: 1.6rem;
  color: #747474;
}

.title_and_counter {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.number_question {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.number_question h3 {
  width: 5%;
}

.category_box_quiz #content {
  padding: 3.5rem !important;
}

@media (max-width: 768px) {
  .category_box_quiz {
    max-width: unset;
    height: 50vh !important;
  }
}

.ear_trainer {
  width: 100%;
  float: left;
}

.login_page.signup_page {
  width: 100%;
  float: left;
  height: 100%;
  padding-bottom: 30px;

  h2 {
    color: #ff1616;
  }

  .form-row {
    justify-content: space-between;

    .col-md-6 {
      padding-left: 1.5rem;
      padding-right: 1.5rem;
    }
  }

  .form-group {
    label {
      color: white;
    }

    .studentSelect {
      padding-left: 15px !important;
      position: unset;
      opacity: 1;
      .ant-select-selection-placeholder {
        font-weight: 400;
        line-height: 1.5;
        color: #495057;
        font-family: poppins_500;
      }
      .ant-select-selector {
        border: none !important;
        box-shadow: none !important;
        &:hover {
          border: none;
          box-shadow: none;
        }
        .ant-select-selection-item{
          background-color: rgba(255,22,22,.2);
    color: #ff1616;
    white-space: nowrap;
    border: none;
    .ant-select-selection-item-remove {
      display: flex;
      color: #ff1616;
    }
        }
      }
    }

    .studentSelect.invalid {
      border: 0.2rem solid #e40909;
      padding-right: calc(1.5em + 0.75rem);
    }

    .studentSelect.valid {
      border: 0.2rem solid #29c536;
      padding-right: calc(1.5em + 0.75rem);
    }

    select {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      z-index: 1;
      border: 0;
      opacity: 0;
      cursor: pointer;
    }

    .PhoneInput {
      display: flex;
      align-items: center;
      .PhoneInputCountry {
        position: relative;
    align-self: stretch;
    display: flex;
    align-items: center;
    select {
      width: 10px;
    }
      }
      img {
        width: 8%;
      }
      .PhoneInputInput {
        width: 79%;
        position: absolute;
        top: 8px;
        left: 60px;
      }
    }
  }
}

.quiz_search_menu {

  // display: flex;
  // align-items: center;
  // justify-content: space-between;
  .search_icon {
    left: auto !important;
    right: 1rem;
    top: 50%;
    transform: translateY(-50%);
  }
}

.swal2-title {
  display: flex;
  align-items: center;
  justify-content: center;
}

.student_quiz_search {
  left: auto !important;
  right: 1rem;
  top: 50%;
  transform: translateY(-50%);
}


.lesson-youtube {
  display: flow-root;
  padding: 1.5rem;
}

.address_input {
  .form-control.PhoneInput {
    line-height: 2.4rem;
    color: #0b0b0b;
    overflow: auto;
    white-space: nowrap;
    display: flex;
    gap: 10px;

    .PhoneInputCountry {
      position: relative;
      align-self: stretch;
      display: flex;
      align-items: center;
      margin-right: 0.35em;
      margin-right: var(--PhoneInputCountrySelect-marginRight);

      .PhoneInputCountrySelect {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        z-index: 1;
        border: 0;
        opacity: 0;
        cursor: pointer;
        padding: 0;
      }

      .PhoneInputCountryIcon {
        width: 1.5em;

        .PhoneInputCountryIconImg {
          display: block;
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}

.order-confirm {
  .details_list {
    .ant-btn {
      max-width: 15rem !important;
    }
  }
}

// video::-webkit-media-controls-play-button {
//   display: none !important;
// }

.minHeight {
  min-height: 6rem;
  @media (max-width: 750px){
    min-height: 4rem;
  }
}

.space{
  @media (max-width: 750px){
    margin-bottom: 1rem;
  }
}