.student_billing {
    .heading_area {
        .inner_search {
            max-width: 100%;
        }
    }


    // Plan Builder Tab script
    .plan_builder {
        @extend .d-flex;

        @media screen and (max-width: 1200px) {
            flex-direction: column;
        }

        .plan_info {
            max-width: calc(100% - 51rem);
            padding-right: 5rem;

            @media screen and (max-width: 1680px) {
                max-width: calc(100% - 40rem);
                padding-right: 2.5rem;
            }

            @media screen and (max-width: 1200px) {
                max-width: 100%;
                padding-right: 1.5rem;
            }

            @media screen and (max-width: 767px) {
                max-width: 100%;
                padding-right: 0;
            }
        }

        .plan_list {
            max-width: 51rem;

            @media screen and (max-width: 1680px) {
                max-width: 40rem;
            }

            @media screen and (max-width: 1080px) {
                max-width: 30rem;
            }

            @media screen and (max-width: 767px) {
                max-width: 100%;
            }

            li {
                @extend .fullWidth;
                @extend .mb-4;
            }

            .plan_approval {
                @extend .fullWidth;
                border: .1rem solid $light_gray;
                @extend .radius_10;
                padding-bottom: 2rem;

                .plan_box {
                    border: 0;
                }
            }

            .plan_box {
                display: flex;
                align-items: center;
                @extend .fullWidth;
                @extend .radius_10;
                padding: 2rem;
                border: .1rem solid $light_gray;
                @extend .cursor_pointer;
                @include transition(all .3s ease-in-out);

                @media screen and (max-width: 1680px) {
                    // zoom: 1;
                    @include zoom(1);
                }

                @media screen and (max-width: 1080px) {
                    padding: 1rem;
                }

                @media screen and (max-width: 576px) {
                    // zoom: 0.8;
                    @include zoom(.8);
                }

                .plan_box_no {
                    width: 3.5rem;
                    @extend .poppins_bold;

                    span {
                        width: 3.5rem;
                        height: 3.5rem;
                        @extend .radius_full;
                        background: $gray;
                        color: $dark_gray;
                        @extend .font_22;
                        @include d_flex_center;
                        @include transition(all .3s ease-in-out);

                        @media screen and (max-width: 1024px) {
                            width: 3rem;
                            height: 3rem;
                            font-size: 1.8rem
                        }
                    }
                }

                .plan_box_content {
                    width: calc(100% - 13.5rem);
                    padding-left: 2rem;
                    padding-right: 2rem;

                    @media screen and (max-width: 1080px) {
                        padding-right: 1rem;
                    }

                    @media screen and (max-width: 1024px) {
                        width: calc(100% - 10rem);
                        padding-left: 1rem;
                    }

                    .plan_box_name {
                        @extend .font_20;
                        @extend .mb-2;
                        @include transition(all .3s ease-in-out);

                        width: 100%;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        overflow: hidden;

                        @media screen and (max-width: 1024px) {
                            font-size: 1.6rem;
                        }
                    }

                    .plan_box_days {
                        @media screen and (max-width: 1024px) {
                            font-size: 1.4rem;
                        }
                    }
                }

                .plan_box_amount_action {
                    width: 12.5rem;
                    @extend .text-center;

                    @media screen and (max-width: 1024px) {
                        width: 7rem;
                    }

                    .plan_box_amount {
                        @extend .font_24;
                        @extend .poppins_600;
                        @include transition(all .3s ease-in-out);

                        @media screen and (max-width: 1024px) {
                            font-size: 1.8rem;
                        }
                    }

                    .plan_box_action {
                        @include d_flex_center;

                        @media screen and (max-width: 1024px) {
                            // zoom: 0.8;
                            @include zoom(.8);
                        }

                        i {
                            color: $dark_gray;
                            @include transition(all .3s ease-in-out);
                        }
                    }

                    .switch {
                        position: relative;
                        display: inline-block;
                        width: 50px;
                        height: 28px;

                        input {
                            opacity: 0;
                            width: 0;
                            height: 0;
                        }


                        .slider {
                            position: absolute;
                            cursor: pointer;
                            top: 0;
                            left: 0;
                            right: 0;
                            bottom: 0;
                            background-color: #ccc;
                            -webkit-transition: .4s;
                            transition: .4s;
                        }

                        .slider:before {
                            position: absolute;
                            content: "";
                            height: 20px;
                            width: 22px;
                            left: 4px;
                            bottom: 4px;
                            background-color: white;
                            -webkit-transition: .4s;
                            transition: .4s;
                        }

                        input:checked+.slider {
                            background-color: #ff1616;
                        }

                        input:focus+.slider {
                            box-shadow: 0 0 1px #ff1616;
                        }

                        input:checked+.slider:before {
                            -webkit-transform: translateX(20px);
                            -ms-transform: translateX(20px);
                            transform: translateX(20px);
                        }

                        /* Rounded sliders */
                        .slider.round {
                            border-radius: 34px;
                        }

                        .slider.round:before {
                            border-radius: 50%;
                        }
                    }
                }

                &:hover,
                &.active {
                    background: $red_highlighted;
                    border-color: $red_highlighted;

                    .plan_box_no {
                        span {
                            color: $red_highlighted;
                        }
                    }

                    .plan_box_content {
                        color: $white;

                        .plan_box_name {
                            color: $white;
                        }
                    }

                    .plan_box_amount_action {
                        .plan_box_amount {
                            color: $white;
                        }

                        .plan_box_action {
                            i {
                                color: $white;
                            }
                        }
                    }
                }
            }

            .course_status {
                @extend .fullWidth;
                max-width: 27rem;
                display: flex;
                @extend .text-center;
                // @extend .mt-4;
                padding: .5rem 2rem;

                .approve_btn {
                    @extend .active_green_button;
                    background: $dark_green;
                    width: 50%;
                }

                .reject_btn {
                    @extend .btn_primary;
                    width: 50% !important;
                }

                a {
                    border-radius: 5rem !important;
                    @include d_flex_center;
                    @extend .poppins_600;
                    color: $white !important;
                }
            }
        }


        .btn_primary {
            width: 10rem;
            @extend .text-center;
        }
    }

}

// Plan Widget Tab script
.plan_widget {
    @extend .d-flex;

    @media screen and (max-width: 767px) {
        flex-direction: column;
    }

    .design_style {
        width: 100%;
        max-width: 50rem;

        @media screen and (max-width: 1680px) {
            max-width: 40rem;
        }

        @media screen and (max-width: 1080px) {
            max-width: 33rem;
        }

        @media screen and (max-width: 767px) {
            max-width: 100%;
            margin-bottom: 4rem;
        }

        .style_design_box {
            background: $red_highlighted;
            padding: 3.5rem;
            @extend .radius_20;

            @media screen and (max-width: 1680px) {
                padding: 3.5rem 1.5rem .5rem;
            }

            h2 {
                color: $white;
            }

            li {
                @extend .mb-4;
            }

            .style_design_listbox {
                background: $white;
                @extend .radius_20;
                padding: 1.5rem 2rem;
                @extend .d-flex;
                @extend .align-items-center;

                @media screen and (max-width: 1080px) {
                    // zoom: .9;
                    @include zoom(.9);
                }

                @media screen and (max-width: 767px) {
                    // zoom: 1;
                    @include zoom(1);
                }

                @media screen and (max-width: 576px) {
                    // zoom: .9;
                    @include zoom(.9);
                }
            }

            .style_design_listbox.show {
                border-bottom-right-radius: 0;
                border-bottom-left-radius: 0;
            }

            .style_design_listbox.show+.plan_dropdown {
                background: $thin_light_pink;
                padding: 1.5rem 2rem;
                border-bottom-right-radius: 2rem;
                border-bottom-left-radius: 2rem;
            }

            .plan_dropdown {
                display: none;

                &.show {
                    display: block;
                }

                li {
                    margin-bottom: 0 !important;
                    padding: 1rem;
                    background: rgba($thin_light_pink_hover, .5);

                    &:nth-child(even) {
                        background: $thin_light_pink_hover;
                    }

                    &:hover {
                        background: $white;
                        @extend .cursor_pointer;
                    }
                }
            }


            .style_design_listbox_icon {
                width: 5rem;

                @media screen and (max-width: 1680px) {
                    width: 4rem;
                }
            }

            .style_design_listbox_content {
                padding-left: 1.5rem;
                width: calc(100% - 5rem);

                @media screen and (max-width: 1680px) {
                    width: calc(100% - 4rem);
                }
            }

            .style_design_listbox_name {
                @extend .font_20;
                color: $red_highlighted;
                @extend .poppins_600;
                @extend .mb-2;

                span {
                    @extend .poppins_300;
                    @extend .font_24;

                    transform: rotate(90deg);
                    height: 2rem;
                    width: 2rem;
                    @include d_flex_center;
                    @extend .cursor_pointer;
                }
            }

            .style_design_listbox_para {
                text-overflow: ellipsis;
                width: 100%;
                white-space: nowrap;
                overflow: hidden;
            }
        }
    }

    .widgets {
        width: 100%;
        max-width: calc(100% - 50rem);
        padding-left: 5rem;

        @media screen and (max-width: 1680px) {
            max-width: calc(100% - 40rem);
        }

        @media screen and (max-width: 1080px) {
            max-width: calc(100% - 33rem);
            padding-left: 3rem;
        }

        @media screen and (max-width: 767px) {
            max-width: 100%;
            padding-left: 0;
        }

        .color_style {
            ul {
                @extend .d-flex;
                @extend .align-items-center;
                @extend .wrap;

                li {
                    &:not(:last-child) {
                        padding-right: 2.5rem;
                        // margin-bottom: 1rem;
                    }
                }

                .color_picker {
                    @extend .float-left;
                    width: 2.4rem;
                    height: 2.4rem;
                    margin-right: 1rem;
                }
            }
        }
    }
}


// Login Widget tab script
.login_widget {
    @extend .d-flex;

    @media screen and (max-width: 768px) {
        flex-direction: column;
    }

    .widget_color_style {
        width: 30%;

        @media screen and (max-width: 768px) {
            width: 100%;
        }

        table {
            th {
                background: $gray;
            }
        }
    }

    .login_style {
        width: 70%;
        padding-left: 5rem;

        @media screen and (max-width: 1080px) {
            padding-left: 2rem;
        }

        @media screen and (max-width: 768px) {
            width: 100%;
            padding-left: 0;
            margin-top: 4rem;
        }

        .login_form_graphic {
            @extend .d-flex;
            @extend .radius_10;
            overflow: hidden;
            @include boxShadow(0 .4rem 3rem rgba($dark_black, .05));
        }

        .login_graphic {
            @extend .fullWidth;
            @extend .text-center;
            max-width: 37rem;
            background-color: $thin_light_pink;
            padding: 5rem;
            @extend .d-flex;
            @extend .flex-direction-column;
            @extend .justify-content-space-between;

            @media screen and (max-width: 1080px) {
                padding: 5rem 1rem;
            }

            @media screen and (max-width: 576px) {
                display: none !important;
            }

            p {
                @extend .font_20;

                span {
                    @extend .poppins_bold;
                }
            }
        }

        .login_form {
            padding-left: 4rem;
            padding-right: 4rem;

            @media screen and (max-width: 1080px) {
                padding-left: 2rem;
                padding-right: 2rem;
            }

            label {
                @extend .font_18;
                @extend .poppins_600;
            }
        }
    }
}

.student_widget {
    display: flex;
    flex-direction: column;
    .student_widget_header {
        display: flex;
    justify-content: space-between;
        @media screen and (max-width: 425px) {
            flex-direction: column;
            align-items: baseline;
        }
        h2 {
            width: auto;
        }
    }
    .student_widget_action {
        display: flex;
        margin-bottom: 15px;
        align-items: flex-start;
        gap: 10px;
        .student_widget_link_lable {
            margin-bottom: 0px;
            // width: 115px;
            // display: flex;
        }
        .student_widget_link {
            margin-right: 10px;
            background: #f0f0f0;
            outline: none;
            border: none;
            border-radius: 4px;
            width: -webkit-fill-available;
            max-width: 1450px;
        }
        .student_widget_btn {
            background: rgb(38, 38, 39);
            color: white;
            box-shadow: 0 2px 0 rgba(0, 0, 0, 0.02);
            outline: none;
            border: none;
            border-radius: 6px;
            width: 70px;
            cursor: pointer;
            font-size: 13px;
        }
    }
    .student_widget_content {
        .student_widget_preview {
            height: 725px;
        }
    }
}