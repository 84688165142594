.practice_skills {
    .card {
        .course_img {
            height: 14rem;

            @media screen and (max-width: 1180px) {
                height: 10rem;
            }
        }

        .course_details {
            padding: 1.5rem 1.75rem;

            @media screen and (max-width: 1180px) {
                padding: .5rem .75rem;
            }

            h4 {
                @media screen and (max-width: 992px) {
                    line-height: 2rem;
                }

                @media screen and (max-width: 576px) {
                    line-height: 1.6rem;
                    font-size: 1.4rem;
                }
            }

            span {
                @extend .poppins_300;
            }
        }
    }
}

.reminder_type_list {
    .reminder_type {
        // background: $red_highlighted;
        @extend .position-relative;
        display: flex;
        align-items: center;
        padding: 1rem 2rem;
        @extend .radius_50;
        @extend .cursor_pointer;

        @media screen and (max-width: 576px) {
            // zoom: .85;
            @include zoom(.85);
            padding: .6rem 1.5rem;
        }

        span {
            width: 2.5rem;
            height: 2.5rem;
            float: left;
        }

        .icon_day {
            background: url(../images/icon_date_black.png) no-repeat;
            background-size: 2.5rem 2.5rem;
        }

        .icon_week {
            background: url(../images/icon_week_black.png) no-repeat;
            background-size: 2.5rem 2.5rem;
        }

        .icon_monthly {
            background: url(../images/icon_monthly_black.png) no-repeat;
            background-size: 2.5rem 2.5rem;
        }

    }

    .form-check-inline {
        @media screen and (max-width: 414px) {
            margin-right: 0;
        }
    }

    .form-check-label {
        input {
            position: absolute !important;
            width: 100%;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            opacity: 0;
        }

        input:checked~.reminder_type {
            background: $red_highlighted;
            color: $white;

            .icon_day {
                background: url(../images/icon_date_white.png) no-repeat;
                background-size: 2.5rem 2.5rem;
            }

            .icon_week {
                background: url(../images/icon_week_white.png) no-repeat;
                background-size: 2.5rem 2.5rem;
            }

            .icon_monthly {
                background: url(../images/icon_monthly_white.png) no-repeat;
                background-size: 2.5rem 2.5rem;
            }
        }
    }


    .dropdown {
        .dropdown-menu {
            @include boxShadow(0 0 2rem -0.5rem rgba($dark_black, .4));
            border: 0;
            padding: 10px;
            width: 13.4rem;
            margin-bottom: 5rem;

            &.show {
                @extend .d-flex;
                @extend .wrap;

                @media screen and (max-width: 576px) {
                    right: 0;
                    left: auto;
                }
            }


            a {
                @extend .p-0;
                @extend .font_14;
                width: 3.8rem;
                @extend .text-center;
                height: 3.5rem;
                color: $black;
                background: $white;
                @include d_flex_center;
                @extend .radius_5;

                &:hover {
                    color: $red_highlighted;
                }

                &.active {
                    background: $red_highlighted;
                    color: $white;
                }
            }
        }

        &.month_dropdown {
            .dropdown-menu {
                width: 24.8rem;
            }
        }
    }
}

.time_selector {
    @extend .d-flex;
    @extend .align-items-center;
}


.practice_file_list {
    .file_box {
        width: 6rem;
        height: 6rem;
        float: left;
        background: $white;
        border: 0.1rem dashed $gray_status_border;
        @include d_flex_center;
        @extend .radius_10;
        @extend .position-relative;
        ;
        margin: 0 1.5rem 2rem .5rem;

        .cross_btn {
            border: 0.2rem solid $white;
            height: 2rem;
            width: 2rem;
            top: -.9rem;
            right: -.9rem;
            background: $red_highlighted;
            padding: .5rem;
            @include d_flex_center;
            @extend .radius_full;
            @extend .cursor_pointer;

            img {
                width: .8rem;
                height: .8rem;
            }
        }
    }
}

.instruments_courses {
    display: none;

    &.active {
        display: block;
        padding: 1.5rem;

        li {
            margin-bottom: 1rem;
        }
    }
}