.parents{

    .heading_area{
        @extend .heading_area;

        @extend .d-flex;
        justify-content: space-between;

        .btn_primary,
        .btn_black{
            span{
                @extend .font_26;
                @extend .poppins_300;
            }
        }
    }

    .card_list{
        @extend .card_list;
        
        &.parents_list{
            h4{
                @extend .mt-3;
            }
        }
    }

}