.sub_admin{

    .heading_area{
        @extend .heading_area;
        @extend .d-flex;
        justify-content: space-between;
    }

    .card_list{
        @extend .card_list;
        
        &.sub_admin_list{
            h4{
                @extend .mt-3;
            }
        }
    }

}