.groups {
  .heading_area {
    @extend .heading_area;

    @extend .d-flex;
    justify-content: space-between;

    .btn_primary,
    .btn_black {
      span {
        @extend .font_26;
        @extend .poppins_300;
      }
    }
  }

  .card_list {
    @extend .card_list;

    .group_profile_pic {
      @extend .mb-1;

      img {
        @include img_object_fit_center;
        height: 11rem;
        width: 11rem;

        @media screen and (max-width: 1440px) {
          height: 9rem;
          width: 9rem;
        }
      }
    }

    .group_header_detail {
      margin-left: 2.5rem;

      @media screen and (max-width: 1440px) {
        margin-left: 1rem;
      }

      .group_name {
        @extend .poppins_600;
        @extend .font_22;
        @extend .text-capitalize;
        line-height: 3.3rem;
        color: $black;

        @media screen and (max-width: 1440px) {
          font-size: 1.8rem;
          line-height: 2.3rem;
        }
      }
      .group_member {
        & > span {
          @media screen and (max-width: 1440px) {
            font-size: 1.4rem;
            line-height: 1.8rem;
          }
        }
        li {
          @extend .position-relative;
          margin-right: -1rem;
          img {
            border: 0.2rem solid $white;
            @extend .radius_full;
            @include img_object_fit_center;
            height: 5.2rem;
            width: 5.2rem;

            @media screen and (max-width: 1620px) {
              height: 3.5rem;
              width: 3.5rem;
            }
          }

          .member_above_5 {
            @include d_flex_center;
            height: 5rem;
            width: 5rem;
            background: $gray_status_border;
            color: $dark_gray;
            @extend .font_20;
            line-height: 3rem;
            @extend .radius_full;
            border: 0.2rem solid $gray;

            @media screen and (max-width: 1620px) {
              height: 3.5rem;
              width: 3.5rem;
              font-size: 1.4rem;
              line-height: 2rem;
            }
          }
        }
      }
    }
    .group_progress_cards {
      .group_name {
        @extend .poppins_600;
        @extend .font_22;
        line-height: 3.3rem;
        color: $black;
        margin-bottom: 2rem;
        @media screen and (max-width: 1440px) {
          font-size: 1.8rem;
          line-height: 2.3rem;
        }
      }
      .card {
        background: $white;
        @extend .radius_20;
        @include boxShadow(0 0.4rem 0.4rem rgba($dark_black, 0.1));
        @extend .border-0;
        padding: 2rem;
        @media screen and (max-width: 1620px) {
          padding: 1.5rem;
        }

        .rounded_progressbar {
          margin-bottom: 2rem;
          width: 11.8rem;
          height: 11.8rem;
          position: relative;

          span {
            position: absolute;
            @extend .poppins_600;
            @extend .font_42;
            line-height: 6.3rem;
            color: $black;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
          }

          @media screen and (max-width: 1620px) {
            // zoom: 0.8;
            @include zoom(0.8);
          }

          @media screen and (max-width: 1440px) {
            // zoom: 0.6;
            @include zoom(0.6);
          }
        }
        p {
          color: $dark_gray;
          @extend .mb-0;

          @media screen and (max-width: 1620px) {
            font-size: 1.4rem;
            line-height: 1.8rem;
          }

          a {
            color: $red_highlighted;
          }
        }
      }
    }
  }

  &.view_profile {
    .group_header_detail {
      margin-left: 0;
    }
  }
}

.add_class_group {
  .modal-content {
    .modal-body {
      max-height: unset !important;

      .students_unassigned_area,
      .students_assigned_area {
        max-width: 45%;

        .card {
          padding: 1.5rem;
          height: 100%;
          height: calc(100% - 3rem);

          .searchbar {
            button {
              right: 1rem;
              top: 0.8rem;
            }
            input {
              padding: 2rem;
              font-size: 1.4rem;
              margin-bottom: 1rem;
              border: 0.2rem solid $light_gray;
            }
          }

          ul {
            max-height: 30rem;
            overflow-y: auto;
            margin-top: 1rem !important;
          }

          .custom-control-label {
            &::before {
              left: -2.6rem;
            }
            &::after {
              left: -2.7rem;
            }
          }
          .select_all_none {
            background: $gray_status_border;
            padding: 1rem 1.5rem;
            border-radius: 0.5rem;
          }
        }
      }
      .students_move_area {
        width: 10%;
        align-items: center;
        justify-content: center;
        display: flex;
        flex-direction: column;
        font-size: 3rem;

        .move_into_assigned,
        .move_into_unassigned {
          @include transition(all 0.4s ease-in-out);
          @extend .cursor_pointer;
          &:hover {
            color: $red_highlighted;
          }
        }
      }
      .students_list_label {
        font-weight: 600;
        @extend .font_14;
        margin-top: 0.5rem;
      }
    }
  }
}

.edit_class_group {
  .form-group {
    min-height: 1rem !important;
  }
  textarea {
    padding: 1.5rem;
  }
}
.modal_student_lessons {
  .student_name {
    span {
      color: red;
    }
  }
  .lessons_timer {
    @extend .d-flex;
    align-items: baseline;
    label {
      margin-bottom: 0 !important;
      padding-left: 0.5rem; 
    }
  }
}
