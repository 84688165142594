@mixin ripple{
    @extend .position-fixed;
    right: 3.5rem;
    bottom: 3.5rem;
    background: $red_highlighted;
    color: $white;
    width: 4rem;
    height: 4rem;
    @extend .radius_full;
    display: none;
    @extend .justify-content-center;
    @extend .align-items-center;
    animation: ripple 2s linear infinite;

    @media screen and (max-width: 1536px){
        display: flex;
    }
}


@mixin zoom($property){

    @supports (zoom: unset){
        zoom: $property;
    }
        
    @supports not (zoom: unset) {
        -moz-transform: scale($property); /* Firefox */
        -moz-transform-origin: 0 0;
        -o-transform: scale($property); /* Opera */
        -o-transform-origin: 0 0;
        -webkit-transform: scale($property); /* Safari And Chrome */
        -webkit-transform-origin: 0 0;
        transform: scale($property); /* Standard Property */
        transform-origin: 0 0;  /* Standard Property */
    }
}


@mixin multiline_ellipsis{
    -webkit-box-orient: vertical;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
}
.multiline_ellipsis{
    @include multiline_ellipsis;
}

@mixin text_ellipsis{
    text-overflow: ellipsis;
    overflow: hidden !important;
    white-space: nowrap;
    display: block;
    width: 100%;
}


@mixin appearance($property){
    -webkit-appearance: $property; /* Safari & Chrome */
    -moz-appearance: $property; /* Firefox */
    -o-appearance: $property; /* Opera */
    appearance: $property;
}


@mixin transition($property){
    -webkit-transition: $property; /* Safari & Chrome */
    -moz-transition: $property; /* Firefox */
    -ms-transition: $property;
    -o-transition: $property; /* Opera */
    transition: $property;
}


@mixin boxShadow($property){
    -webkit-box-shadow: $property; /* Safari & Chrome */
    -moz-box-shadow: $property; /* Firefox */
    -ms-box-shadow: $property;
    -o-box-shadow: $property; /* Opera */
    box-shadow: $property;
}


@mixin transition_border_boxShadow($property){
    -webkit-transition: border-color $property ease-in-out, box-shadow $property ease-in-out; /* Safari & Chrome */
    -moz-transition: border-color $property ease-in-out, box-shadow $property ease-in-out; /* Firefox */
    -o-transition: border-color $property ease-in-out, box-shadow $property ease-in-out; /* Opera */
    transition: border-color $property ease-in-out, box-shadow $property ease-in-out;
}


@mixin rotate($property){
    -webkit-transform: rotate($property); /* Safari & Chrome */
    -moz-transform: rotate($property); /* Firefox */
    -ms-transform: rotate($property); /* Internet Explorer */
    -o-transform: rotate($property); /* Opera */
    transform: rotate($property);
}


@mixin translateY($property){
    transform: translateY($property);
    -webkit-transform: translateY($property); /* Safari & Chrome */
    -moz-transform: translateY($property); /* Firefox */
    -ms-transform: translateY($property); /* Internet Explorer */
    -o-transform: translateY($property); /* Opera */
}


@mixin d_flex_center{
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-align-items: center;
    align-items: center;
}


// form > input validation success
@mixin valid_feedback_icon{
    @extend .position-absolute;
    right: 1.2rem;
    top: .9rem;
    border: .2rem solid $validation_green;
    @extend .radius_full;
    height: 2rem;
    width: 2rem;
    @include d_flex_center;
}


// form > input validation error 
@mixin invalid_feedback_icon{
    @extend .position-absolute;
    right: 1.2rem;
    top: .8rem;
    @extend .radius_full;
    height: 2.4rem;
    width: 2.4rem;
    @include d_flex_center;
}


@mixin form_validation{
    &:focus{
        @include boxShadow(none);
        border: .2rem solid $blue;
    }

    &.valid{
        border: .2rem solid $validation_green;
        padding-right: calc(1.5em + 0.75rem);
    }
    &.invalid{
        border: .2rem solid $red;
        padding-right: calc(1.5em + 0.75rem);
    }
}


// Cross button
@mixin cross_btn{
    @extend .radius_full;
    border: .2rem solid $white;
    height: 2rem;
    width: 2rem;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    right: 0;
    background: $red_highlighted;
    img{
        width: .8rem;
        height: .8rem;
        @extend .border-0;
    }
}


@mixin add_new_file_thumbnail{
    @extend .position-relative;

    .add_new_file_icon{
        width: 6rem;
        height: 6rem;
        float:left;
        background: $white;
        border: .1rem dashed $black;
        @include d_flex_center;
        @extend .font_30;
        color: $red_highlighted;
        @extend .radius_10;
        margin: 0 .5rem 2rem;
        @extend .cursor_pointer;
    }

    [type="file"]{
        @extend .position-absolute;
        width: 6rem;
        height: 6rem;
        left: 0;
        top: 0;
        opacity: 0;
        z-index: 99;
    }

    .dropdown-item{
        [type="file"]{
            height: 100%;
            width: 100%;
        }
    }
}


@mixin uploaded_img_thumbnail{
    float: left;
    margin: 0 .5rem 2rem;
    position: relative;
    @include d_flex_center;
    width: 6rem;
    height: 6rem;
    border: .1rem dashed $scroll_thumb;
    @extend .radius_10;

    & > img, & > video {
        @extend .radius_10;
        max-width:5.8rem;
        max-height: 5.8rem;
        @include img_object_fit_center;
    }

    .cross_btn{
        border: .2rem solid $white;
        height: 2rem;
        width: 2rem;
        top: -.9rem;
        right: -.9rem;
        background: $red_highlighted;
        @extend .radius_full;
        padding: .5rem;
        @include d_flex_center;
        @extend .cursor_pointer;

        img{
            width: .8rem;
            height: .8rem;
        }
    }
}


// Switch
@mixin custom-switch{
    padding-left: 0;
    @extend .cursor_pointer;

    .custom-control-label{
        padding: 0.2rem;
        float: left;
        width: 4rem;
        @extend .cursor_pointer;

        &:before{
            content: '';
            width: 4rem;
            height: 2.2rem;
            background: $red_highlighted;
            border: 0;
            @extend .radius_50;
            padding: 0.2rem;

            left: 0;
        }

        &:after{
            content: '';
            width: 1.8rem;
            height: 1.8rem;
            left: 0.2rem;
            @extend .radius_full;
            background: $white;
        }
    }
    .custom-control-input:checked ~ .custom-control-label::before{
        background: $validation_green;
    }
    .custom-control-input:checked ~ .custom-control-label::after{
        left: 1.2rem;
    }
}
.custom-switch{
    @include custom-switch;
}


// Animation Delay
@mixin animation_delay($property){
    -webkit-animation-delay: $property;
    animation-delay: $property;
}


// Animation duration
@mixin animation_duration($property){
    -webkit-animation-duration: $property;
    animation-duration: $property;
}


// Loader Animation
@mixin loader{
    position: fixed;
    background: $red_highlighted;
    background: rgba( $red_highlighted, .97);
    width: 100%;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: 999999;
    display: flex;
    @extend .align-items-center;
    @extend .justify-content-center;

    .line-scale-party{
        &>div{
            width:.5rem;
            height:6.5rem;
            @extend .d-inline-block;
            border-radius:.2rem;
            margin:.8rem;
            background-color:$white;
        }
    }
    .line-scale-party>div:nth-child(1), .line-scale-party>div:nth-child(7){
        @include animation_delay(.48s);
        @include animation_duration(.54s);
    }
    .line-scale-party>div:nth-child(2), .line-scale-party>div:nth-child(5){
        @include animation_delay(-.15s);
        @include animation_duration(1.15s);
    }
    .line-scale-party>div:nth-child(3){
        @include animation_delay(40ms);
        @include animation_duration(.77s);
    }
    .line-scale-party>div:nth-child(4){
        @include animation_delay(-.12s);
        @include animation_duration(.61s);
    }
    .line-scale-party>div:nth-child(6){
        @include animation_delay(-.01s);
        @include animation_duration(.61s);
    }
    .line-scale-party>div:nth-child(8){
        @include animation_delay(-.1s);
        @include animation_duration(.61s);
    }
    .line-scale-party > div{
        -webkit-animation-fill-mode:both; 
        animation-fill-mode:both; 
        -webkit-animation-name:line-scale-party; 
        animation-name:line-scale-party; 
        -webkit-animation-iteration-count:infinite; 
        animation-iteration-count:infinite; 
        @include animation_delay(0s);
    }
}


// Inner Loader animation
@mixin inner_loader{
    border: .5rem solid $light_pink;
    border-radius: 50%;
    border-top: .5rem solid $red_highlighted;
    width: 6rem;
    height: 6rem;
    -webkit-animation: spin 2s linear infinite; /* Safari */
    animation: spin 2s linear infinite;
    background: transparent;
    display: inline-flex;
}


// Image as a object
@mixin img_object_fit_center{
    object-fit: cover;
    object-position: center;
}
.img_object_fit_center{
    @include img_object_fit_center;
}


// DropDown Menu 
@mixin dropdown_menu{
    left: auto;
    right: 0;
    top: 2.7rem;
    padding: 0.5rem 1rem;
    background: $white;
    box-shadow: 0 .5rem 1rem rgba( $dark_black, 0.25);
    @extend .radius_10;

    .dropdown-item{
        @extend .font_14;
        @extend .radius_10;
        line-height: 3.6rem;        

        @media screen and (max-width: 1919px) {
            min-width: 1rem;
            min-height: 4rem;
            min-width: 18rem;
            display: flex;
            align-items: center;
            white-space: unset;
            line-height: 1.6rem;
            padding-top: 1rem;
            padding-bottom: 1rem;
        }
        @media screen and (max-width: 1200px) {
            min-width: 1rem;
            min-height: 2.5rem;
            min-width: 18rem;
            display: flex;
            align-items: center;
            white-space: unset;
            line-height: 1.8rem;
            padding-top: .65rem;
            padding-bottom: .65rem;
        }

        &:hover{
            background: $red_highlighted;
            color: $white;
        }

        .global_file_upload{
            @extend .position-absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            width: 100%;
            height: 100%;
            opacity: 0;
            z-index: 1;
            @extend .cursor_pointer;
        }
    }
}



// Audio playing Animation
@mixin audio_playing_animation{
    width: 100%;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    display: flex;
    @extend .align-items-center;
    @extend .justify-content-center;

    .audio_playing_animation>div:nth-child(-n+5), .audio_playing_animation>div:nth-last-child(-n+4){height: 1rem;}
    .audio_playing_animation>div:nth-child(6), .audio_playing_animation>div:nth-child(44), .audio_playing_animation>div:nth-child(13),
    .audio_playing_animation>div:nth-child(34){height: 2rem;}
    .audio_playing_animation>div:nth-child(7), .audio_playing_animation>div:nth-child(43), 
    .audio_playing_animation>div:nth-child(22), .audio_playing_animation>div:nth-child(23), .audio_playing_animation>div:nth-child(33), .audio_playing_animation>div:nth-child(35) {height: 3rem;}
    .audio_playing_animation>div:nth-child(8), .audio_playing_animation>div:nth-child(12), .audio_playing_animation>div:nth-child(14), .audio_playing_animation>div:nth-child(42), 
    .audio_playing_animation>div:nth-child(21), .audio_playing_animation>div:nth-child(24), 
    .audio_playing_animation>div:nth-child(25){height: 3.5rem;}
    .audio_playing_animation>div:nth-child(9), .audio_playing_animation>div:nth-child(41),
    .audio_playing_animation>div:nth-child(26){height: 4rem;}
    
    .audio_playing_animation{
        &>div{
            width:.6rem;
            height:5rem;
            @extend .d-inline-block;
            border-radius:.2rem;
            margin:.8rem .3rem;
            background-color:$white;
        }
    }

    .audio_playing_animation.animating>div:nth-child(1), .audio_playing_animation.animating>div:nth-child(7),
    .audio_playing_animation.animating>div:nth-child(13), .audio_playing_animation.animating>div:nth-child(19),
    .audio_playing_animation.animating>div:nth-child(25), .audio_playing_animation.animating>div:nth-child(31),
    .audio_playing_animation.animating>div:nth-child(37), .audio_playing_animation.animating>div:nth-child(45){
        @include animation_delay(1s);
        @include animation_duration(1s);
    }
    .audio_playing_animation.animating>div:nth-child(2), .audio_playing_animation.animating>div:nth-child(5), .audio_playing_animation.animating>div:nth-child(11), .audio_playing_animation.animating>div:nth-child(17), .audio_playing_animation.animating>div:nth-child(23), .audio_playing_animation.animating>div:nth-child(29), .audio_playing_animation.animating>div:nth-child(35), .audio_playing_animation.animating>div:nth-child(41), .audio_playing_animation.animating>div:nth-child(47){
        @include animation_delay(-.15s);
        @include animation_duration(1.15s);
    }
    .audio_playing_animation.animating>div:nth-child(3), .audio_playing_animation.animating>div:nth-child(9), .audio_playing_animation.animating>div:nth-child(15),
    .audio_playing_animation.animating>div:nth-child(21), .audio_playing_animation.animating>div:nth-child(27), .audio_playing_animation.animating>div:nth-child(33), .audio_playing_animation.animating>div:nth-child(39), .audio_playing_animation.animating>div:nth-child(43){
        @include animation_delay(40ms);
        @include animation_duration(1.77s);
    }
    .audio_playing_animation.animating>div:nth-child(4), .audio_playing_animation.animating>div:nth-child(10), .audio_playing_animation.animating>div:nth-child(16),
    .audio_playing_animation.animating>div:nth-child(22), .audio_playing_animation.animating>div:nth-child(28), .audio_playing_animation.animating>div:nth-child(34), .audio_playing_animation.animating>div:nth-child(40), .audio_playing_animation.animating>div:nth-child(46){
        @include animation_delay(-.12s);
        @include animation_duration(.61s);
    }
    .audio_playing_animation.animating>div:nth-child(6), .audio_playing_animation.animating>div:nth-child(12), .audio_playing_animation.animating>div:nth-child(18),
    .audio_playing_animation.animating>div:nth-child(24), .audio_playing_animation.animating>div:nth-child(30), .audio_playing_animation.animating>div:nth-child(36), .audio_playing_animation.animating>div:nth-child(42), 
    .audio_playing_animation.animating>div:nth-child(48){
        @include animation_delay(-.01s);
        @include animation_duration(.61s);
    }
    .audio_playing_animation.animating>div:nth-child(8), .audio_playing_animation.animating>div:nth-child(14), .audio_playing_animation.animating>div:nth-child(20),
    .audio_playing_animation.animating>div:nth-child(26), .audio_playing_animation.animating>div:nth-child(32), .audio_playing_animation.animating>div:nth-child(38),
    .audio_playing_animation.animating>div:nth-child(44){
        @include animation_delay(-.1s);
        @include animation_duration(.61s);
    }



    .audio_playing_animation > div{
        -webkit-animation-fill-mode:both; 
        animation-fill-mode:both; 
        -webkit-animation-name:line-scale-party; 
        animation-name:line-scale-party; 
        -webkit-animation-iteration-count:infinite; 
        animation-iteration-count:infinite; 
        @include animation_delay(0s);
    }
}


@mixin option_humberger_toggle{
    position: relative;
    width: 3.5rem;
    height: 4rem;
    @extend .cursor_pointer;
    float: right;
    margin-top: .8rem;

    .option_humberger_icon{
        display: block;
        width: 80%;
        height: 0.3rem;
        margin-top: 2rem;
        background: $red_highlighted;

        &::before, &::after{
            content: " ";
            float: right;
            height: .3rem;
            background: $red_highlighted;
        }

        &::before{
            width: 120%;
            margin: -1.2rem 0 0 0;
        }
        &::after{
            width: 120%;
            margin: 1.2rem 0 0 0;
        }
    }
}