html {
    font-size: 10px;
}

body {
    @extend .font_16;
    line-height: 2.4rem;
    background-color: $white;
    @extend .poppins_500;
}

a,
a:hover {
    text-decoration: none;
    @include transition(all .3s ease-in-out);
    ;
}

a {
    color: $red_highlighted;

    &:hover {
        color: $black;
    }
}

form,
.form-row {
    @extend .fullWidth;
}

*,
*:focus,
*.active {
    outline: none;
}

*::selection {
    background-color: $light_pink;
}

*::-webkit-selection {
    background-color: $light_pink;
}

*::-moz-selection {
    background-color: $light_pink;
}

// Custom Scrollbar
::-webkit-scrollbar {
    width: .5rem;
    height: .5rem;
}

::-webkit-scrollbar-track {
    background: $gray;
}

::-webkit-scrollbar-thumb {
    background: $scroll_thumb;
}

::-webkit-scrollbar-thumb:hover {
    background: $scroll_thumb_hover;
}

// history box
.history_list::-webkit-scrollbar {
    width: .5rem;
    height: .5rem;
}

.history_list::-webkit-scrollbar-track {
    background: $gray;
    border-radius: 10rem;
}

.history_list::-webkit-scrollbar-thumb {
    background: $scroll_thumb;
    border-radius: 10rem;
}

.history_list::-webkit-scrollbar-thumb:hover {
    background: $scroll_thumb_hover;
}

[placeholder] {
    text-overflow: ellipsis;
}

::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    text-overflow: ellipsis;
}

::-moz-placeholder {
    /* Firefox 19+ */
    text-overflow: ellipsis;
}

:-ms-input-placeholder {
    /* IE 10+ */
    text-overflow: ellipsis;
}

:-moz-placeholder {
    /* Firefox 18- */
    text-overflow: ellipsis;
}


#wrapper {
    @extend .d-flex;
}

#content-wrapper {
    width: calc(100% - 30rem);
    @extend .position-relative;
    padding-left: 7rem;

    @media screen and (max-width: 1680px) {
        padding-left: 3rem;
    }

    @media screen and (max-width: 991px) {
        padding-left: 1.5rem;
        width: calc(100% - 0rem);
    }

    #content {
        flex: 1 0 auto;
        padding-right: 1.5rem;
        padding-bottom: 1.5rem;
    }

    .toggled~& {
        @media screen and (min-width: 992px) {
            width: calc(100% - 9rem);
        }
    }
}

.table {
    tr {
        td {
            border-top: .1rem solid $light_gray;
        }

        &:last-child {
            td {
                border-bottom: .1rem solid $light_gray;
            }
        }
    }

    th,
    td {
        vertical-align: middle;
        padding: 1.5rem 0.75rem;
    }
}

.coming_soon {
    .card {
        padding: 10rem 5rem;
        text-align: center;
        @include boxShadow(0 .9rem 10rem rgba($black, .2));
        background: $white;

        @media screen and (max-width: 768px) {
            padding: 5rem 1.5rem;
        }
    }

    h1 {
        color: $dark_gray;
        @extend .poppins_300;
        @extend .text-uppercase;
        letter-spacing: 2rem;

        @media screen and (max-width: 768px) {
            letter-spacing: 1rem;
        }

        @media screen and (max-width: 576px) {
            letter-spacing: .5rem;
            line-height: 3rem;
            margin-bottom: 0;
        }

        span {
            @extend .poppins_bold;
            @extend .font_56;
            @extend .fullWidth;
            padding-top: 2rem;

            @media screen and (max-width: 768px) {
                font-size: 3.6rem;
                padding-top: 0;
            }

            @media screen and (max-width: 576px) {
                margin-top: 1rem;
            }
        }
    }
}


// PreLoader
.preloader {
    @include loader;
}

.inner_loader {
    @include inner_loader;
}

@media screen and (max-width: 991px) {
    .topbar+div {
        margin-top: 8rem
    }
}

.cur_point {
    cursor: pointer;
}

a.fc-timegrid-event.fc-v-event.fc-event.fc-event-start.fc-event-end.fc-event-today.fc-event-future {
    cursor: pointer;
}

.activeDailyHeaderScheduling {
    color: red;
}

.remove_border {
    .form-control {
        border: none !important;
        padding: 0px;
        padding: 0px 10px 0px 0px;
        min-width: 150px;

        .ant-select-arrow {
            padding-right: 5px;
        }
    }
}

.mass-email {
    width: 91rem;
    margin: auto;
    .ant-form {
        .ant-form-item {
            .ant-form-item-label>label {
                width: 160px;
            }
        }
        .ant-btn {
            max-width: 18rem !important;
            padding: 8px 10px;
        }
        .upload_file_icon {
            gap: 5px;
            display: flex;
        }
        .ant-upload-list-item-actions > .ant-upload-list-item-action {
            padding: 0px !important;
        }
    }
}

.adminEdit {
    display: flex;

    .ant-select-selector,
    .ant-input {
        border-radius: 5rem;
        width: auto;
    }
}

.schoolPayment {
    .form-group {
        .marginZeroImportant {
            margin: 0 10px 0 0 !important;
        }

        .paymentCancel {
            min-width: 9rem;
            padding: 0.45rem 1.58rem;
        }
    }
}

.ant-input-number-handler-wrap {
    display: none !important;

}

.ant-input-number:hover .ant-input-number-handler-wrap {
    display: none;
}

.ant-input-number-input-wrap {
    width: 100%;
}

.cursor-pointer {
    cursor: pointer;
}

.prevent_drag {
    user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  -webkit-user-drag: none;
  cursor: pointer;
}

.word_spacing {
    word-break: normal;
}

.cke_notifications_area {
    display: none !important;
}

.pd-2 {
    input {
        padding: 0 20px !important;
    }
}

.dollar_icon {
    position: absolute;
    left: 11px;
    top: 50%;
}