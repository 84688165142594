.modal-dialog-centered {
  @extend .d-flex;
}

.modal {
  &.show {
    background: rgba($dark_black, 0.4);
  }
  .modal-dialog {
    max-width: 90%;

    &.mx-width-10h {
      max-width: 125rem;
      @media screen and (max-width: 980px) {
        max-width: 90%;
      }
    }
    &.mx-width-6h {
      max-width: 60rem;
      @media screen and (max-width: 767px) {
        max-width: 90%;
      }
    }
    &.mx-width-53h {
      max-width: 53rem;
      @media screen and (max-width: 767px) {
        max-width: 90%;
      }
    }
    @media screen and (min-width: 768px) {
      max-width: 91rem;
      padding: 0 1.5rem;
    }
    @media screen and (max-width: 575px) {
      margin-left: auto;
      margin-right: auto;
    }
    .modal-content {
      @extend .radius_10;
      @extend .border-0;
      .modal-header {
        padding: 2.3rem 4rem;
        @extend .border-0;
        margin-bottom: 1rem;
        h2 {
          @extend .mb-0;
        }
        .close {
          opacity: 1;
          padding: 1rem 0rem;
        }
        @media screen and (max-width: 767px) {
          padding: 1.5rem 2.5rem 1.5rem 2rem;
        }
      }
      .modal-body {
        padding: 0 4rem 0;
        padding: 0 1.5rem 0 2.5rem;
        max-height: calc(100vh - 18rem);
        overflow: auto;
        margin-bottom: 4rem;
        @media screen and (max-width: 767px) {
          padding: 0 0.5rem 0 1rem;
          margin-bottom: 2rem;
        }
        form {
          .form-row {
            margin-right: -1rem;
            margin-left: -1rem;
            & > [class*="col-"] {
              padding-left: 1rem;
              padding-right: 1rem;
            }
          }
          .form-group {
            margin-bottom: 1rem;
            min-height: 6rem;
            @extend .fullWidth;
            .form-control {
              line-height: 2.4rem;
              color: $black;
              overflow: auto;
        white-space: nowrap;
            //   span {
            //     text-overflow: ellipsis;
            //     overflow: hidden !important;
            //     white-space: nowrap;
            //     display: block;
            //     width: 100%;
            //     margin-top: 4px;
            //   }
            }
            &.class_group_select_student {
              width: auto;
              min-width: 50%;
              .rbt {
                &-input-multi {
                  border-radius: 2rem;
                }
                &-input-wrapper {
                  flex-wrap: wrap;
                  justify-content: flex-start;
                }
              }
            }
          }
          textarea {
            @extend .radius_10;
          }
        }
        .custom_color {
          @extend .float-left;
          padding: 0.9rem 2rem;
          border-radius: 4rem;
          border: 0.1rem solid $light_gray;
        }
        .custom_color_sqrBox {
          height: 2rem;
          width: 2rem;
          @extend .float-left;
          background: $red_highlighted;
        }
      }
    }
    &.chat_modal {
      @media screen and (min-width: 768px) {
        width: 95%;
        max-width: 162rem;
      }
      .modal-body {
        max-height: calc(100vh - 5rem);
      }
    }
    &.reject_modal,
    &.addNoteModal,
    &.edit_class_group {
      .modal-body {
        max-height: unset;
      }
      textarea {
        @extend .font_16;
        line-height: 3.6rem;
        color: $dark_gray;
        // @extend .radius_10;
        @extend .fullWidth;
        border: 0.1rem solid $light_gray;
      }
    }
    &.login_modal {
      .login_details {
        max-width: 100%;
        @media screen and (max-width: 768px) {
          flex-direction: column;
      }
      }
    }
    // Choose from library in library page
    &.librayModal {
      .table_filter {
        @extend .d-flex;
        @extend .align-items-center;
        @extend .justify-content-end;
        .inner_search { 
          @extend .fullWidth;
          max-width: 22rem;
          @extend .mb-4;
          button {
            right: 0.6rem;
            top: 0.6rem;
          }
          .form-control{
            padding: 0.375rem 3rem; 
          }
        }
        .reset {
          @extend .fullWidth;
          @extend .pl-3;
          @extend .mb-4;
          max-width: 7rem;
          text-align: right;
        }
      }
      table {
        min-width: 65rem;
        @extend .font_14;
      }
    }
    &.add_credit_card {
      h4 {
        .form-check {
          label {
            @extend .poppins_500;
            // font-size: 1.4rem;
          }
        }
      }
    }
    // View Video / Audio / Image / PDF
    &.lesson_video_desc {
      width: 100%;
      .lesson_view_document {
        height: calc(100vh - 18rem);
      }
      .lesson_view_audio_card {
        @media screen and (max-width: 576px) {
          padding: 1rem 1.5rem;
          min-height: 35rem;
          .audio_playing_animation {
            // zoom: .9;
            @include zoom(0.9);
          }
        }
      }
    }
  }
  .modelColor {
    .modal-content {
      background-color: #fff !important;
    }
  }
}
.student_tabs_listing {
  ul {
    border: 0;
    margin-bottom: 2rem;
    padding: 0 1.5rem 0 2.5rem;
    li {
      width: 47%;
      text-align: center;
      margin-left: 1rem;
      @extend .cursor_pointer;
      border-bottom: 0.2rem solid #ffffff;
      &:hover {
        color: #ff1616;
        border-bottom: 0.2rem solid #ff1616;
        a {
          color: #ff1616;
        }
      }
      &.active {
        color: #ff1616;
        border-bottom: 0.2rem solid #ff1616;
        a {
          color: #ff1616;
        }
      }
      a {
        color: #000;
        border: 0;
      }
    }
  }
  .modal-body {
    form {
      .student_info {
        display: none;
      }
      .active {
        display: block !important;
        border-bottom: 0.1rem solid #ffffff;
      }
      .payment_info {
        display: none;
      }
      .active {
        display: block !important;
        // border-bottom: 0.1rem solid $light_gray;
        .add_new_payment {
          margin-left: -1.5rem;
          @extend .fullWidth;
          @extend .mt-5;
        }
        .pay_content {
          padding-left: 0 !important;
          padding-right: 0 !important;
          .biling_list {
            span {
              display: block;
              @extend .font_10;
              line-height: 1.8rem;
            }
          }
        }
      }
      .Edit_Billing_Information {
        border-top: 0.1rem solid $light_gray;
        .active {
          display: block;
        }
      }
    }
  }
}

.studentSelect {
  position: unset;
  opacity: 1;
  .ant-select-selection-placeholder {
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    font-family: poppins_500;
  }
  .ant-select-selector {
    border: none !important;
    box-shadow: none !important;
    &:hover {
      border: none;
      box-shadow: none;
    }
    .ant-select-selection-item{
      background-color: rgba(255,22,22,.2);
color: #ff1616;
white-space: nowrap;
border: none;
.ant-select-selection-item-remove {
  display: flex;
  color: #ff1616;
}
    }
  }
}

.PhoneInput--disabled {
  background-color: #e9ecef;
}

.disabled_phone {
  background-color: #e9ecef;
}

.ant-select-disabled {
  background-color: #e9ecef;
  .ant-select-selector {
    background-color: #e9ecef !important;
  }
}