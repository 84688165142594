.data_table{
    table{
        min-width: 76.8rem;
        margin-bottom: 0;

        &.table_minWidth_11{
            min-width: 110rem;
        }
        &.table_minWidth_13{
            min-width: 130rem;
        }
        th{
            background: $gray;
            @extend .no_wrap;
        }
        .max_width_350{
            max-width: 35rem;
            @media screen and (max-width: 1280px){
                max-width: 25rem;
            }
        }
    }
}


.action{
    white-space: nowrap;
    a{
        color: $black;

        &:hover{
            color: $red_highlighted;
        }
    }
}


.pagination{
    .page-link{
        width: 3.2rem;
        height: 3.2rem;
        @extend .font_14;
        color: $light_black;
        @extend .text-center;
        @extend .border-0;
        padding: .9rem 0;
        @extend .mx-1;

        &.active, &:hover{
            background: $red_highlighted;
            color: $white;
            @extend .radius_5;
        }
    }
}
.table_records_pagination{
    @media screen and (max-width: 767px) {
        @include d_flex_center;
        flex-direction: column;

        & > span:first-child{
            margin-bottom: 2rem;
        }
    }
}