.userProfile {
  form {
    textarea {
      @extend .radius_10;
    }

    .inline_label_input {
      input {
        max-width: 8rem;
      }
    }
  }

  .data_table table {
    min-width: 50rem;
  }
  .custom-switch {
    @include custom-switch;
  }

  .lesson_type_field {
    @extend .d-flex;
    @extend .align-items-center;
    input {
      width: calc(100% - 10rem);
      float: left;
    }
    span {
      width: 5rem;
      float: left;
    }

    @media screen and (max-width: 768px) {
      input {
        width: calc(100% - 5rem);
        float: left;
      }
      span {
        width: 5rem;
        float: left;
      }
    }
    @media screen and (max-width: 575px) {
      input {
        width: calc(100% - 3rem);
        float: left;
      }
      span {
        width: 3rem;
        float: left;
      }
    }
  }

  .showPaymentOptionToggle {
    .dropdown-menu {
      font-size: 1.4rem;
      min-width: 17rem;
      border: 0.1rem solid rgba(0, 0, 0, 0.05);
      border-radius: 0.75rem;
      left: 0;
      right: auto;
      @extend .p-2;

      .dropdown-item {
        padding: 0.75rem 1.5rem;
        margin-bottom: 0.5rem;
        @extend .radius_5;

        &:hover {
          background: $red_highlighted;
          color: $white;
        }
      }
    }
  }
}

.costom_communication {
  .custom_heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 3rem;
    @media screen and (max-width: 767px){
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: 2rem;
    }
    .short {
      width: 100%;
      max-width: 18rem;
      display: flex;
      align-items: center;
      @media screen and (max-width: 768px){
          margin-bottom: 1.5rem;
      }
      label {
        white-space: nowrap;
        padding-right: 2rem;
      }
      select{
         text-transform: capitalize;
      }
    }
    .mail_link{
        p{
            font-size: 1.6rem;
            line-height: 2.4rem;
            color: $black;
            @media screen and (max-width: 420px){
                font-size: 1.4rem;
                line-height: 2rem;
            }
        }
    }
  }
  .custom_table {
    th {
      padding: 1.5rem 4rem;
      white-space: nowrap;
    }
    td {
      padding: 0;
      p{
        @include text_ellipsis;
        width: 100%;
        max-width: 25rem;
      }

      table{
        table-layout: fixed;
        td{
          padding: 1.5rem 4rem;

          &:nth-child(1){width: 19.66%}
          &:nth-child(2){width: 16.66%}
          &:nth-child(3){width: 19.66%}
          &:nth-child(4){width: 16.66%}
          &:nth-child(5){width: 13.66%}
          &:nth-child(6){width: 13.66%}
        }
      }
    }
    .communication_list {
      img {
        width: 1.2rem;
        object-fit: cover;
        object-position: center;
        margin-right: 2rem;
      }
      &.right_active {
        img {
          transform: rotate(-90deg);
        }
      }
    }
    .list_active {
      display: table-row;
    }
    .list_inactive {
      display: none;
    }
  }
}

.quick_link_main {
  display: flex;
  flex-direction: column;
  .d-flex {
    @media screen and (max-width: 576px) {
      flex-wrap: wrap;
    }
  }
}