.admin_dashboard,
.supre_admin_dashboard,
.student_dashboard {
  @media screen and (max-width: 767px) {
    flex-direction: column;
  }
}

.dashboard_cards {
  width: calc(100% - 45rem);

  @media screen and (max-width: 1680px) {
    width: calc(100% - 40rem);
  }

  @media screen and (max-width: 1536px) {
    width: calc(100% - 0rem);
  }

  .welcome_card {
    background: linear-gradient(85.61deg,
        $red_highlighted 2.57%,
        $red_hover 78.43%);
    border-radius: 1rem;
    @extend .fullWidth;
    @extend .position-relative;
    color: $white;
    padding: 3rem 0 2rem 7.7rem;
    overflow: hidden;

    @media screen and (max-width: 676px) {
      padding: 3rem 1.5rem 2rem 2.5rem;
    }

    @media screen and (max-width: 476px) {
      padding: 3rem 1.5rem 2rem 1.5rem;
    }

    .welcome_text {
      max-width: 50rem;
      @extend .position-relative;
      // z-index: 1;
    }

    h2 {
      color: $white;
      margin-bottom: 1.5rem;
    }

    p {
      @extend .font_18;
      line-height: 2.7rem;
      margin-bottom: 1.5rem;
    }

    .learn_more {
      @extend .font_14;
      line-height: 2.1rem;
      text-align: center;
      color: $red_highlighted;
      background: $white;
      border-radius: 5rem;
      padding: 1.1rem 0;
      width: 13.4rem;
      display: inline-block;

      &:hover {
        background: $black;
        color: $white;
      }
    }

    &::after {
      @extend .welcome_card_before;
      @include transition(all 0.6s ease-in-out);

      @media screen and (max-width: 676px) {
        content: unset;
      }
    }

    &:hover::after {
      content: "";
      opacity: 0.7;
      bottom: 3.5rem;
    }

    &::before {
      content: "";
      background: url(../images/welcome_graphic.png) no-repeat;
      width: 100%;
      position: absolute;
      height: 100%;
      top: 0;
      right: 0;
      background-position: 90% bottom;
      background-size: auto calc(100% - 2rem);
      @include transition(all 0.6s ease-in-out);

      @media screen and (max-width: 768px) {
        opacity: 0.35;
      }
    }

    &:hover::before {
      @media screen and (min-width: 769px) {
        content: "";
        background-position-x: 92%;
        opacity: 0.7;
      }
    }
  }

  .module_list {
    .card {
      @extend .card;
      @extend .border-0;
      min-height: 29.7rem;
      padding: 0 1.5rem;
      flex-direction: row;
      overflow: hidden;
      justify-content: space-around;


      @media screen and (max-width: 991px) {
        min-height: 1rem;
        padding-top: 2rem;
        padding-bottom: 2rem;
      }

      @media screen and (max-width: 420px) {
        // zoom: 0.8;
        @include zoom(0.8);
      }

      .quick_link {
        display: flex;
        width: 100%;
        padding: 10px;
        flex-wrap: wrap;
        margin-top: 5px !important;

        h2 {
          text-align: right;
        }

        .quick_link_group {
          padding-top: 10px;
          display: flex;
          flex-wrap: wrap;
          justify-content: center;

          .quick_link_redirect {
            cursor: pointer;
            border-radius: 5px;
            display: flex;
            width: 45%;
            margin: 7px;
            padding: 14px;
            box-shadow: rgba(0, 0, 0, 0.35) 0px 2px 8px;
            position: relative;
            justify-content: space-between;
            align-items: center;

            @media screen and (max-width: 425px) {
              width: 100%
            }
          }
        }
      }

      .card_img {
        @media screen and (max-width: 991px) {
          width: 100%;
          max-width: 50%;
          flex: 0 0 50%;
          text-align: center;
        }
      }

      .admin_dashboard_h2 {

        // // height: 30rem;
        // display: flex;
        // flex-direction: column;
        // justify-content: space-between;
        &_teacher {
          height: 26rem;
          display: flex;
          flex-direction: column;
          justify-content: space-between;

          h2 {
            text-align: end;
          }
        }

        h2 {
          text-align: end;
        }
      }

      .module_card_content {
        padding: 1.5rem 0 1.5rem 4rem;
        width: 100%;
        max-width: 25rem;

        @media screen and (max-width: 1680px) {
          padding: 1.5rem 0 1.5rem 1.5rem;
        }

        @media screen and (max-width: 991px) {
          width: 100%;
          max-width: 50%;
          flex: 0 0 50%;
        }

        p {
          @extend .fullWidth;
          @extend .font_55;
          line-height: 8.2rem;
          margin-bottom: 1.5rem;
          @extend .poppins_bold;
        }

        .see_details {
          @extend .font_14;
          line-height: 4rem;
          color: $white;
          background: $red_highlighted;
          border-radius: 2.5rem;
          display: inline-block;
          width: 11.9rem;
          text-align: center;

          &:hover {
            color: $white;
            background: $black;
            border-color: $black;
          }
        }

        .active_inactive {
          margin-bottom: 2rem;

          a {
            display: inline-block;
            margin-bottom: 0.5rem;
          }

          .active {
            @extend .active_green_button;
            margin-right: 0.5rem;
          }

          .inactive {
            @extend .inactive_green_button;
          }
        }

        .reporting_list {
          margin: 1rem 0 2rem;

          .list-inline-item {
            &:not(:last-child) {
              margin-right: 1.6rem;
            }
          }

          .dot {
            margin-right: 1.1rem;
          }
        }

        &.store_books_accessories {
          margin-bottom: 2rem;

          a {
            display: inline-block;
            margin-bottom: 0.5rem;
          }

          .store_badge {
            background: $white;
            margin-right: 0.5rem;
            color: $black;
            padding: 0.45rem 1rem;
            line-height: 2.1rem;
            border-radius: 0.3rem;
            text-decoration: none;
            transition: all 0.3s ease-in-out;

            &:hover {
              color: $white;
              background: $black;
              border-color: $black;
            }
          }
        }
      }

      .card_img {
        @media screen and (max-width: 576px) {
          max-width: 150px;

          img {
            max-width: 100%;
            height: auto;
          }

          &>div {
            @include zoom(0.7);
          }
        }
      }
    }
  }

  &.teacher_dashboard {
    width: 100%;

    .what_going_today {
      background: $gray;
      margin-bottom: 5rem;
      @extend .radius_20;
      overflow: hidden;

      .what_going_heading {
        background: $dark_gray;
        color: $white;
        @extend .px-5;
        @extend .py-3;

        h2 {
          color: $white;
        }
      }

      .slide_left_arrow,
      .slide_right_arrow {
        a {
          @extend .font_42;
          color: $dark_gray;
          opacity: 0.3;

          &:hover {
            opacity: 1;
          }
        }
      }

      .student_list {
        &>.col {
          width: 100%;
          max-width: 25%;
          flex-basis: unset;

          @media screen and (max-width: 1440px) {
            max-width: 33.33%;
          }

          @media screen and (max-width: 1240px) {
            max-width: 50%;
          }
        }

        .card {
          @extend .px-3;
          @extend .py-4;
          background: $white;
          @extend .text-center;
          @include boxShadow(0 0.3rem 1rem rgba($dark_black, 0.15));
          @extend .mb-5;
        }

        .student_profile_pic {
          @extend .mb-4;

          img {
            @include img_object_fit_center;
            @extend .profile_image_border_n_shadow;
            @extend .radius_full;
          }
        }

        .student_name {
          a {
            color: $black;
            @extend .text_ellipsis;

            &:hover {
              color: $red_highlighted;
            }
          }
        }
      }
    }

    .module_list {
      .card {
        @extend .card;
        @extend .border-0;
        min-height: 29.7rem;
        padding: 0 1.5rem;
        flex-direction: row;
        overflow: hidden;
        justify-content: space-around;

        .module_card_content {
          padding: 1.5rem 0 1.5rem 1.5rem;
          width: 100%;
          max-width: 25rem;

          @media screen and (max-width: 991px) {
            width: 100%;
            max-width: 50%;
            flex: 0 0 50%;
          }
        }
      }
    }
  }
}

.present_teacher {
  background: #f2f2f2;

  &:hover {
    @include boxShadow(0 0.3rem 1rem rgba($dark_black, 0.15));
  }

  .today_present {
    padding: 10px 15px;
    background-color: $gray;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    @extend .d-flex;
    @extend .align-items-center;
    @extend .justify-content-space-between;

    .day {
      border: 0;
      background: gray;
      color: white;
      border-radius: 5px;
    }
  }

  .teacherlist {
    padding: 0 15px;
    margin-bottom: 4rem;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;

    .row {
      flex-wrap: nowrap;
      overflow-x: auto;
    }

    .teacher {
      padding: 20px 0;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      .teacher_profile_pic {
        position: relative;
        margin-bottom: 2rem;
        width: 12rem;

        @media screen and (max-width: 576px) {
          width: 100%;
          text-align: center;
          margin-bottom: 1.5rem;
        }

        img {
          @extend .profile_image_border_n_shadow;
          @extend .radius_full;
          @include img_object_fit_center;
        }

        img+[type="file"] {
          position: absolute;
          left: 0;
          top: 0;
          right: 0;
          bottom: 0;
          opacity: 0;
          width: 12rem;
          height: 12rem;
          @extend .radius_full;
        }
      }

      h4 {
        width: 100%;
        max-width: 220px;
        padding-bottom: 1rem;
        border-bottom: 1px solid gray;
        cursor: pointer;
      }
    }
  }
}

@import "recent_activity";