.login_page {
  background: #212121;
  background-image: url("../images/login_bg_left.svg"),
    url("../images/login_bg_right.svg");
  background-position-x: left, right;
  background-position-y: bottom, bottom;
  background-repeat: no-repeat;
  height: 100vh;
}

.login_modal {
  padding: 4rem 0;
  @media screen and (max-width: 767px) {
    transform: translate(0, 0);
  }

  .modal-content {
    h1 {
      @media screen and (max-width: 576px) {
        font-size: 2rem;
        line-height: 2rem;
      }
    }
  }

  .login_details {
    @extend .fullWidth;
    @extend .d-flex;
    max-height: unset !important;

    .login_part {
      @extend .fullWidth;
      max-width: 50%;
      padding: 4rem 3rem 3rem 3rem;

      @media screen and (max-width: 768px) {
        max-width: 100%;
      }
      @media screen and (max-width: 576px) {
        padding: 3rem 2rem 1rem 2rem;
        max-width: 100%;
      }

      a {
        color: $red_highlighted;

        &:hover {
          color: $red_highlighted;
        }
      }

      .login_header {
        @extend .fullWidth;
        margin-bottom: 1.5rem;

        .login_header_logo {
          display: none;
        }

        .close {
          position: absolute;
          top: 1.5rem;
          right: 1.5rem;
          opacity: 1;
        }

        h2 {
          @extend .poppins_bold;

          @media screen and (max-width: 576px) {
            font-size: 1.8rem;
            line-height: 2rem;
          }
        }
      }

      .form-group {
        min-height: 1rem !important;
        margin-bottom: 2rem !important;

        &.password_form_group {
          margin-bottom: 0 !important;
        }
      }

      .forgot_password {
        @extend .fullWidth;
        margin-bottom: 1rem;
        @extend .font_14;
        text-align: right;

        a {
          color: $dark_gray;
          &:hover {
            color: $red_highlighted;
          }
        }
      }

      p {
        @extend .font_14;
        text-align: center;
        @extend .fullWidth;
      }
    }
    .marketing {
      @extend .fullWidth;
      max-width: 50%;
      padding: 4rem 3rem 3rem 3rem;
      @media screen and (max-width: 768px) {
        max-width: 100%;
      }
      h2 {
        font-size: 2.4rem;
        @extend .poppins_bold;
      }
      p {
        display: block;
        margin-bottom: 1.5rem;
        // display: -webkit-box;
        // -webkit-line-clamp: 2;
        // -webkit-box-orient: vertical;
        // overflow: hidden;
        // text-overflow: ellipsis;
        // height: 5rem;
      }
      ul {
        padding: 0;
        li {
          padding-left: 2rem;
          font-size: 1.2rem;
          line-height: 1.6rem;
          margin-bottom: 0.7rem;
          display: block;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
          height: 3.5rem;
          position: relative;
          &::before{
            content: '';
            display: block;
            width: 12px;
            height: 12px;
            background-color: red;
            border-radius: 100%;
            position: absolute;
            left: 0rem;
            top: 0.2rem;
          }
          // span {
          //   margin-right: 1rem;
          //   img {
          //     width: 1.2rem;
          //     height: 1.2rem;
          //   }
          // }
        }
      }
    }
  }
}
