@font-face {
    font-family: poppins_300;
    src: url(../fonts/poppins/Poppins-Light-300.ttf);
}
@font-face {
    font-family: poppins_400;
    src: url(../fonts/poppins/Poppins-Medium-400.ttf);
}
@font-face {
    font-family: poppins_500;
    src: url(../fonts/poppins/Poppins-Regular-500.ttf);
}
@font-face {
    font-family: poppins_600;
    src: url(../fonts/poppins/Poppins-SemiBold-600.ttf);
}
@font-face {
    font-family: poppins_bold;
    src: url(../fonts/poppins/Poppins-Bold.ttf);
}

@font-face {
    font-family: segoesc;
    src: url(../fonts/segoesc/segoesc.ttf);
}





.poppins_300{
    font-family: poppins_300;
}
.poppins_400{
    font-family: poppins_400;
}
.poppins_500{
    font-family: poppins_500;
}
.poppins_600{
    font-family: poppins_600;
}
.poppins_bold{
    font-family: poppins_bold;
}
.segoesc{
    font-family: segoesc;
    font-size: 26px;
    line-height: 32px;
}