.sidebar {
    background: $gray;
    width: 30rem;
    padding: 3rem 2.5rem 4rem;
    min-height: 100vh;
    position: relative;

    @media screen and (max-width: 991px) {
        position: fixed;
        width: 100%;
        max-width: 35rem;
        left: -100%;
        top: 0;
        height: 100vh;
        z-index: 999;
        @include transition(all .5s ease-in-out);

        &.show {
            left: 0;
            padding-right: 0;
            border-right: .1rem solid $white;

            @include transition(all 0.6s ease-in-out);
            @include boxShadow(0 1rem 2rem rgba($black, .2));

            @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
                background: linear-gradient(117.76deg, rgba($white, 0.7) -7.21%, rgba($white, 0.5) 118.08%);
                -webkit-backdrop-filter: blur(.8rem);
                backdrop-filter: blur(.8rem);
            }

            /* slightly transparent fallback for Firefox (not supporting backdrop-filter) */
            @supports not ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
                background: linear-gradient(117.76deg, rgba($white, 0.95) -7.21%, rgba($white, 0.95) 118.08%);
            }
        }
    }

    @media screen and (max-width: 576px) {
        max-width: 100%;
        padding: 1.5rem 1rem;
    }

    @media (orientation: landscape) and (max-width: 991px) {
        padding: 2rem 2rem 4rem;

        .sidebar_brand {
            img {
                height: 5.5rem;
            }
        }
    }

    .sidebar_nav {
        margin-top: 2.4rem;
        @extend .position-relative;
        margin-bottom: 1.5rem;
        min-height: 48rem;

        @media screen and (max-width: 991px) {
            height: calc(100vh - 18rem);
            overflow-y: auto;
            margin-bottom: 0;
            min-height: 1rem;
            padding-right: 1.5rem;
        }

        @media screen and (max-width: 576px) {
            margin-top: 1.4rem;
        }

        @media (orientation:landscape) and (max-width: 767px) {
            margin-top: 1.4rem;
            height: calc(100vh - 15rem);
        }

        .nav-item {
            margin-bottom: 0.5rem;

            .nav-link {
                @include transition(none);
                color: $black;
                @extend .font_17;
                line-height: 2rem;
                @extend .radius_20;
                padding: 1.7rem 1.5rem 1.7rem 2.3rem;
                @extend .position-relative;
                @extend .fullWidth;
                @extend .poppins_600;

                @media screen and (max-width: 991px) {
                    padding: 1.2rem 1.5rem 1.2rem 2.3rem;
                }

                @media screen and (max-width: 680px) {
                    // zoom: .85;
                    @include zoom(.85);
                }

                &.active,
                &:hover {
                    color: $white;
                }

                span {
                    margin-left: 3rem;
                    white-space: nowrap;
                }

                &~ul {
                    @media screen and (min-width: 1281px) {
                        margin-top: 2.5rem;
                    }
                }

                ul:empty {
                    display: none !important;
                }
            }

            &.sub_nav {
                .nav {
                    .nav-item {
                        @extend .fullWidth;

                        .nav-link {
                            @extend .fullWidth;
                            @extend .poppins_500;
                            @extend .font_16;
                            padding: .6rem .5rem .6rem 2.3rem;

                            @media screen and (max-width: 991px) {
                                padding: .3rem .5rem .3rem 2.3rem;
                            }

                            @media screen and (max-width: 680px) {
                                // zoom: 1;
                                @include zoom(1);
                                padding: .3rem .5rem .3rem 1.3rem;
                            }

                            &.active,
                            &:hover {
                                color: $red_highlighted;
                            }
                        }
                    }

                    @media screen and (max-width: 991px) {
                        margin-top: 1rem;
                    }
                }

                &>.nav-link {
                    @include boxShadow(0 3rem 3rem -2rem rgba($red_highlighted, .71));
                }
            }

            .dashboard_nav {
                background: url(../images/dashboard_icon_red.svg) no-repeat 2rem;

                &.active,
                &:hover {
                    background: $red_highlighted url(../images/dashboard_icon_white.svg) no-repeat 2rem;

                    &::after {
                        @extend .nav_link_after;
                    }
                }
            }

            .quizzes_nav {
                background: url(../images/dashboard_icon_red.svg) no-repeat 2rem;

                &.active,
                &:hover {
                    background: $red_highlighted url(../images/dashboard_icon_white.svg) no-repeat 2rem;

                    &::after {
                        @extend .nav_link_after;
                    }
                }
            }

            .members_nav {
                background: url(../images/members_icon_red.svg) no-repeat 2rem;

                &.active,
                &:hover {
                    background: $red_highlighted url(../images/members_icon_white.svg) no-repeat 2rem;

                    &::after {
                        @extend .nav_link_after;
                    }
                }
            }

            .learning_nav {
                background: url(../images/learning_icon_red.svg) no-repeat 2rem;

                &.active,
                &:hover {
                    background: $red_highlighted url(../images/learning_icon_white.svg) no-repeat 2rem;

                    &::after {
                        @extend .nav_link_after;
                    }
                }
            }

            .schedualing_nav {
                background: url(../images/schedualing_icon_red.svg) no-repeat 1.8rem;

                &.active,
                &:hover {
                    background: $red_highlighted url(../images/schedualing_icon_white.svg) no-repeat 1.8rem;

                    &::after {
                        @extend .nav_link_after;
                    }
                }
            }

            .stud_billing_nav {
                background: url(../images/stud_billing_icon_red.svg) no-repeat 1.8rem;

                &.active,
                &:hover {
                    background: $red_highlighted url(../images/stud_billing_icon_white.svg) no-repeat 1.8rem;

                    &::after {
                        @extend .nav_link_after;
                    }
                }
            }

            .reporting_nav {
                background: url(../images/reporting_icon_red.svg) no-repeat 2.1rem;
                background-size: 2.5rem;

                &.active,
                &:hover {
                    background: $red_highlighted url(../images/reporting_icon_white.svg) no-repeat 2.1rem;
                    background-size: 2.5rem;

                    &::after {
                        @extend .nav_link_after;
                    }
                }
            }

            .musicalInteractives_nav {
                background: url(../images/reporting_icon_red.svg) no-repeat 2.1rem;
                background-size: 2.5rem;

                &.active,
                &:hover {
                    background: $red_highlighted url(../images/reporting_icon_white.svg) no-repeat 2.1rem;
                    background-size: 2.5rem;

                    &::after {
                        @extend .nav_link_after;
                    }
                }
            }
        }
    }

    .more_features {
        p {
            @extend .font_17;
            line-height: 2.4rem;
            text-align: center;
            letter-spacing: .1rem;
            color: $black;
            @include transition(none);

            a {
                color: $red;
                @include transition(none);
            }
        }

        .support {
            a {
                @include transition(none);
                background: $purple;
                color: $white;
                @extend .font_16;
                line-height: 2.4rem;
                max-width: 18.7rem;
                padding: .8rem 0;
                border-radius: 1.5rem;

                img {
                    margin: 0 1rem;
                }
            }
        }


    }

    .sidebar_toggle {
        bottom: 2rem;
        right: -1.8rem;
        // transform: translate(50%);
        z-index: 1;
        position: absolute;

        a {
            width: 4rem;
            height: 4rem;
            background: $red_highlighted;
            @include d_flex_center;
            @extend .radius_full;
            @extend .font_26;
            @include rotate(180deg);

            &.toggled {
                @include rotate(0deg);
                @include transition(all .3s ease-in-out);
                ;
            }
        }
    }

    .submenu_hide_icon {
        @extend .position-absolute;
        bottom: 8rem;
        left: 0;
        right: 0;
        height: 5rem;
        @extend .cursor_pointer;
        @extend .text-center;
        @extend .justify-content-center;
        @extend .align-items-center;

        @media (orientation: landscape) {
            bottom: 2rem;
        }

        @media screen and (max-width: 991px) {
            // zoom: .7;
            @include zoom(.7);

            @supports not (zoom: unset) {
                width: 143%;
            }
        }

        span {
            background: $red_highlighted;
            height: 5rem;
            width: 5rem;
            @include d_flex_center;
            @include transition(all .5s ease-in-out);
            @extend .radius_full;

            &:hover {
                @include rotate(360deg);
                background: $black;
            }
        }
    }

    &.toggled {

        .practiceTimer {

            @media screen and (max-width: 991px) {
                display: flex;
                flex-direction: column;
                align-items: center;
            }

            &_section {
                @extend .d-none;

                @media screen and (max-width: 991px) {
                    display: flex !important;
                    max-width: 22rem;
                }
            }

            &_label {
                @extend .font_16;
                @extend .text-center;
                @extend .cursor_pointer;
                line-height: 2rem;

                img {
                    margin: 0 0 .5rem 0 !important;
                }
            }

            @media screen and (min-width: 992px) {
                &_label:hover {
                    width: 8rem;
                    padding-right: 1rem;
                }

                &_label:hover+.practiceTimer_section,
                &_section:hover {
                    display: flex !important;
                    border-radius: 1rem;

                    width: 22rem;
                    z-index: 1;
                    position: absolute;
                    left: 8.8rem;
                    padding: .5rem .6rem;
                    @include boxShadow(0 .15rem 1.75rem 0 rgba(11, 11, 11, .15));
                }
            }
        }

        @media screen and (min-width: 992px) {
            width: 9rem;
            padding: 3rem 1rem 4rem;

            .sidebar_brand {
                img {
                    max-width: 100%;
                    height: 6.5rem;
                    @include img_object_fit_center;
                    object-position: left;
                }
            }

            .sidebar_nav {
                .nav-item {
                    .nav-link {
                        float: left;
                        width: 100%;
                        padding: 2.7rem 0 1.3rem;
                        background-position: center .8rem !important;
                        margin-bottom: 1.5rem;

                        span {
                            font-size: 1.1rem;
                            font-family: 'poppins_300';
                            margin: 0;
                            text-align: center;
                            float: left;
                            width: 100%;
                            padding-top: .8rem;
                        }

                        &.active,
                        &:hover {
                            &:after {
                                content: unset;
                            }
                        }
                    }

                    .nav {
                        position: absolute;
                        left: 7rem;
                        z-index: 9;
                        margin-top: 0;
                        margin-bottom: 0;

                        @include boxShadow(0 .15rem 1.75rem 0 rgba($black, .15));
                        padding: .5rem 0;
                        min-width: 15rem;
                        width: auto;
                        background: $white;
                        border-radius: 1rem;

                        -webkit-animation-name: growIn;
                        animation-name: growIn;
                        -webkit-animation-duration: .2s;
                        animation-duration: .2s;
                        -webkit-animation-timing-function: transform cubic-bezier(.18, 1.25, .4, 1), opacity cubic-bezier(0, 1, .4, 1);
                        animation-timing-function: transform cubic-bezier(.18, 1.25, .4, 1), opacity cubic-bezier(0, 1, .4, 1);

                        .nav-item {
                            .nav-link {
                                padding: 0 !important;
                                margin-bottom: 0;

                                span {
                                    font-size: 1.4rem;
                                    padding: .7rem 1.5rem;
                                    text-align: left;
                                    white-space: nowrap;
                                }

                                &.active,
                                &:hover {
                                    margin-bottom: 0;
                                    background: $gray;
                                    border-radius: 0;
                                }
                            }
                        }
                    }
                }
            }

            .more_features {
                padding: .9rem;
                border-radius: 1rem;
                border-radius: 0;
                margin: 0 -1rem;
                @include transition(none);

                &>img {
                    position: absolute;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    @include img_object_fit_center;
                    opacity: 0.1;
                    z-index: 0;
                }

                p {
                    font-size: 1.4rem;
                    line-height: 1.8rem;
                    letter-spacing: 0;
                    text-transform: capitalize;
                    position: relative;
                    z-index: 1;
                    @include transition(none);

                    a {
                        @include transition(none);
                    }
                }

                .support {
                    position: relative;
                    z-index: 1;
                    @include transition(none);

                    a {
                        font-size: 0;
                        max-width: 4rem;
                        width: auto;
                        border-radius: 100%;

                        img {
                            margin: 0;
                        }
                    }
                }

                .sidebar_toggle {
                    right: -3.4rem;
                    right: .2rem;
                    bottom: auto;
                    position: relative;
                    position: absolute;
                }
            }

            // .nav-item{
            //     .nav-link{
            //         @include transition(none);
            //     }
            // }
        }

        @media screen and (max-width: 680px) {
            .sidebar_brand {
                img {
                    max-width: 18rem;
                }
            }
        }
    }
}



// Timer
.practiceTimer {
    &_label {
        @extend .font_20;
        @extend .poppins_600;
    }

    &_section {
        border: .1rem solid $light_gray;
        border-radius: 5rem;
        background: $white;
        @extend .d-flex;
        @extend .align-items-center;
        @extend .justify-content-space-between;

    }

    &_play {
        background: $validation_green;
        @include d_flex_center;
        width: 4rem;
        height: 4rem;
        @extend .radius_full;
        @extend .float-left;
        @extend .cursor_pointer;

        img {
            height: 1.6rem;
            width: 1.6rem;
        }
    }

    &_counter {
        @extend .font_18;
    }

    &_stop {
        background: $red_highlighted;
        @include d_flex_center;
        width: 3rem;
        height: 3rem;
        @extend .radius_full;
        @extend .float-left;
        @extend .cursor_pointer;

        img {
            height: 1.2rem;
            width: 1.2rem;
        }
    }
}

.sidebar.toggled .sidebar_nav .nav-item .nav {
    display: none !important;
}

.sidebar.toggled .sidebar_nav .nav-item {
    &:hover .nav {
        display: block !important;
    }
}

.sidebar.toggled .sidebar_nav .nav-item .nav .nav-item .nav-link {
    &:hover {
        color: #ff1616;
    }
}