.teachers{

    .heading_area{
        @extend .heading_area;

        @extend .d-flex;
        justify-content: space-between;
    }

    .card_list{
        @extend .card_list;
        
        &.teachers_list{
            h4{
                @extend .mt-3;
            }
        }
    }
}

.teacher_dashboard_banner{
    background-color: $light_pink;
    @extend .radius_10;
    @extend .position-relative;

    &::before {
        content: '';
        background: url('../images/teacher_dashboard_banner_vector.svg') no-repeat;
        width: 100%;
        position: absolute;
        height: 100%;
        top: 0;
        right: 0;
        background-position: 97% center;
        background-size: auto 130%;
        transition: 0.6s;
    }

    h2{
        span{
            @extend .font_16;
            line-height: 3rem;
            @extend .poppins_400;
        }
        .prev_icon {
            background: $white;
            display: inline-flex;
            padding: .5rem;
            height: 4rem;
            width: 4rem;
            border-radius: 100%;
            justify-content: center;
            align-items: center;
            transform: rotate(90deg);
            @extend .cursor_pointer;
        }
        .next_icon {
            background: $white;
            display: inline-flex;
            padding: .5rem;
            height: 4rem;
            width: 4rem;
            border-radius: 100%;
            justify-content: center;
            align-items: center;
            transform: rotate(-90deg);
            @extend .cursor_pointer;
        }
    }

    .student_slider{
        @extend .d-flex;
        @extend .align-items-center;
        @extend .position-relative;
        max-width: 94rem;

        .stud_slide{
            @extend .d-flex;
            @extend .align-items-center;
            @extend .pl-5;
            min-width: 31.3rem;

            .stud_slide_img{
                @extend .position-relative;
                img{
                    @extend .radius_full;
                }
                span{
                    @extend .position-absolute;
                    bottom: .4rem;
                    right: .4rem;
                }
            }
            .stud_name_time{
                @extend .d-flex;
                @extend .flex-direction-column;
                @extend .ml-3;
            }
        }
        .slider_prev_arrows{
            @extend .position-absolute;
            left: 0;
            height: 100%;
            width: 2rem;
            @include d_flex_center;
            @extend .cursor_pointer;

            img{
                max-width: 1.5rem;
                transform: rotate(90deg);
            }
        }
        .slider_next_arrows{
            @extend .position-absolute;
            right: 0;
            height: 100%;
            width: 2rem;
            @include d_flex_center;
            @extend .cursor_pointer;

            img{
                max-width: 1.5rem;
                transform: rotate(-90deg);
            }
        }
    }
}