// Breakpoints
// 768px: 76.8rem;
// 1024px: 102.4rem;
// $iphone5: 32rem;
// $iphone6Plus: 41.4rem;
// $iphoneX: 37.5rem;
// $iphoneX_landscape: 81.2rem;
// 576px: 57.6rem;
// 768px: 76.8rem;
// 992px: 99.2rem;
// 1200px: 120rem;
// $point_xxl: 140rem;
// 1366px: 136.6rem;
// $bp-babybear: "(max-width: 600px)";
// col-sm [ @media (min-width:576px){.col-sm{} ]
// col-md [ @media (min-width:768px){.col-md{} ]
// col-lg [ @media (min-width:992px){.col-lg{} ]
// col-xl [ @media (min-width:1200px){.col-xl{} ]
// col-xxl [ @media (min-width:1400px){.col-xxl{} ]
// Theme color codes
$white: #ffffff;
$light_gray: #dadada;
$dark_gray: #5c5c5c;
$gray: #f3f3f3;
$gray_status_border: #c4c4c4;
$offWhite: #f5f5f5;
$warning: #e15d5d;
$red: #e40909;
$red_highlighted: #ff1616;
$maroon: #e72d2d;
$purple: #5468e7;
$green: #57d9a3;
$pink: #fb49c0;
$light_pink: #ffeaea;
$light_blue: #53b9ea;
$blue: #006dff;
$dark_opacity_orange: #b4781e;
$orange: #f5a623;
$light_orange: #ffc400;
$light_opacity_orange: #ffd18b;
$dark_orange: #ff8f73;
$pinkish: #ffbdad;
$light_pink: #ffd8d8;
$thin_light_pink: #ffeded;
$thin_light_pink_hover: #f7e2e2;
$black: #0b0b0b;
$light_black: #6f6f6f;
$dark_black: #000;
$active_inactive_btn_text_color: #1f4058;
$dark_green: #36b37e;
$validation_green: #29c536;
$border_green: #68c69e;
$dark_red: #d93535;
$red_hover: #c50000;
// Custom Scroll
$scroll_thumb: #9f9f9f;
$scroll_thumb_hover: #555;
// Chat Messges
$msg_receive_box_bg: #ff5353;
$msg_sent_box_bg: #f2f2f2;

$dim_black: #000000;
$light_white: #f7f7f7;
