.course_list {

    @media screen and (max-width: 991px) {
        margin-left: 0;
        margin-right: 0;
    }

    &>.col {
        width: 100%;
        max-width: 25%;
        flex-basis: unset;

        @media screen and (max-width: 1440px) {
            max-width: 33.33%;
        }

        @media screen and (max-width: 1240px) {
            max-width: 50%;
        }

        @media screen and (max-width: 575px) {
            max-width: 100%;
            padding-left: 0;
            padding-right: 0;
        }
    }

    .card {
        padding: 0;
        overflow: hidden;
        background: $white;
        border-radius: 20px !important;
        @include boxShadow(0 .2rem .4rem rgba($dark_black, .25));
        @extend .mb-5;

        .course_img {
            overflow: hidden;
            height: 20rem;

            @media screen and (max-width: 575px) {
                height: 15rem;
            }

            img {
                width: 100%;
                height: 100%;
                object-fit: contain;
                object-position: center;
                // @include img_object_fit_center;
                @include transition(all .3s ease-in-out);

                @media screen and (max-width: 575px) {
                    height: 15rem;
                }
            }
        }

        &:hover {
            .course_img {
                img {
                    -webkit-transform: scale(1.1) rotate(.85deg);
                    /* Safari & Chrome */
                    -moz-transform: scale(1.1) rotate(.85deg);
                    /* Firefox */
                    -ms-transform: scale(1.1) rotate(.85deg);
                    /* Internet Explorer */
                    -o-transform: scale(1.1) rotate(.85deg);
                    /* Opera */
                    transform: scale(1.1) rotate(.85deg);
                }
            }
        }

        .course_name {
            @extend .fullWidth;
            margin-bottom: 1rem;
            overflow: hidden;
            @extend .text-center;

            a {
                @extend .font_22;
                @extend .poppins_600;
                line-height: 3.3rem;
                color: $black;

                &:hover {
                    color: $red_highlighted;
                }
            }
        }

        .course_by_date {
            color: $light_black;
        }

        .course_details {
            @extend .fullWidth;
            padding: 1.5rem 1.75rem;

            p {
                @extend .fullWidth;
            }
        }

        .course_options {
            @extend .fullWidth;

            @media screen and (max-width: 1880px) {
                font-size: 1.4rem;
            }

            a {
                color: $black;

                @media screen and (max-width: 1780px) and (min-width: 1441px) {
                    width: 100%;
                    text-align: center;
                }

                @media screen and (max-width: 1366px) and (min-width: 1241px) {
                    width: 100%;
                    text-align: center;
                }

                @media screen and (max-width: 767px) and (min-width: 576px) {
                    width: 100%;
                    text-align: center;
                }

                @media screen and (max-width: 376px) {
                    width: 100%;
                    text-align: center;
                }

                &:hover {
                    color: $red_highlighted;
                }
            }

        }

        .course_status {
            @extend .fullWidth;
            display: flex;
            @extend .text-center;
            @extend .mt-4;

            .approve_btn {
                @extend .active_green_button;
                background: $dark_green;
                width: 50%;
            }

            .reject_btn {
                @extend .btn_primary;
                width: 50%;
            }

            a {
                border-radius: 5rem !important;
                @include d_flex_center;
                @extend .poppins_600;
                color: $white  !important;
            }
        }
    }
}

.practice_students_list {
    &>.col {
        max-width: 33.33%;
        margin-bottom: 3rem;
        flex: 0 0 33.33%;

        @media screen and (max-width: 1440px) {
            max-width: 50%;
            flex: 0 0 50%;
        }

        @media screen and (max-width: 740px) {
            max-width: 100%;
            padding-left: 0;
            padding-right: 0;
            flex: 0 0 100%;
        }
    }



    .student_prof_pic_part {
        width: 11rem;
    }

    .student_details_part {
        width: calc(100% - 11rem);
        padding-left: 2rem;

    }
}