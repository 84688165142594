.searchbar_script {
    width: 100%;
    max-width: 50rem;

    @media screen and (max-width: 1680px) {
        max-width: 46.5rem;
    }

    .search_icon {
        top: 50%;
        left: 2rem;
        @include translateY(-50%);
    }

    input {
        width: 100%;
        border: .1rem solid $light_gray;
        box-sizing: border-box;
        border-radius: 10rem;
        @extend .font_14;
        line-height: 2.4rem;
        padding-left: 5.2rem;
        padding: .7rem 0 .7rem 5.2rem;
        height: auto;
    }

    &.inner_search {
        max-width: 32rem;

        .search_icon {
            left: auto !important;
            right: 1rem;
        }

        input {
            padding: .7rem 4rem .7rem 2rem;
        }
    }
}


// Card more option dots [...]
.more_options {
    @extend .position-absolute;
    right: 3.6rem;

    @media screen and (max-width: 767px) {
        right: 1.5rem;
    }

    .more_options_dots {
        float: right;
        @extend .position-relative;
        top: -0.7rem;
        padding: 1.1rem .2rem;
        @extend .cursor_pointer;

        .dot {
            width: .4rem;
            height: .4rem;
            @extend .radius_full;
            float: left;
            background: $red_highlighted;

            &:not(:last-child) {
                margin-right: .5rem;
            }
        }
    }

    .dropdown-menu {
        @include dropdown_menu;
    }

    &.courses_list {
        padding-top: 20px;
    }
}


.heading_area {
    margin-bottom: 4rem;

    h2 {
        width: auto;
        margin-right: 2rem;
        white-space: nowrap;
        margin-bottom: 0;
        // span{
        //     color: $red_highlighted;
        // }
    }

    .searchbar {
        @extend .searchbar_script;
        margin-right: 2rem;
    }

    .add_new_btn {
        a {
            @extend .btn_primary;
            @extend .d-flex;

            span {
                @extend .font_30;
                @extend .poppins_300;
            }
        }
    }

    @media screen and (max-width: 1536px) {
        // zoom: 0.9; 
        @include zoom(.9);
    }

    @media screen and (max-width: 1440px) {
        flex-direction: column;
        margin-bottom: 2rem;

        &>.d-flex {
            margin-bottom: 1.5rem;

            h2 {
                margin-right: auto;
            }
        }
    }

    @media screen and (max-width: 767px) {
        &>.d-flex {
            flex-direction: column;

            h2 {
                order: 1;
                margin-right: 0;
            }

            .inner_search {
                order: 3;
                margin-right: 0;
            }

            .add_new_btn {
                order: 2;
            }

            h2,
            .add_new_btn {
                margin-bottom: 1rem;
            }
        }
    }
}


.card_list {

    @media screen and (max-width: 991px) {
        margin-left: 0;
        margin-right: 0;
    }

    &>[class*='col-'] {
        @extend .mb-5;

        @media screen and (max-width: 991px) {
            padding-left: .5rem;
            padding-right: .5rem;
        }

        @media screen and (max-width: 767px) {
            padding-left: 0;
            padding-right: 0;
        }
    }

    .group_listing {
        @extend .float-left;
        width: 33.33%;
        padding: 0 1.5rem;
        margin-bottom: 3rem;

        @media screen and (max-width: 1200px) {
            width: 50%;
        }

        @media screen and (max-width: 991px) {
            padding-left: .5rem;
            padding-right: .5rem;
        }

        @media screen and (max-width: 767px) {
            width: 100%;
            padding-left: 0;
            padding-right: 0;
        }
    }

    .card {
        @extend .card;
        color: #747474;
        height: 100%;

        .more_options {
            @extend .more_options;
        }

        h2 {
            @extend .text-capitalize;
        }

        .school_profile_pic,
        .private_teacher_profile_pic,
        .sub_admin_profile_pic,
        .teachers_profile_pic,
        .parents_profile_pic,
        .student_profile_pic,
        .group_profile_pic {
            margin-bottom: 3rem;

            img {
                @extend .profile_image_border_n_shadow;
                @extend .radius_full;
                @include img_object_fit_center;
                height: 11rem;
                width: 11rem;
            }
        }

        h4 {
            color: #3D3D3D;
            @extend .poppins_500;
            @extend .font_18;
            line-height: 2.7rem;
            margin-bottom: 1rem;
        }

        .active {
            @extend .active_green_button;
        }

        .person_status {
            @extend .person_status;
        }
    }
}



// Card Status
.person_status {
    background: $white;
    @extend .radius_20;
    padding: 1.8rem 0;
    @extend .mt-3;

    h4 {
        @extend .poppins_600;
        @extend .mt-0;
        @extend .mb-1;
    }

    p {
        @extend .fullWidth;
        color: $red_highlighted;
        @extend .font_14;
        line-height: 2.1rem;
        @extend .mb-0;
    }

    [class*='col-'] {
        @extend .px-1;

        @media screen and (min-width: 768px) {
            &:not(:first-child)::before {
                content: '';
                height: 70%;
                width: .1rem;
                background: $gray_status_border;
                position: absolute;
                left: 0;
                top: 50%;
                @include translateY(-50%);
            }

        }
    }
}

// End: Card Status



.sort_by {
    @extend .d-flex;
    @extend .align-items-center;
    @extend .fullWidth;
    @extend .justify-content-flex-end;
    @extend .position-relative;
    max-width: 40%;

    label {
        @extend .font_16;
        line-height: 2.4rem;
        color: $black;
        margin-right: 2rem;
        @extend .mb-0;
    }

    select {
        max-width: 32.7rem;
    }

    .react-datepicker {
        position: absolute;
        right: 0;
        top: 4.8rem;
        z-index: 9;
    }

    @media screen and (max-width: 1440px) {
        max-width: 100%;
    }

    @media screen and (max-width: 767px) {
        flex-direction: row !important;
        justify-content: center;
    }

    @media screen and (max-width: 414px) {
        flex-direction: column !important;
    }
}



// Multi select (Select2)
.rbt {
    @extend .radius_50;

    &.valid {
        border: 0.2rem solid $validation_green;
    }

    &.invalid {
        border: 0.2rem solid $red_highlighted;
    }

    .rbt-input-multi {
        @extend .d-flex;
        @extend .align-items-center;

        .rbt-input-wrapper {
            flex-wrap: nowrap;
            @extend .m-0;
            justify-content: flex-end;
        }

        .rbt-input-main {
            margin-bottom: .1rem;
        }

        .rbt-token {
            background-color: rgba($red_highlighted, 0.2);
            color: $red_highlighted;
            @extend .font_14;
            @extend .text-capitalize;
            @extend .my-1;
            white-space: nowrap;
        }

        .rbt-token-remove-button {
            top: 0;
        }
    }

    .rbt-menu {
        @extend .radius_10;
        @extend .font_14;
        @extend .text-capitalize;
        @extend .px-2;
        @extend .py-1;
        border: .1rem solid rgba($dark_black, .05);
        @include boxShadow(0 .5rem 1rem rgba($dark_black, .15));

        a {
            padding: .5rem 1rem;
            @extend .mb-1;
            @extend .radius_5;


            &:hover {
                background: $red_highlighted;
                color: $white;
            }
        }
    }
}

// End: Multi select (Select2)



// React DatePicker
.react-datepicker-wrapper {
    @extend .fullWidth;
}

.react-datepicker {
    border: none;
    @include boxShadow(0 0 2rem -.5rem rgba($dark_black, .4));
    @extend .font_14;
    @extend .poppins_500;

    &__month-select,
    &__year-select {
        padding: 0.4rem 2rem;
        border: 0;
        line-height: 1.4rem;
        padding-right: 3rem;
    }

    .react-datepicker__day--today {
        color: $dark_black;
        border-radius: 0.3rem;
    }

    .react-datepicker__time-container {
        border-left: .1rem solid $gray;

        .react-datepicker__time-list-item--selected,
        .react-datepicker__time-list-item--keyboard-selected {
            background-color: $red_highlighted;
            color: $white;
        }
    }

    .react-datepicker__day--keyboard-selected {
        background-color: $white;
        color: $dark_black;
    }

    .react-datepicker__day--outside-month {
        color: $scroll_thumb;
    }

    .react-datepicker__today-button {
        background-color: $white;
        border-top: .1rem solid $gray;
    }

    .react-datepicker__triangle {
        display: none;
    }

    .react-datepicker__header {
        background-color: $white;
        border-bottom: .1rem solid $gray;
    }

    .react-datepicker__day-name {
        font-weight: 600;
    }

    .react-datepicker__current-month {
        margin-bottom: 1rem;
        @extend .font_16;
    }

    .react-datepicker__current-month,
    .react-datepicker-time__header,
    .react-datepicker-year-header {
        color: $red_highlighted;
    }

    .react-datepicker__day-name,
    .react-datepicker__day,
    .react-datepicker__time-name {
        width: 3rem;
        line-height: 3rem;
    }

    .react-datepicker__navigation--next {
        border-left-color: $red_highlighted;
    }

    .react-datepicker__navigation--previous {
        border-right-color: $red_highlighted;
    }

    .react-datepicker__day--selected,
    .react-datepicker__day--keyboard,
    .react-datepicker__day--in-selecting-range,
    .react-datepicker__day--in-range,
    .react-datepicker__month-text--selected,
    .react-datepicker__month-text--keyboard-selected,
    .react-datepicker__month-text--in-selecting-range,
    .react-datepicker__month-text--in-range,
    .react-datepicker__quarter-text--selected,
    .react-datepicker__quarter-text--keyboard-selected,
    .react-datepicker__quarter-text--in-selecting-range,
    .react-datepicker__quarter-text--in-range {
        background-color: $red_highlighted;

        &:hover {
            background-color: $red_highlighted;
        }
    }

    .react-datepicker__day--in-selecting-range,
    .react-datepicker__month-text--in-selecting-range,
    .react-datepicker__quarter-text--in-selecting-range {
        background-color: rgba($red_highlighted, 0.15);
    }

    .react-datepicker__month--selecting-range {

        .react-datepicker__day--in-range,
        .react-datepicker__month-text--in-range {
            color: $white;
        }
    }

    .react-datepicker__quarter-text--in-range {
        color: $white;
    }

    .react-datepicker__day--selected.react-datepicker__day--today,
    .react-datepicker__day--keyboard-selected.react-datepicker__day--today {
        background-color: $red_highlighted;
        color: $white;
    }

    // Month Dropdown
    .react-datepicker__month-dropdown,
    .react-datepicker__year-dropdown {
        background-color: $white;
        width: 60%;
        left: 20%;
        top: 3.7rem;
        border-radius: 0.9rem;
        border: 0;
        @include boxShadow(0 0 2rem 0 rgba($dark_black, .2));
    }

    .react-datepicker__month-option,
    .react-datepicker__year-option {
        line-height: 2.2rem;
        @include transition(all 0.3s ease-in-out);

        &:hover {
            color: $red_highlighted;
            background: $white;
        }
    }

    .react-datepicker__month-option--selected_month,
    .react-datepicker__year-option--selected_year,
    .react-datepicker__month-option--keyboard-selected_month,
    .react-datepicker__year-option--keyboard-selected_year {
        color: $red_highlighted;
        background: rgba($red_highlighted, 0.2);
    }
}

.react-datepicker .react-datepicker__day--selected.react-datepicker__day--outside-month,
.react-datepicker .react-datepicker__day--keyboard-selected.react-datepicker__day--outside-month {
    color: $white;
}

// End: React DatePicker







// Inline Tabs
.inline_tabs {
    .nav {
        @extend .border-0;
    }

    .nav-items {
        padding: 0 1.5rem;

        a {
            color: $dark_black;
            border: 0;
            @extend .px-0;

            &.active,
            &:hover {
                color: $red_highlighted;
                border-bottom: .2rem solid $red_highlighted;
            }
        }

        &:first-child {
            padding-left: 0;
        }

        &:last-child {
            padding-right: 0;
        }
    }

    .tab-content {
        padding: 3rem 0;
    }

    // User Profile Page tabs
    &.userProfile_tabs {
        .userProfile_tabs_listing {
            background: rgba($light_pink, .75);
            border-radius: 2rem;
            @extend .d-flex;
            @extend .justify-content-space-between;

            .nav_tabs_humberger_toggle {
                @extend .transparent;

                @media screen and (max-width: 420px) {
                    margin-top: 0 !important;
                    margin-right: 0 !important;
                }
            }

            .nav {
                padding: 1rem 2.5rem;
                flex-direction: column;
                @extend .mb-0;
                @extend .position-relative;

                @media screen and (max-width: 420px) {
                    padding: 0 1rem;
                }

                .nav-items {
                    padding: 0;

                    a {
                        font-size: 1.6rem;
                        padding: 1rem 0;
                        display: none;
                        text-align: left;

                        &.active {
                            display: block;
                        }

                        &.active,
                        &:hover {
                            @extend .border-0;
                            background: transparent;
                        }
                    }
                }

                &.show {
                    @media screen and (max-width: 420px) {
                        padding-top: 1rem;
                        padding-bottom: 1rem;
                    }

                    .nav-items {
                        a {
                            display: block;

                            @media screen and (max-width: 420px) {
                                padding: .5rem 0;
                            }
                        }
                    }
                }
            }
        }
    }
}

// End: Inline Tabs






// Schedule page : Calendar labels design
.cal_label {
    float: left;
    min-width: 20rem;
    padding: .3rem 1rem;
    border-radius: 0 1.5rem 1.5rem 0;
    @extend .cursor_pointer;

    label {
        @extend .font_18;
        @extend .poppins_600;
    }

    span {
        @extend .font_15;
        @extend .poppins_600;
    }
}

.green_label {
    background: rgba($dark_green, .2);
    border-left: .4rem solid $dark_green;
}

.red_label {
    background: rgba($red_highlighted, .2);
    border-left: .4rem solid $red_highlighted;
}

.purple_label {
    background: rgba($purple, .2);
    border-left: .4rem solid $purple;
}

.blue_label {
    background: rgba($blue, .2);
    border-left: .4rem solid $blue;
}

// END: Schedule page : Calendar labels design


// Schedule page > View by > DropDown with checkbox and sublist
.view_more_dropdown {
    .dropdown {
        input[readOnly] {
            background-color: $white;
        }

        label {
            @extend .font_16;
        }
    }

    .custom-control-label {
        width: calc(100% - 2.5rem);
    }

    .sub_dropdown_arrow {
        @include transition(all 0.3s ease-in-out);

        &.show {
            @include rotate(0deg);
        }

        &.hide {
            @include rotate(-90deg);
        }
    }

    .sub_dropdown {
        max-height: 20rem;
        overflow-y: auto;
        padding-right: 0.5rem;
        @include transition(all 0.3s ease-in-out);

        li {
            @extend .p-2;
            @extend .font_14;
            padding-left: 1.5rem !important;

            &:hover {
                background: $gray;
            }
        }

        &.show {
            height: auto;
            @include transition(all 0.3s ease-in-out);
        }

        &.hide {
            height: 0;
            @include transition(all 0.3s ease-in-out);
        }
    }
}

// End: Schedule page > View by > DropDown with checkbox and sublist



// Navigation Humberger
#humberger,
#chat_humberger {
    position: relative;
    // z-index: 3;
    width: 3.2rem;
    height: 4rem;
    @extend .cursor_pointer;
    float: left;
    margin-top: .8rem;

    span {
        display: block;
        width: 100%;
        height: 0.3rem;
        margin-top: 2rem;
        background: $red_highlighted;
        -webkit-transition: 350ms ease all;
        -moz-transition: 350ms ease all;
        transition: 350ms ease all;

        &:after,
        &:before {
            content: " ";
            float: left;
            // width: 100%;
            height: .3rem;
            background: $red_highlighted;
            -webkit-transition: 350ms ease all;
            -moz-transition: 350ms ease all;
            transition: 350ms ease all;
        }

        &:before {
            width: 90%;
            margin: -1.2rem 0 0 0;
        }

        &:after {
            width: 75%;
            margin: 1.2rem 0 0 0;
        }
    }

    // &.active{
    //     span{
    //         background: transparent;

    //         &:before {
    //             @include rotate(135deg);
    //             margin: 0.2rem 0 0 0;
    //         }
    //         &:after {
    //             @include rotate(-135deg);
    //             margin: -0.2rem 0 0 0;
    //         }
    //     }
    // }
}




// React DatePicker
.re-schedule_modal {
    .react-datepicker {
        // zoom: 1.25;
        @include zoom(1.25);
        background: $gray;

        @media screen and (max-width: 767px) {
            // zoom: 1;
            @include zoom(1);
        }

        @media screen and (max-width: 576px) {
            // zoom: 1.25;
            @include zoom(1.25);
        }

        @media screen and (max-width: 420px) {
            // zoom: 1;
            @include zoom(1);
        }

        &__header {
            background: $gray;
        }

        &__day-name {
            @extend .text-uppercase;
            font-size: 1.1rem !important;
        }

        &__current-month,
        &__day {
            font-size: 1.2rem !important;
        }
    }
}

.time_picker {
    input {
        width: 13rem;
        @extend .text-center;
        @extend .text-uppercase;
        background: $white url('../images/select_down_arrow.svg') no-repeat;
        background-position: calc(100% - 1.5rem) center;
        @extend .pl-2;
    }
}

.react-datepicker {
    .form-control {
        background: $white;
    }

    // &-ignore-onclickoutside{
    // @include rotate(90deg)
    // }
    &-time__header {
        @extend .font_16;
    }

    &__time-list-item {
        &:before {
            content: unset !important;
        }
    }

    &-popper[data-placement^="top"] {
        @extend .mb-0;
    }

    &__time-container {
        width: 15rem;
    }

    &__time-box {
        width: 100% !important;
    }

    &__time-list {
        &-item {
            &:hover {
                color: $red_highlighted;
                background-color: transparent !important;
            }
        }

        &-item--selected,
        &-item--selected:hover {
            color: $white;
            background: $red_highlighted !important;
        }
    }
}