.upload_img_structure{
    // @extend .mb-5;

    .form-group{
        @extend .fullWidth;
    }

    select{
        min-width: 17rem;
    }

    .add_new_file{
        @include add_new_file_thumbnail;
    }
    .uploaded_img{
        @include uploaded_img_thumbnail;

        .ReactCrop{
            @extend .radius_10;

            &__image{
                height: 5.8rem;
                width: 5.8rem;
            }
        }
    }

    textarea{
        @extend .radius_20;
    }
    .dropdown-menu{
        @include dropdown_menu;
        left: 0;
        right: auto;
        top: 6rem;
    }
}

.success_tick{
    height:17rem;
    width:17rem;
    border: .7rem solid $validation_green;
    @extend .radius_full;
    @include d_flex_center;
    
    i{
        @extend .font_70;
        color: $validation_green;
    }
}

.form_stepper{
    #progressbar{
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 3rem;
        @media screen and (max-width: 767px){
            flex-direction: column;
            align-items: end;
        }

        li{
            display: flex;
            align-items: center;
            position: relative;
            @media screen and (max-width: 767px){
                flex-wrap: wrap;
                margin-bottom: 1rem;
            }

            &:before{
                content: '';
                width: 4rem;
                height: 4rem;
                float:left;
                left: 0;

                @extend .radius_full;
                @extend .font_26;
                @extend .poppins_bold;
                @extend .position-relative;

                color: $black;
                display: flex;
                justify-content: center;
                align-items: center;
                margin-right: 1rem;
                background-color: $gray;
            }
            &#create_course{
                &:before{
                    content: '1';
                }
            }
            &#add_lesson{
                &:before{
                    content: '2';
                }
            }
            &#confirmation{
                &:before{
                    content: '3';
                }
            }
            hr{
                width: 15rem;
                margin-left: 1rem;
                border-top: 0.3rem solid $gray;

                &.green{
                    border-top: 0.3rem solid $validation_green;
                }

                @media screen and (max-width: 980px){
                    width: 10rem;
                }
                @media screen and (max-width: 880px){
                    width: 5rem;
                }
                @media screen and (max-width: 767px){
                    display: none;
                }
            }
            &.active{
                &:before{
                    background-color: $validation_green;
                    color: $white;
                }
            }

        }

        &.asign_practice_stepper{
            @media screen and (max-width: 900px){
                flex-direction: column;
            }
            li{
                display: block;
                @media screen and (max-width: 1366px){
                    font-size: 1.4rem;
                }
                @media screen and (max-width: 900px){
                    width: 100%;
                    margin-bottom: 1rem;
                }
                strong{
                    float: left;
                    margin: .7rem 0;
                }
                &:before{
                    content: '';
                    width: 4rem;
                    height: 4rem;
                    float:left;
                    left: 0;
    
                    @extend .radius_full;
                    @extend .font_26;
                    @extend .poppins_bold;
                    @extend .position-relative;
    
                    color: $black;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin-right: 1rem;
                    background-color: #e8e8e8;
                }
                hr{
                    float: right;
                    margin: 1.8rem 10px 1.8rem 0;
                    width: 15rem;
                    margin-left: 1rem;
                    border-top: 0.3rem solid #e8e8e8;
    
                    &.green{
                        border-top: 0.3rem solid $validation_green;
                    }
    
                    @media screen and (max-width: 1680px){
                        width: 10rem;
                    }
                    @media screen and (max-width: 1366px){
                        width: 7.5rem;
                    }
                    @media screen and (max-width: 1080px){
                        width: 3.2rem;
                    }
                    @media screen and (max-width: 900px){
                        display: none;
                    }
                }
                &.active{
                    &:before{
                        background-color: $validation_green;
                        color: $white;
                    }
                }
            }
        }
    }
    fieldset{
        @extend .fullWidth;

        &:not(:first-of-type) {
            display: none
        }

        .practice_courses{
            & > [class*="col-"]{
                @media screen and (max-width: 1180px) {
                    flex: 0 0 100%;
                    max-width: 100%;
                }
            }
            .card{
                flex-direction: row;
                align-items: center;
                padding: 1.5rem 2rem;
                filter: drop-shadow(0 0.5rem 1rem rgba(0, 0, 0, 0.1));
                @extend .radius_10;
                @extend .cursor_pointer;

                img{
                    width: 4rem;
                    height: 4rem;
                    @extend .radius_5;
                    margin-right: 2rem;
                }
            }
            .dropdown-menu{
                min-width: 17rem;
                border: .1rem solid rgba( $dark_black, .05);
                border-radius: .75rem;
                @media screen and (max-width: 1440px){
                    min-width: 50rem;
                }
                @media screen and (max-width: 768px){
                    min-width: 1rem;
                }
                .custom-checkbox{
                    .custom-control-label{
                        &::before, 
                        &::after{
                            top: calc(50% - 0.9rem);
                        }
                    }
                }

                .checkbox_listing{
                    max-height: 25rem;
                    overflow-y: auto;

                    .custom-control-label{
                        img{
                            height: 5rem;
                            width: 5rem;
                            @include img_object_fit_center;
                        }
                    }
                }
            }
            .courseSearchBar{
                @extend .position-relative;
            
                input{
                    padding-right: 4rem;
                }
            
                span{
                    @extend .position-absolute;
                    right: 1.6rem;
                    top: .65rem;
                }
            }
            
            .checkbox_listing{
                .form-check {
                    font-size: 1.4rem;
                    margin: 1rem .5rem;
                }   
            }
        }
    }
}

.select_course_modal_search{
    max-width: 100% !important;
}