@media screen and (max-width:1920px){}
@media screen and (max-width:1536px){}
@media screen and (max-width:1440px){}
@media screen and (max-width:1366px){}
@media screen and (max-width:1280px){}
@media screen and (max-width:1200px){}
@media screen and (max-width:1080px){
    select{
        padding-right: 2rem !important;
        @include text_ellipsis;
    }
}
@media screen and (max-width:1024px){}
@media screen and (max-width:992px){}
@media screen and (max-width:768px){}
@media screen and (max-width:680px){}
@media screen and (max-width:576px){}
@media screen and (max-width:420px){}
@media screen and (max-width:375px){}
@media screen and (max-width:320px){}