.library{
    .table_filters{
        .row{
            justify-content: space-between;
        }
        select{
            width: 17.5rem;
            @extend .float-left;
            @extend .font_16;
            padding: 0 1.5rem;
            min-height: 4rem;
        }

        .searchbar{
            button{
                top: .8rem;
                left: 1rem;
            }
            input{
                @extend .font_16;
                width: 20rem;
                padding-left: 4rem;
                min-height: 4rem;
            }
        }

        .filter_search_btns{
            width: 26.5rem;
            @extend .d-flex;
            justify-content: space-between;
            align-items: flex-end;

            @media  screen and (max-width: 768px) {
                justify-content: center;
            }

            .btn_primary, .btn_black{
                width: 12rem;
            }
        }
        
        .library_filter{
            @media screen and (max-width: 991px){
                flex-direction: column;
                align-items: center;
            }
        }

        .filter_search_select{
            width: calc(100% - 28.5rem);
            .form-control{
                @extend .font_16;
                min-height: 4rem;
                width: 100%;
                max-width: 100%;
            }
            @media screen and (max-width: 768px){
                width: calc(100% - 15rem);
            }
            @media screen and (max-width: 576px){
                width: calc(100% - 0rem);
            }
        }
    }
}

.file_upload{
    .card{
        padding: 4rem;
        border-radius: 2rem 2rem 0 0;
        p{
            color: $dark_gray;
        }
        .file_upload_input{
            position: absolute;
            margin: 4rem;
            left: 0;
            top: 0;
            bottom: 0;
            right: 0;
            border: .2rem dashed $scroll_thumb;

            @media screen and (max-width: 576px) {
                margin: 2rem;
            }

            input[type="file"]{
                display: flex;
                width: 100%;
                height: 100%;
                opacity: 0;
                @extend .cursor_pointer;
            }
        }
    }
}

.file_upload_progressbar{
    border-radius: 0 0 2rem 2rem !important;
    .image_icon{
        @extend .fullWidth;
        max-width: 6rem;

        img{
            height: 6rem;
        }
    }
    .file_progress{
        @extend .fullWidth;
        max-width: calc(100% - 6rem);
        
        .progress{
            height: 1.5rem;
        }

        .progress-bar{
            height: 1.5rem;
            @extend .font_12;
        }
    }

}