.store {
    .inner_search {
        .search_icon {
            top: 50%;
            right: .5rem;
            @include translateY(-50%);
            @include transition(all .3s ease-in-out);
            ;
        }

        input {
            padding-right: 3rem;
        }
    }

    .order_filter_tabs {
        @media screen and (max-width: 1680px) {
            flex-direction: column;

            .order_tabs {
                width: 100%;
                margin-bottom: 2rem;
            }

            .order_filter {
                width: 100%;

                .inner_search {
                    margin-left: auto;
                }
            }
        }

        @media screen and (max-width: 1024px) {
            .order_tabs {
                text-align: center;

                .nav {
                    display: inline-flex;
                    width: 100%;
                }
            }

            .order_filter {
                flex-direction: column;

                &>div {
                    width: 100%;
                    margin-bottom: 1.5rem;

                    &:last-child {
                        margin-right: auto;
                        margin-bottom: 0;
                    }
                }
            }
        }
    }

    .order_details_main {
        display: flex;
        @extend .fullWidth;
        margin-top: 4rem;

        @media screen and (max-width: 1440px) {
            flex-direction: column;
        }

        .order_configuration {
            border: .1rem solid $light_gray;
            @extend .radius_10;
            @extend .fullWidth;
            overflow: hidden;
            margin-bottom: 3rem;

            @media screen and (max-width: 1440px) {
                margin-right: 3rem;

                &:last-child {
                    margin-right: 0;
                }
            }

            h3 {
                background: $gray;
                padding: 1.2rem 1.5rem;
                @extend .fullWidth;
            }

            .details_list {
                padding: 1.2rem 1.5rem;
                @extend .fullWidth;

                p {
                    @media screen and (max-width: 1200px) {
                        flex-wrap: wrap;
                    }

                    span {
                        @media screen and (max-width: 1200px) {
                            width: 100%;
                            float: left;

                            br {
                                display: none;
                            }
                        }
                    }
                }
            }

            .remove_product_from_cart {
                background: #ddd;
                padding: 5px;
                opacity: 0.5;
                height: 20px;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 20px;
                border-radius: 5px;
                @extend .cursor_pointer;
                @include transition(all .2s ease-in-out);

                &:hover {
                    opacity: 1;
                }
            }
        }

        .order_details {
            max-width: 37.4rem;
            @extend .fullWidth;
            margin-left: 1.5rem;

            @media screen and (max-width: 1440px) {
                margin-left: 0;
                max-width: 100%;
                display: flex;
                font-size: 1.4rem;
            }

            @media screen and (max-width: 768px) {
                flex-direction: column;
                font-size: 1.6rem;
            }

            @media print {
                // display: none !important;
            }
        }
    }
}

// Super Admin > Store > Product Listing
.product {
    .heading_area {
        flex-direction: row;
        justify-content: space-between;

        @media screen and (max-width: 576px) {
            flex-direction: column;
            justify-content: center;
        }

        h2 {
            margin-bottom: 1.5rem;
        }

        .right_options {
            @extend .d-flex;
            justify-content: space-between;
            width: 100%;
            max-width: 41rem;

            @media screen and (max-width: 767px) {
                flex-direction: row;
            }

            .form-group .form-control {
                text-transform: capitalize;
                padding: 0.375rem 4.75rem 0.375rem 1.75rem;
                option {
                    text-transform: capitalize;
                }
            }

            .cart_icon {
                width: 2.5rem;

                span {
                    top: -1.3rem;
                    right: -1rem;
                    border: .2rem solid $white;
                    min-width: 2.2rem;
                    height: 2.4rem;
                    @extend .poppins_300;
                    @include d_flex_center;
                }
            }

            .sort_by {
                @media screen and (max-width: 767px) {
                    justify-content: flex-end;
                }
            }
        }
    }

    &_card {
        width: 100%;
        max-width: 25%;
        @extend .px-4;
        @extend .mb-5;

        @media screen and (max-width: 1680px) {
            max-width: 25%;
        }

        @media screen and (max-width: 1366px) {
            max-width: 50%;
        }

        @media screen and (max-width: 768px) {
            max-width: 50%;
        }

        @media screen and (max-width: 576px) {
            max-width: 100%;
        }
    }

    &_box {
        padding: 1rem;
        background-color: $gray;
        @extend .fullWidth;
    }

    &_image {
        margin-bottom: 1rem;
        min-height: 26rem;
        @extend .fullWidth;
        background-color: white;

        img {
            height: 26rem;
            width: 100%;
            object-fit: contain;
            object-position: center;
        }
    }

    &_name {
        @extend .fullWidth;
        @extend .font_18;
        line-height: 2.8rem;
        margin-bottom: 1rem;
        min-height: 5.6rem;
        @include transition(all .3s ease-in-out);

        @media screen and (max-width: 576px) {
            min-height: 1rem;
        }

        &:hover {
            color: $red_highlighted;
        }
    }

    &_price {
        @extend .fullWidth;
        @extend .poppins_bold;
        @extend .font_25;
        margin-bottom: 1.5rem;
        line-height: 28px;
        color: $red_highlighted;
    }

    &_quantity_add_to_cart {
        @extend .fullWidth;
        justify-content: space-between;
    }

    &_quantity {
        width: 100%;
        max-width: 9rem;
        margin-bottom: 1rem;

        input {
            font-size: 18px;
            padding-right: 3rem;
        }

        &_arrow {
            right: 1rem;
            top: 0;
            height: 100%;
            @extend .d-flex;
            flex-direction: column;

            span {
                height: 50%;
                width: 2rem;
                @include d_flex_center;
            }

            &_up {
                @include rotate(180deg);
            }
        }
    }

    &_add_to_cart_btn {
        width: calc(100% - 10rem);
        margin-bottom: 1rem;
    }
}

// group email css