@keyframes ripple {
    0% {
      box-shadow: 0 0 0 .5rem rgba($red_highlighted, 0.15),
                  0 0 0 1rem rgba($red_highlighted, 0.15),
                  0 0 0 2.5rem rgba($red_highlighted, 0.15);
    }
    100% {
      box-shadow: 0 0 0 1rem rgba($red_highlighted, 0.15),
                  0 0 0 2.5rem rgba($red_highlighted, 0.15),
                  0 0 0 4rem rgba($red_highlighted, 0);
    }
}


@-webkit-keyframes growIn{
  0%{
    transform:scale(.9);
    opacity:0
  }
  100%{
    transform:scale(1);
    opacity:1
  }
}
@keyframes growIn{
  0%{
    transform:scale(.9);
    opacity:0
  }
  100%{
    transform:scale(1);
    opacity:1
  }
}


// Height Toggle
// @-webkit-keyframes heightToggle{
//   0%{
//     height: 0;
//     overflow: unset;
//   }
//   100%{
//     height: auto;
//     overflow: unset;
//   }
// }
// @keyframes heightToggle{
//   0%{
//     height: 0;
//     overflow: unset;
//   }
//   100%{
//     height: auto;
//     overflow: unset;
//   }
// }



// Page loader line animation
@-webkit-keyframes line-scale-party{
	0%, 100%{
    -webkit-transform:scale(1);
    transform:scale(1)
  }
	50%{
    -webkit-transform:scale(.5);
    transform:scale(.5)
  }
}
@keyframes line-scale-party{
	0%, 100%{
    -webkit-transform:scale(1);
    transform:scale(1)
  }
	50%{
    -webkit-transform:scale(.5);
    transform:scale(.5)
  }
}


// Page loader line animation
@-webkit-keyframes audio_playing_animation{
	0%, 100%{
    -webkit-transform:scale(1);
    transform:scale(1)
  }
	50%{
    -webkit-transform:scale(.5);
    transform:scale(.5)
  }
}
@keyframes audio_playing_animation{
	0%, 100%{
    -webkit-transform:scale(1);
    transform:scale(1)
  }
	50%{
    -webkit-transform:scale(.5);
    transform:scale(.5)
  }
}


// Rounded spinner loader
@-webkit-keyframes spin {
  0% { 
    -webkit-transform: rotate(0deg); 
  }
  100% { 
    -webkit-transform: rotate(360deg); 
  }
}

@keyframes spin {
  0% { 
    transform: rotate(0deg); 
  }
  100% { 
    transform: rotate(360deg); 
  }
}