.recent_activity{
    width: 45rem;
    padding: 0 1.5rem;
    top: 2rem;
    @include transition(all .5s ease-in-out);

    @media screen and (max-width: 1680px){
        width: 40rem;
        padding-right: 0;
    }

    @media screen and (max-width: 1536px){
        position: fixed;
        right: 0;
        top: 0;
        height: 100vh;
        width: 55rem;
        z-index: 99;
    }

    @media screen and (max-width: 676px){
    }

    .card{
        position: inherit;
    }
    
    .recent_activity_show_hide{
        @include ripple;
        @extend .cursor_pointer;

        @media screen and (max-width: 768px){
            // zoom: .75;
            @include zoom(.75);

            i{
                font-size: 1.8rem;
                margin-top: 0.25rem;
            }
        }
    }


    .close_recent_activity{
        position: absolute;
        width: 6rem;
        left: -6rem;
        top: 0;
        @extend .font_22;
        background-color: $red_highlighted;
        @extend .cursor_pointer;
        padding: 2rem 0;
        text-align: center;
        
        img{
            @include transition(all .5s ease-in-out);
        }
        &:hover{
            img{
                transform: rotate(360deg);
            }
        }
    }

    &.recent_activities_n_practice{
        @media screen and (max-width: 1536px) and (min-width: 1080px){
            &.show,
            &.hide{
                width: 85rem;
            }

            .activities_n_practice{
                flex-direction: row;
                justify-content: space-between;
                height: 95vh;

                & > .card{
                    width: 50%;
                    max-height: 100%;
                }
                .activities_card, 
                .practice_card{
                    border-radius: 0;
                }

                @media screen and (max-width: 1536px){
                    height: 100vh;
                }

                @media screen and (max-width: 767px){
                    height: auto;
                }
            }

            &.hide{
                right: -85rem;
            }
        }
    }

    &.show{
        .bg-overlay{
            display: block;
            @include transition(all .3s ease-in-out);;
        }
        .recent_activity_listing {
            height: 100%;
            overflow-y: auto;
            padding-right: 1rem;
        }
        .close_recent_activity{
            @extend .d-block;
        }
        .recent_activity_show_hide{
            @extend .d-none;
        }
    }

    &.hide{
        .bg-overlay{
            display: none;
            @include transition(all .3s ease-in-out);;
        }
        @media screen and (max-width: 1536px){
            right: -55rem;
            position: fixed;

            .recent_activity_show_hide{
                display: flex;
            }
        }

        @media screen and (max-width: 767px){
            right: 0;
            position: relative;
            width: 100%;
            padding: 0;
            height: auto;
        }
        .close_recent_activity{
            @extend .d-none;
        }
        
        
    }

    .card{
        background: $white;
        @include boxShadow(0 .4rem 5rem rgba(163, 163, 163, 0.25));
        @extend .radius_20;
        padding: 2.5rem;
        @media screen and (max-width: 1680px){
            padding: 2.5rem 1.5rem;
        }
        @media screen and (max-width: 1536px){
            height: 100%;
            padding: 2.5rem 2.5rem;
            border-radius: 0;
        }
        @media screen and (max-width: 992px){
            position: relative;
            z-index: 99;
        }

        .view_all_activities {
            @extend .font_14;
            line-height: 2.8rem;
            color: $red;
            background: $white;
            border: .1rem solid $red;
            box-sizing: border-box;
            border-radius: 3rem;
            min-width: 8.1rem;
            @include d_flex_center;
        }

        .activity {
            border-bottom: .1rem solid $light_gray;
            padding: 2rem 0 1.9rem;
            &:last-child{
                border-bottom: 0;
            }
        }

        .activity_profile_img{
            width: 6.8rem;
            span {
                @extend .position-absolute;
                bottom: .5rem;
                right: 0;
                @media screen and (max-width: 376px) {bottom: 2.5rem;}
                img{
                    @include img_object_fit_center;
                }
            }
        }
        .activity_details{
            width: calc(100% - 6.8rem);
            padding-left: 1.5rem;
            h4{
                margin-bottom: 0;
                line-height: 2rem;
                @include text_ellipsis;
            }
            p {
                color: $light_black;
                margin-bottom: 0;
                line-height: 3rem;
                @extend .font_15;
                @extend .fullWidth;
                @include text_ellipsis;
            }
        }
        .activity_date_time{
            @extend .font_14;
            line-height: 2.1rem;
            color: #8E8E8E;
            @media screen and (max-width: 376px) {
                flex-direction: column;
            }

            & > span {
                @media screen and (max-width: 376px) {
                    letter-spacing: -0.01rem;
                }
                & > span + span:before{
                    content: '';
                    @extend .position-relative;
                    width: .1rem;
                    height: 1rem;
                    background: #8E8E8E;
                    left: 0;
                    display: inline-block;
                    margin-left: .9rem;
                    margin-right: .9rem;

                    @media screen and (max-width: 376px) {
                        margin-left: .4rem;
                        margin-right: .4rem;
                    }
                }
            }
        }
    }

    .activities_n_practice{
        flex-direction: column;
        height: calc(100vh - 16rem);

        @media screen and (max-width: 1536px) {
            // height: calc(100vh - 6rem);
            height: 100vh;
        }
        @media screen and (max-width: 767px){
            height: auto;
        }

        .activities_card{
            height: 100%;
            max-height: 53rem;
            margin-bottom: 2rem;

            @media screen and (max-width: 1536px) {
                // height: calc(55% - 2rem);
                border-radius: 0;
                margin-bottom: 0;
                max-height: 50vh;
            }

            .recent_activity_listing{
                height: 100%;
                overflow-y: auto;
                padding-right: 1rem;
            }
        }
        .practice_card{
            height: 100%;
            max-height: 40rem;

            @media screen and (max-width: 1536px) {
                max-height: 50vh;
            }

            .recent_activity_listing{
                height: 100%;
                overflow-y: auto;
                padding-right: 1rem;

                .practice_profile_img{
                    width: 6.8rem;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    label{
                        position: absolute;
                        margin-bottom: 0;
                        @extend .font_20;
                        line-height: 3rem;
                        @extend .poppins_600;

                        span{
                            color: $red_highlighted;
                        }
                    }
                }
            }
        }
    }
}

.student_dashboard{
    @media screen and (max-width: 767px) {
        margin-bottom: 5rem;
    }
    .student_next_lesson_listing{
        height: 100vh;
        max-height: 23vh;
        overflow-y: auto;
        padding-right: 1rem;

        @media screen and (max-width: 767px) {
            max-height: 50vh;
            height: auto;
            margin-bottom: 5rem;;
        }
    }
    .student_recent_activity_listing{
        max-height: 50vh;
        height: 100vh;
        overflow-y: auto;
        padding-right: 1rem;

        @media screen and (max-width: 1536px) {
            max-height: 58vh;
        }

        @media screen and (max-width: 767px) {
            max-height: 50vh;
            height: auto;
        }
    }
}

.admin_dashboard, .supre_admin_dashboard, .member_viewProfile{
    @media screen and (max-width: 767px){
        .recent_activity {
            right: 0;
            position: relative;
            width: 100%;
            padding-left: 0;
            border-radius: 1rem;
            height: auto;
            z-index: 1;

            .card{
                border-radius: 1.5rem;
                padding: 2.5rem 1.5rem;
                margin-bottom: 3rem;
            }
        }
    }
}

.recent_activity_show_hide {
    @media screen and (max-width: 767px){
        display: none !important;
    }
}

.recent_activity_listing{
    .view_all_block{
        width: 100%;
        float: left;
        background: #FFFFFF;
        box-shadow: 0px 4px 50px rgba(163, 163, 163, 0.25);
        border-radius: 20px;
        margin-bottom: 1rem;
        padding: 2.5rem;
        display: flex;
    }
}