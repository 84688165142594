.chat_modal {
    .modal-content{ border-radius:0 !important;}
    .modal-body{
        @media screen and (max-width: 1440px) {
            @include zoom(.9);
        }
        .chat_sidebar{
            background: $gray;
            max-width: 38.1rem;
            position: relative;

            @media screen and (max-width: 992px) {
                max-width: 30rem;
            }
            @media screen and (max-width: 768px) {
                position: absolute;
                left: 0;
                max-width: 40rem;
                display: none;
                z-index: 9;
                @include transition(all 0.3s ease-in-out);

                &.show{
                    transform: translateX(0%);
                    background: rgba( $dark_gray, .6);

                    @include transition(all 0.4s ease-in-out);
                    @include boxShadow(0 1rem 2rem rgba( $dark_gray, .2));

                    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
                        background: rgba($msg_sent_box_bg, 0.9);
                        -webkit-backdrop-filter: blur(.8rem);
                        backdrop-filter: blur(.8rem);
                    }
                        
                    /* slightly transparent fallback for Firefox (not supporting backdrop-filter) */
                    @supports not ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
                        background: rgba($msg_sent_box_bg, 0.95);
                    }
                }
            }
            @media screen and (max-width: 576px) {
                // transform: translateX(-100rem);
                max-width: 100%;
            }

            .emp_sidebar_close{
                position: absolute;
                top: 1.5rem;
                right: 1.5rem;
                @extend .cursor_pointer;
                display: none;

                @media screen and (max-width: 768px) {
                    display: unset;
                }
            }

            .chat_profile{
                @extend .fullWidth;
                display: flex;
                padding: 3rem;
                @media screen and (max-width: 768px) {
                    padding: 2rem;
                }

                .chat_profile_pic{
                    img{
                        border: .3rem solid $border_green;
                        @extend .radius_full;
                    }
                }
                .chat_profile_name{
                    margin-left: 1.5rem;
                    h2{
                        margin-bottom: 0;
                    }
                    h4{
                        color: $light_black;
                        @extend .poppins_500;
                        margin-bottom: 0;
                    }
                }
            }

            .chat_member_search{
                @extend .fullWidth;
                padding: 0 3rem;
                @extend .position-relative;

                img{
                    top: 1.3rem;
                    margin-left: 1.9rem;
                }

                input{
                    padding: .7rem .7rem .7rem 5rem;
                    @extend .font_16;
                    line-height: 2.4rem;
                    height: auto;
                }
            }

            .chat_member_list{
                // padding: 0 2.5rem;
                margin: 2rem 0 0 0;
                height: calc(100vh - 30rem);
                overflow-y: auto;

                @media screen and (max-width: 768px) {
                    height: calc(100vh - 28rem);
                }

                li{
                    margin: 0rem 0;
                    display: block;
                    float: left;
                    width: 100%;
                    
                }
                
                .chat_member_list_profile {
                    display: flex;
                    padding: 1.2rem 2.5rem;
                    @extend .cursor_pointer;

                    &.active{
                        background: $white;
                    }

                    .member_profile_pic{ float: left; width: 6rem;}

                    .member_profile_name_message{
                        float: left;
                        width: calc(100% - 7.5rem);
                        margin-left: 1.5rem;

                        h3{
                            margin-bottom: 0;

                            .emp_name{
                                @extend .poppins_600;
                                @extend .font_18;
                                color: $dark_black;
                            }

                            span{
                                @extend .font_16;
                                color: $light_black;
                            }
                        }
                        span{
                            @extend .font_16;
                            color: $light_black;
                        }
                        .short_msg{
                            width: 100%;
                        }
                        .unread_msg_counter ~ .short_msg{
                            width: calc(100% - 5rem);
                        }
                        .unread_msg_counter {
                            background: $red;
                            color: $white !important;
                            padding: 0 .5rem;
                            line-height: 2rem;
                            border-radius: 1rem;
                            font-size: 1.5rem !important;
                            min-width: 2rem;
                            @include d_flex_center;
                            margin-left: 8px;
                        }
                    }
                }
            }

            .chat_sidebar_footer{
                background: $black;
                position: absolute;
                position: relative;
                bottom: 0;

                .chat_tab_btns{
                    @extend .d-flex;
                    @extend .align-items-center;
                    background: $black;

                    button{
                        min-height: 6rem;
                        width: 33.3%;
                        @extend .border-0;
                        background: transparent;
                    }
                }
            }
        }

        .chat_area{
            width: calc(100% - 38.1rem);
            @extend .position-relative;
            overflow: hidden;

            @media screen and (max-width: 992px) {
                width: calc(100% - 30rem);
            }

            @media screen and (max-width: 768px) {
                width: calc(100% - 0rem);
            }

            .chat_header{
                display: flex;
                padding: 1.8rem 3.5rem;
                border-bottom: .1rem solid $light_gray;

                @media screen and (max-width: 1280px) {
                    padding: 1.8rem 1.5rem;
                }
                @media screen and (max-width: 768px) {
                    // zoom: .8;
                    @include zoom(.8);
                    padding: 1rem 1.5rem;
                }

                .humberger_toggle{
                    display: none;
                    
                    @media screen and (max-width: 768px) {
                        &.hide{
                            display: none;
                        }
                        &.show{
                            display: block;
                        }
                    }
                }

                .chat_profile_pic{
                    @extend .position-relative;
                    @extend .radius_full;
                    
                    .dot{
                        border: .2rem solid $white;
                        height: 1.5rem;
                        width: 1.5rem;

                        position: absolute;
                        bottom: .2rem;
                        right: .2rem;
                    }
                }
                .chat_profile_name{
                    padding-left: 1.5rem;

                    h3{
                        @extend .poppins_600;
                        margin-bottom: 0;
                    }
                    p{
                        margin-bottom: 0;
                        color: $dark_gray;
                    }
                }
                .chat_close_minimize{
                    right: 1rem;
                    top: 2.5rem;
                    position: absolute;
                    @media screen and (max-width: 768px) {
                        top: 1.7rem;
                    }
                    
                    a{
                        display: inline-block;
                        padding: 1rem;
                    }

                    .chat_more_option{
                        display: inline-block;

                        @media screen and (min-width: 1281px) {
                            display: none;
                        }

                        .dropdown-menu{
                            left: auto;
                            right: 0;
                            top: calc(100% + 1rem);
                            font-size: 1.4rem;
                            min-width: 17rem;
                            border: 0.1rem solid rgba( $black , 0.05);
                            border-radius: .75rem;
                            @extend .p-2;
                            a{
                                @extend .font_16;
                                @extend .no_wrap;
                                @extend .mx-0;
                                color: $black;
                                border-radius: .75rem;

                                &:hover{
                                    background: $red_highlighted;
                                    color: $white;
                                }
                                @media screen and (max-width: 768px) {
                                    font-size: 1.8rem;
                                }
                            }
                        }
                    }
                }
            }

            .chat_and_emp_details{
                display: flex;
                @extend .fullWidth;
                @extend .position-relative;
                overflow: hidden;

                .chat_msgs_list_input{
                    position: relative;
                    width: 100%;

                    @media screen and (max-width: 1280px) {
                        width: calc(100% - 0rem);
                    }

                    .chat_details{
                        float:left;
                        padding: 3rem 4rem;

                        // height: calc(100vh - 21.3rem);
                        height: calc(100vh - 28.3rem);
                        overflow-y: auto;

                        @media screen and (max-width: 1280px) {
                            padding: 3rem 1.5rem;
                        }
                        @media screen and (max-width: 768px) {
                            height: calc(100vh - 20.6rem);
                        }

                        li{
                            &:not(:last-child){
                                margin-bottom: 2rem;
                            }
                        }
                        .chat_msgs{
                            .chat_received{
                                @extend .fullWidth;

                                & > div{
                                    width: 90%;
                                    max-width: 50rem;
                                    float: left;
                                }

                                .chat_profile_pic{
                                    margin-right: 1.5rem;
                                    @extend .radius_full;
                                }
                                .chat_section{
                                    display: flex;
                                    flex-direction: column;
                                    position: relative;
                                    padding-bottom: 1.5rem;
                                    width: 100%;
                                    align-items: flex-start;

                                    &::before{
                                        content: '';
                                        width: 4rem;
                                        height: 4rem;
                                        border-left: 4rem solid $msg_receive_box_bg;
                                        border-bottom: 3rem solid transparent;
                                        position: absolute;
                                        bottom: 0;
                                    }

                                    .chat_msg_box{
                                        background: $msg_receive_box_bg;
                                        margin-bottom: .5rem;
                                        padding: 1.6rem 3rem 2rem 2rem;
                                        border-radius: 2rem 2rem 2rem 0rem;
                                        @include boxShadow(0 .5rem 1rem rgba( $dark_black, 0.15));
                                        color: $white;
                                        @extend .poppins_300;
                                        position: relative;
                                        z-index: 1;

                                        @media screen and (max-width: 1440px) {
                                            font-size: 1.8rem;
                                        }
                                        @media screen and (max-width: 576px) {
                                            font-size: 1.6rem;
                                            padding: 1.5rem 1.5rem 1.5rem 1.5rem;
                                        }

                                        span{
                                            margin-top: 1rem;
                                        }
                                    }
                                }
                            }
                            .chat_sent{
                                @extend .fullWidth;

                                & > div{
                                    width: 90%;
                                    max-width: 50rem;
                                    float: right;
                                }

                                .chat_profile_pic{
                                    margin-left: 1.5rem;
                                    @extend .radius_full;
                                }
                                .chat_section{
                                    display: flex;
                                    flex-direction: column;
                                    position: relative;
                                    align-items: flex-end;
                                    padding-bottom: 1.5rem;
                                    width: 100%;

                                    &::before{
                                        content: '';
                                        width: 4rem;
                                        height: 4rem;
                                        border-left: 4rem solid $msg_sent_box_bg;
                                        border-bottom: 4rem solid transparent;
                                        position: absolute;
                                        bottom: 0;
                                        right: 0;
                                        -webkit-transform: scale(-1) rotate(-90deg); /* Safari & Chrome */
                                        -moz-transform: scale(-1) rotate(-90deg); /* Firefox */
                                        -ms-transform: scale(-1) rotate(-90deg); /* Internet Explorer */
                                        -o-transform: scale(-1) rotate(-90deg); /* Opera */
                                        transform: scale(-1) rotate(-90deg);
                                    }

                                    .chat_msg_box{
                                        background: $msg_sent_box_bg;
                                        margin-bottom: .5rem;
                                        padding: 1.6rem 3rem 2rem 2rem;
                                        border-radius: 2rem 2rem 0rem 2rem;
                                        color: $dark_black;
                                        @extend .poppins_300;
                                        position: relative;
                                        z-index: 1;

                                        @media screen and (max-width: 1440px) {
                                            font-size: 1.8rem;
                                        }
                                        @media screen and (max-width: 576px) {
                                            font-size: 1.6rem;
                                            padding: 1.5rem 1.5rem 1.5rem 1.5rem;
                                        }

                                        span{
                                            margin-top: 1rem;
                                            color: $scroll_thumb;
                                        }
                                    }
                                }
                            }

                            .chat_msg_img, .chat_msg_video{
                                width: 17rem;
                                padding: 1rem !important;
                                @extend .cursor_pointer;

                                & > img, & > video{
                                    width: 15rem;
                                    height: 12rem;
                                    @include img_object_fit_center;
                                    border-radius: 1rem 1rem 0 0;
                                }
                                .download_attached_img{
                                    height: 5rem;
                                    width: 5rem;
                                    @extend .position-absolute;
                                    top: 6rem;
                                    left: 6rem;
                                    background: rgba($dark_black, .5);
                                    display: none;
                                    border-radius: .5rem;
                                    img{
                                        height: 2.5rem;
                                    }
                                }
                                &:hover{
                                    .download_attached_img{
                                        @include d_flex_center;
                                    }
                                }
                            }
                            .chat_msg_audio{
                                width: 31.5rem;
                                padding: 1rem !important;
                            }
                            .chat_msg_doc, 
                            .chat_msg_pdf{
                                padding: 1rem !important;
                                border-radius: 1rem !important;
                                
                                .chat_msg_doc_file_name{
                                    display: flex;
                                    align-items: center;
                                    label{
                                        @include text_ellipsis;
                                        width: 15rem;
                                    }

                                    .doc_file_icon{
                                        display: flex;
                                        flex-direction: column;
                                        align-items: center;
                                        flex: 0 0 5.2rem;
                                        padding: .6rem;
                                        background: #ffd7d7;
                                        border-radius: .6rem;

                                        img{
                                            height: 2.5rem;
                                        }
                                        span{
                                            line-height: 1rem;
                                            color: $red_highlighted;
                                            @extend .font_14;
                                        }                                        
                                    }
                                }
                            }

                        }
                    }

                    // .chat_msg_input_box > 

                    .chat_msg_input_box{
                        @extend .fullWidth;
                        position: relative;
                        background: $white;
                        border-top: .1rem solid $light_gray;
                        padding: 1.2rem 3rem;
                        bottom: 0;
                        left: 0;
                        right: 0;

                        &:hover {
                            @include transition(all .3s ease-in-out);
                            .coming_soon_overlay{
                                display: flex;
                                @include transition(all .3s ease-in-out);
                            }
                        }

                        .attachment_list{
                            height: 7.2rem;
                            border: 1px solid #ddd;
                            border-bottom: 0;
                            border-radius: 1.5rem 1.5rem 0 0;
                            display: flex;
                            flex-wrap: nowrap;
                            overflow-x: auto;
                            
                            .attached_file{
                                width: 6rem;
                                height: 6rem;
                                flex: 0 0 6rem;
                                margin: .5rem;
                                @extend .cursor_pointer;
                                @extend .position-relative;
                                border-radius: .7rem;
                                overflow: hidden;
                                background: rgba($dark_black, .4);

                                display: flex;
                                flex-direction: column;
                                align-items: center;
                                justify-content: center;

                                & > img{
                                    height: 6rem;
                                    width: 6rem;
                                    @include img_object_fit_center;

                                    &.video_icon{
                                        max-height: 2.2rem;
                                        width: 2rem;
                                        object-fit: unset;
                                        object-position: unset;
                                    }
                                    &.audio_icon{
                                        max-height: 2.2rem;
                                        width: 2rem;
                                        object-fit: unset;
                                        object-position: unset;
                                    }
                                    &.doc_icon{
                                        max-height: 2.2rem;
                                        width: 2rem;
                                        object-fit: unset;
                                        object-position: unset;
                                    }
                                }
                                
                                & > label{
                                    margin: 0;
                                    font-size: 1.4rem;
                                    line-height: 1.4rem;
                                    margin-top: .4rem;
                                }

                                span{
                                    display: none;
                                    @extend .position-absolute;
                                    height: 100%;
                                    width: 100%;
                                    top: 0;
                                    background: rgba($dark_black, .4);
                                    @include transition(all 0.3s ease-in-out);

                                    img{
                                        height: 2rem;
                                        width: 2rem;
                                    }
                                }
                                &:hover{
                                    span{
                                        display: flex;
                                        justify-content: center;
                                        align-items: center;
                                    }
                                }

                            }

                        }
                        .attachment_list ~ .input_msg_box > input[type="text"]{
                            border-radius: 0 0 1.5rem 1.5rem;
                        }


                        input{
                            color: $dark_gray;
                            @extend .font_16;
                            padding: .5rem 10rem .5rem 2rem;
                            line-height: 2.4rem;
                            min-height: 3.6rem;
                        }
                        .msg_options{
                            @extend .position-absolute;
                            right: 1rem;
                            top: 0;

                            li{
                                margin: 0;
                                padding: .6rem;
                                img{
                                    display: inline-block;
                                    margin: 0;
                                    @extend .cursor_pointer;
                                }
                                button{
                                    padding: 0;
                                    border: 0;
                                    background: transparent;
                                    @extend .cursor_pointer;
                                }

                                .attach_doc{
                                    height: 2.3rem;
                                    width: 2rem;
                                    overflow: hidden;
                                    @extend .position-relative;

                                    [type="file"]{
                                        @extend .position-absolute;
                                        left: 0;
                                        top: 0;
                                        z-index: 99;
                                        opacity: 0;
                                        @extend .cursor_pointer;
                                    }
                                }
                            }
                        }
                    }
                }
                .chat_msgs_list_input + .hide{
                    right: -37rem;
                }
                .chat_msgs_list_input + .show{
                    right: 0;
                }
                .emp_details{
                    width: 37rem;
                    padding: 3rem;
                    border-left: .1rem solid $light_gray;
                    height: calc(100vh - 15.2rem);
                    overflow-y: auto;
                    // position: relative;
                    background: $white;
                    position: absolute;
                    right: -37rem;
                    bottom: 0;
                    z-index: 99;
                    @include transition(all .3s ease-in-out);

                    @media screen and (max-width: 1280px) {
                        &.hide{
                            position: fixed;
                            right: 0;
                            transform: translateX(100%);
                            opacity: 0;
                        }
                        &.show{
                            position: absolute;
                            right: 0;
                            transform: translateX(0%);
                            opacity: 1;
                            background: rgba( $white, .6);
                            border: 0;

                            @include transition(all 0.4s ease-in-out);
                            @include boxShadow(-1rem 0rem 2.5rem rgba( $dark_gray, .2));

                            @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
                                background: rgba($white, 0.9);
                                -webkit-backdrop-filter: blur(.8rem);
                                backdrop-filter: blur(.8rem);
                            }
                                
                            /* slightly transparent fallback for Firefox (not supporting backdrop-filter) */
                            @supports not ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
                                background: rgba($white, 0.95);
                            }

                        }
                    }
                    @media screen and (max-width: 768px) {
                        &.show{
                            height: calc(100vh - 12rem);
                        }
                    }
                    @media screen and (max-width: 576px) {
                        &.show{
                            width: 100%;
                            padding: 1.5rem;
                        }
                    }

                    .emp_profile_close{
                        position: absolute;
                        top: 1.5rem;
                        right: 1.5rem;
                        @extend .cursor_pointer;
                        background: $gray_status_border;
                        width: 3rem;
                        height: 3rem;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        border-radius: 5px;
                    }

                    .emp_profile_pic{
                        padding-top: 1.5rem;
                        margin-bottom: 4rem;
                        @extend .fullWidth;
                        @extend .text-center;

                        @media screen and (max-width: 1280px) {
                            margin-bottom: 2rem;
                            padding-top: 0;
                        }

                        img{
                            @media screen and (max-width: 1280px) {
                                width: 12rem;
                                height: 12rem;
                            }
                        }

                        p{
                            @extend .fullWidth;
                            color: $dark_gray;

                            span{
                                height: 1.1rem;
                                width: 1.1rem;
                                margin-right: .75rem;
                            }
                        }
                    }
                    .emp_details_list{
                        margin-bottom: 5rem;
                        @media screen and (max-width: 1280px) {
                            margin-bottom: 3rem;
                        }
                        li{
                            &:not(:last-child){
                                margin-bottom: 3.5rem;
                                @media screen and (max-width: 1280px) {
                                    margin-bottom: 2rem;
                                }
                            }
                            label{
                                color: $light_black;
                            }
                            p{
                                color: $black;
                                @extend .font_17;
                            }
                        }
                    }
                }
            }

            &.welcome_chat{
                background: url(../images/welcome_chat_bg.png) no-repeat;
                background-size: cover;
                background-position: bottom center;
                padding: 7rem 2rem;
                height: 100vh;
                @media screen and (max-width: 768px) {
                    padding: 0 2rem 1.5rem;
                    min-height: 100vh;
                }

                .chat_header {
                    padding: 0;
                    border: 0;

                    .chat_close_minimize{
                        top: .5rem;
                    }
                }
                .welcome_content{
                    @extend .text-center;
                    @extend .fullWidth;
                    @media screen and (max-width: 768px) {
                        padding-top: 5rem;
                    }

                    .ampliteach_logo{
                        margin-bottom: 3rem;
                        img{
                            @media screen and (max-width: 576px) {
                                width: 17.5rem;
                            }
                        }
                    }

                    h2{
                        @extend .font_32;
                        line-height: 4.8rem;
                        margin-bottom: 1.5rem;

                        @media screen and (max-width: 576px) {
                            font-size: 2.2rem;
                            line-height: 3rem;
                        }
                    }

                    p{
                        @media screen and (max-width: 576px) {
                            line-height: 1.8rem;
                        }
                    }
                    
                    .welcome_profile{
                        margin-top: 5rem;
                        margin-bottom: 1.5rem;
                        @media screen and (max-width: 1024px) {
                            margin-top: 0;
                        }
                        @media screen and (max-width: 576px) {
                            margin-top: 2rem;
                        }
                        img{
                            @include img_object_fit_center;
                            @extend .profile_image_border_n_shadow;
                            @extend .radius_full;
                            height: 11rem;
                            width: 11rem;
                            @media screen and (max-width: 1024px) {
                                height: 8rem;
                                width: 8rem;
                            }
                        }
                    }

                    h3{
                        @extend .font_24;
                        line-height: 2.9rem;
                        @extend .poppins_600;
                        @media screen and (max-width: 576px) {
                            font-size: 1.8rem;
                            line-height: 2.4rem;
                        }
                    }

                }
            }
            .blank_chat{
                .welcome_profile{
                    margin-top: 5rem;
                    margin-bottom: 1.5rem;
                    @media screen and (max-width: 576px) {
                        margin-top: 2rem;
                    }
                    img{
                        @include img_object_fit_center;
                        @extend .profile_image_border_n_shadow;
                        @extend .radius_full;
                        height: 11rem;
                        width: 11rem;
                    }
                }

                h3{
                    @extend .font_24;
                    line-height: 2.9rem;
                    @extend .poppins_600;
                    @extend .text-capitalize;
                    margin-bottom: 4rem;
                    @media screen and (max-width: 576px) {
                        font-size: 1.8rem;
                        line-height: 2.4rem;
                    }
                }
                p{
                    span{
                        background: $green;
                        @extend .font_20;
                        @extend .cursor_pointer;
                        padding: 1rem 2rem;
                        border-radius: .6rem;
                        color: $white;
                    }
                }
            }
        }
    }
    .emoji-mart{
        position: absolute;
        bottom: 4.7rem;
        right: 3rem;
        z-index: 9;
    }
}

.overlay{
    
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: #00000050;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 21;
    transition: all 0.5s ease-in-out;
    display: none;

}

.showChat {
    display: block !important;
}