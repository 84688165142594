.topbar {
  padding-top: 1rem;
  padding-bottom: 3rem;

  @media screen and (max-width: 991px) {
    padding: 0 1.5rem 1rem;
    position: fixed;
    z-index: 9;
    left: 0;
    // zoom: 0.9;
    @include zoom(0.9);
    @supports not (zoom: 1) {
      width: 112%;
    }

    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      background: linear-gradient(
        117.76deg,
        rgba($white, 0.7) -7.21%,
        rgba($white, 0.5) 118.08%
      );
      -webkit-backdrop-filter: blur(0.8rem);
      backdrop-filter: blur(0.8rem);
    }
    @supports not ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
      background: linear-gradient(
        117.76deg,
        rgba($white, 0.95) -7.21%,
        rgba($white, 0.95) 118.08%
      );
    }
  }

  @media screen and (max-width: 680px) {
    // zoom: .8;
    @include zoom(0.8);
    @supports not (zoom: 1) {
      width: 126%;
    }
  }

  h1 {
    width: auto;
    margin-bottom: 0;
    margin-top: 2rem;
    line-height: 4rem;
    @extend .text-capitalize;

    @media screen and (max-width: 991px) {
      margin-top: 1rem;
    }
  }

  .dropdown-menu {
    font-size: 1.4rem;
    min-width: 17rem;
    border: 0.1rem solid rgba($dark_black, 0.05);
    border-radius: 0.75rem;
    @extend .p-2;

    .dropdown-item {
      padding: 0.75rem 1.5rem;
      margin-bottom: 0.5rem;

      @media screen and (max-width: 991px) {
        font-size: 1.8rem;
      }
      @media screen and (max-width: 767px) {
        font-size: 2rem;
      }

      &:hover {
        background: $red_highlighted;
        color: $white;
        @extend .radius_10;

        i {
          color: $white;
        }
      }
      i {
        color: $scroll_thumb;
        @include transition(all 0.3s ease-in-out);
      }
    }
  }

  .option_humberger_toggle {
    @include option_humberger_toggle;
  }

  .topbar_right {
    @media screen and (max-width: 767px) {
      justify-content: space-around !important;
    }

    .searchbar {
      width: 100%;
      max-width: 50rem;
      @media screen and (max-width: 1680px) {
        max-width: 46.5rem;
      }
      @media screen and (max-width: 1440px) {
        max-width: 30rem;
      }
      @media screen and (max-width: 991px) {
        max-width: 100%;
        padding-left: 1rem;
        // zoom: 1.2;
        // @include zoom(1.2);
      }
      @media screen and (max-width: 767px) {
        max-width: 7rem;
      }
      @media screen and (max-width: 576px) {
        // zoom: 1;
        @include zoom(1);
      }

      .search_icon_device {
        float: right;
        border: 0.1rem solid $light_gray;
        height: 4rem;
        @extend .p-0;
        @include transition(all 0.3s ease-in-out);
        width: 5.3rem;
        @include d_flex_center;
        @extend .radius_50;
        background-color: $white;
      }

      form {
        @include transition(all 0.3s ease-in-out);
        position: relative;
        float: right;

        input {
          width: 100%;
          float: right;
          border: 0.1rem solid $light_gray;
          box-sizing: border-box;
          @extend .radius_50;
          @extend .font_14;
          line-height: 2.4rem;
          padding: 0.7rem 1rem 0.7rem 5.2rem;
          height: auto;
          @include transition(all 0.3s ease-in-out);

          @media screen and (max-width: 1440px) {
            padding: 0.7rem 1rem 0.7rem 4.2rem;
          }
          @media screen and (max-width: 576px) {
            height: 5rem;
          }
        }
        .search_icon {
          top: 50%;
          left: 2rem;
          @include translateY(-50%);
          @include transition(all 0.3s ease-in-out);
        }
        @media screen and (max-width: 1440px) {
          float: right;
          width: 5.3rem;

          &:hover {
            width: 100%;
            @include transition(all 0.3s ease-in-out);
          }
        }

        @media screen and (max-width: 991px) {
          @include zoom(1.2);

          @supports not (zoom: unset) {
          }
        }
        @media screen and (max-width: 767px) {
          position: absolute;
          width: 100%;
          // max-width: 50rem;
          top: calc(100% + 0.1rem);
          background: $white;
          padding: 1rem;
          border-radius: 0 0 1rem 1rem;

          left: 0;
          right: 0;
          max-width: 100%;
          @include transition(all 0.3s ease-in-out);
          @include boxShadow(0 1rem 1rem rgba($black, 0.1));

          @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
            background: rgba($white, 0.9);
            -webkit-backdrop-filter: blur(0.8rem);
            backdrop-filter: blur(0.8rem);
          }

          /* slightly transparent fallback for Firefox (not supporting backdrop-filter) */
          @supports not (
            (-webkit-backdrop-filter: none) or (backdrop-filter: none)
          ) {
            background: rgba($white, 0.9);
          }

          input {
            font-size: 1.8rem;
          }
        }
        // @media screen and (max-width: 576px) {
        //     left: 0;
        //     right: 0;
        //     max-width: 100%;
        // }
      }
    }

    .teachers_option {
      margin-left: 2rem;
      @media screen and (max-width: 991px) {
        // zoom:1.1;
        @include zoom(1.1);
      }
      @media screen and (max-width: 576px) {
        // zoom:1;
        @include zoom(1);
        margin-left: 1rem;
      }

      .add_course_icon {
        height: 4rem;
        width: 4rem;

        @include d_flex_center;
        background: linear-gradient(
          339.44deg,
          $red_highlighted 22.85%,
          rgba($red_highlighted, 0.5) 87.92%
        );
        @extend .radius_full;
        color: $white;
      }
    }
    .dropdown-menu {
      left: 0;
      right: auto;
      margin-top: 1.9rem;

      .dropdown-item {
        @media screen and (max-width: 768px) {
          font-size: 1.8rem;
        }
      }
    }

    .teachers_option + .notifications {
      margin-left: 1rem;
      margin-right: 1rem;
      font-size: 20px;
      @media screen and (max-width: 576px) {
        margin-left: 1rem;
      }
    }

    .notifications {
      margin-left: 6rem;

      @media screen and (max-width: 1680px) and (min-width: 992px) {
        margin-left: 2rem;
      }
      @media screen and (max-width: 991px) {
        // zoom:1.5;
        @include zoom(1.5);
      }
      @media screen and (max-width: 576px) {
        // zoom:1.3;
        @include zoom(1.3);
      }
      a{
        .badge{
          top: -8px;
          left: 40%;
          border-radius: 18px;
          font-size: 14px;
        }
      }
      // a {
      //   float: left;
      //   width: 3.4rem;
      //   display: flex;
      //   img {
      //     float: left;
      //   }
      //   .badge {
      //     @extend .position-relative;
      //     top: -1.2rem;
      //     left: -1.1rem;
      //     float: left;
      //     height: 2.4rem;
      //     width: 2.4rem;
      //     @include d_flex_center;
      //     @extend .font_14;
      //     font-weight: normal;
      //     border: 0.2rem solid $white;

      //     @media screen and (max-width: 680px) {
      //       // zoom:.8;
      //       @include zoom(0.8);
      //     }
      //   }
      // }

      .dropdown-menu {
        max-width: 35rem;
        min-width: 25rem;
        left: auto;
        right: -15rem;
        top: 3.2rem;
        @media screen and (max-width: 576px) {
          right: -19.5rem;
          top: 2.2rem;
        }
      }
      .activity {
        &_profile_img {
          width: 6rem;
          img {
            height: 3.5rem;
            width: 3.5rem;
            @include boxShadow(0 0 .2rem rgba($dark_black, .5));
          }
          .dot {
            top: -0.3rem;
            right: 1.1rem;
            border: .2rem solid $white;
            height: 1.2rem;
            width: 1.2rem;
          }
        }
        &_details {
          width: calc(100% - 6rem);
          padding: 0 0 0 0.6rem;
          p {
            line-height: 1.6rem;
          }
        }
      }
    }

    .help {
      margin-left: 1.5rem;
      @media screen and (max-width: 1680px) and (min-width: 992px) {
        margin-left: 1.2rem;
      }
      @media screen and (max-width: 991px) {
        // zoom:1.7;
        @include zoom(1.7);
        margin-left: 0.5rem;
      }
      @media screen and (max-width: 576px) {
        // zoom:1.6;
        @include zoom(1.6);
        margin-left: 0.5rem;
      }
      a {
        width: 2rem;
      }
    }

    .messages {
      margin-left: 3rem;
      @media screen and (max-width: 1680px) {
        margin-left: 2.5rem;
      }
      @media screen and (max-width: 991px) {
        // zoom:1.5;
        @include zoom(1.5);
        @supports not (zoom: unset) {
          // width: 126%;
        }
      }
      @media screen and (max-width: 576px) {
        // zoom:1.4;
        @include zoom(1.4);
        margin-left: 1.5rem;
      }
      a {
        width: 3.5rem;
        float: left;
      }
      .dot {
        width: 1.4rem;
        height: 1.4rem;
        border: 0.2rem solid $white;
        @extend .position-relative;
        top: -0.7rem;
        right: 0.7rem;
      }
    }

    .profile {
      margin-left: 1rem;
      @media screen and (max-width: 1536px) {
        margin-left: 0;
      }
      .nav-link {
        @media screen and (max-width: 576px) {
          padding-left: 0;
        }
        .profile_pic {
          .admin_profile_pic {
            height: 6.5rem;
            width: 6.5rem;
            border-radius: 100%;
            overflow: hidden;
          }
          img {
            @include img_object_fit_center;
            height: 100%;
            width: 100%;
            border: 0;
          }
          .dot {
            height: 1.5rem;
            width: 1.5rem;
            border: 0.2rem solid $white;
            bottom: 1rem;
            right: 0;
          }
          .cross_btn {
            @include cross_btn;
            display: none;
            @extend .cursor_pointer;
          }
        }
        .profile_name {
          padding-left: 0.5rem;
          display: flex;
          align-items: center;
          span {
            float: right;
            margin-left: 1.5rem;
            @extend .text-capitalize;
            @media screen and (max-width: 1536px) {
              margin-left: 0.5rem;
            }

            strong {
              max-width: 15rem;
              font-size: large;
              @extend .fullWidth;
              @extend .text_ellipsis;
              @media screen and (max-width: 768px) {
                font-size: 1.7rem;
              }
              @media screen and (max-width: 576px) {
                max-width: 10rem;
              }
              @media screen and (max-width: 375px) {
                max-width: 9rem;
              }
            }

            label {
              display: block;
              font-size: medium;
              color: $dark_gray;
              @extend .font_15;
              line-height: 1.5rem;
              clear: both;
              // font-size: 15px;
              max-width: 15rem;
              @extend .fullWidth;
              @extend .text_ellipsis;

              @media screen and (max-width: 991px) {
                font-size: 1.6rem;
                margin-bottom: 0.5rem !important;
              }
              @media screen and (max-width: 576px) {
                max-width: 10rem;
              }
              @media screen and (max-width: 375px) {
                max-width: 9rem;
              }
            }
          }

          @media screen and (max-width: 1440px) {
            margin-left: 1rem;
            display: flex;
            flex-wrap: nowrap;
          }
          @media screen and (max-width: 576px) {
            margin-left: 0;
          }
        }

        &.login_sub_member {
          .admin_profile_pic {
            border: 0.3rem solid $dark_green !important;
          }
          .cross_btn {
            display: inline-flex;
            @extend .cursor_pointer;
          }
          .dot {
            display: none;
          }
        }

        &::after {
          content: unset;
        }
      }
    }

    &.tobpar_toggle {
      position: relative;
      @media screen and (max-width: 991px) {
        position: absolute;
        width: 98%;
        top: 70px;
        left: 1%;
        right: 0;
        background: $white;
        z-index: 9;
        padding: 1rem 0;
        // zoom: .85;
        @include zoom(0.85);

        @supports not (zoom: 1) {
          width: 114%;
        }

        border-bottom: 0.1rem solid $white;
        background: rgba($white, 0.95);
        @include transition(all 0.6s ease-in-out);
        @include boxShadow(0 1rem 2rem rgba($black, 0.2));
      }
      // @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      //     background: rgba($white, 0.95);
      //     -webkit-backdrop-filter: blur(.8rem);
      //     backdrop-filter: blur(.8rem);
      // }

      // /* slightly transparent fallback for Firefox (not supporting backdrop-filter) */
      // @supports not ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
      //     background: rgba($white, 0.95);
      // }

      @media screen and (max-width: 767px) {
        padding: 0;
      }
    }
  }
}
