.swal2-container {
    &.swal2-backdrop-show {
        background: rgba($dark_black, .62);
    }

    .swal2-popup {
        font-size: 1.5rem;
        @extend .radius_10;
    }

    .swal2-content {
        @extend .font_16;
    }

    .swal2-actions {
        .swal2-confirm {
            @extend .btn_primary;
            @extend .border-0;
        }
    }

    .swal2-icon {
        &.swal2-success {
            .swal2-success-ring {
                border: .25em solid $green;
            }

            [class^=swal2-success-line] {
                background-color: $green;
            }
        }
    }
}


.no_data_found {
    @extend .mt-50;
    @extend .mb-50;
}

.plus_font {
    @extend .font_30;
    @extend .poppins_300;
}

.discount_icon,
.percentage {
    input {
        padding-right: 3.5rem;
        @extend .text-right;
    }

    span {
        @extend .position-absolute;
        top: .8rem;
        right: 2rem;
    }
}

.schedule_doller {
    input {
        padding-right: 3.5rem !important;
        text-align: left !important
    }

    span {
        @extend .position-absolute;
        top: .8rem;
        left: 2rem;
        width: 10px;
    }
}

.transaction_doller {
    input {
        padding-right: 3.5rem !important;
        text-align: left !important
    }

    span {
        @extend .position-absolute;
        top: .8rem;
        left: 3rem;
        width: 10px;
    }
}

.search {
    input {
        padding-right: 3.5rem;
        @extend .text-left;
    }

    span {
        @extend .position-absolute;
        top: .8rem;
        right: 2rem;
    }
}


.choose_credicard {
    .dropdown-menu {
        font-size: 1.4rem;
        min-width: 17rem;
        border: .1rem solid rgba($black, .05);
        border-radius: .75rem;
        @extend .p-2;
        left: auto;
        right: 0;

        .dropdown-item {
            padding: .75rem 1.5rem;
            margin-bottom: .5rem;
            @extend .radius_10;

            &:hover {
                background: $red_highlighted;
                color: $white;
            }
        }
    }
}


.upload_link_modal {
    .data_table {
        table {
            min-width: auto;
        }
    }
}


.choose_file {
    input {
        @extend .position-absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        opacity: 0;
        width: 100%;
    }
}


.file_upload_icons {
    @extend .d-flex;
}