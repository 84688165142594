.product_details {
    &_content {
        p {
            line-height: 3rem;
        }
    }
    &_image {
        text-align: center;
        img {
            width: 100%;
            max-width: 65rem;
            max-height: 65rem;
            @include img_object_fit_center;
        }
    }
    .slider_thumb {
        .slick-slide {
            width: 8rem !important;
            @extend .mr-3;
            .product_details_thumb_image {
                width: 8rem !important;
                img {
                    width: 8rem;
                    height: 8rem;
                    @include img_object_fit_center;
                    border-radius: .3rem;
                    @extend .mb-2;
                    border: .1rem solid #9F9F9F;
                    @extend .cursor_pointer;
                    @include transition(all .3s ease-in-out);
                    &:not(:last-child) {
                        @extend .mr-3;
                    }
                    @media screen and (max-width: 768px) {
                        width: 6rem;
                        height: 6rem;
                    }
                }
            }
            &:hover,
            &.slick-current {
                .product_details_thumb_image {
                    img {
                        border-color: $red_highlighted;
                        box-shadow: 0 0 .2rem .1rem $red_highlighted;
                    }
                }
            }
        }
    }
}