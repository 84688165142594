.lesson_video_desc {
    width: calc(100% - 47.5rem);
    @extend .float-left;
    padding: 0 1rem 0 0;

    @media screen and (max-width: 1536px) {
        width: calc(100% - 35rem);
    }

    @media screen and (max-width: 992px) {
        width: 100%;
        padding: 0;
    }

    .lesson_video_area {
        @media screen and (max-width: 1280px) {
            // zoom: .7;
        }

        iframe,
        video {
            @extend .float-left;
            @include img_object_fit_center;

            @media screen and (max-width: 1080px) {
                // zoom: .7;
                height: 45rem;
            }

            @media screen and (max-width: 768px) {
                // zoom: .7;
                height: 35rem;
            }
        }
    }

    &.view_practice_detail {
        @media screen and (max-width: 992px) {
            padding-right: 3rem;
        }

        @media screen and (max-width: 576px) {
            padding-right: 2rem;
        }
    }

    .lesson_view_document {
        @media screen and (max-width: 1280px) {
            // zoom: .7;
            @include zoom(.7);
        }
        .iframe_wrapper{
            position: relative;
            padding-bottom: 56.25%;
            padding-top: 25px;
            height: 0;
            iframe {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
            }
        }
        .lesson_view_document_card{
            background: $thin_light_pink;
            @extend .d-flex;
            @extend .align-items-center;
            @extend .justify-content-space-between;
            @extend .flex-direction-column;
            min-height: 53rem;
            padding: 5rem 3rem;

            h2 {
                @extend .font_36;
            }

            p {
                @extend .font_20;
            }
        }

        .scroll-wrapper {
            overflow-y: scroll;
            -webkit-overflow-scrolling: touch;
            width: 100%;
            height: 570px;
        }
    }

    .lesson_view_audio {
        @media screen and (max-width: 1280px) {
            // zoom: .7;
            @include zoom(.7);
        }

        .lesson_view_audio_card {
            background: $maroon;
            @extend .d-flex;
            @extend .align-items-center;
            @extend .justify-content-space-between;
            @extend .flex-direction-column;
            min-height: 53rem;
            padding: 5rem 7rem;

            h2 {
                @extend .font_30;
                line-height: 3.8rem !important;
                color: $white;

                @media screen and (max-width: 767px) {
                    font-size: 2.4rem;
                    line-height: 3rem;
                }
            }

            .audio_play_pause_animation {
                .audio_icon {
                    background: url(../images/audio_bg.svg) repeat-x;
                    background-size: contain;
                    position: relative;
                    min-height: 17rem;

                    .icon_timer_play {
                        width: 7rem;
                        height: 7rem;
                        background: $white;
                        @extend .radius_full;
                        // @extend .d-inline-block;
                        @include d_flex_center;

                        img {
                            width: 2rem;
                            height: 3rem;
                        }
                    }

                    .audio_section {
                        height: 7rem;
                        width: 7rem;
                        display: inline-block;
                        position: absolute;
                        border-radius: 100%;
                        overflow: hidden;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        top: 50%;
                        opacity: 0;
                        cursor: pointer;
                    }

                    audio {
                        display: none;
                    }
                }

                .audio_animation {
                    @include audio_playing_animation;

                    @media screen and (max-width: 576px) {
                        // zoom: .7;
                        @include zoom(.7);
                    }
                }
            }

            .audio_progressbar_duration {
                .progress {
                    display: flex;
                    height: 0.3rem;
                    background-color: rgba(255, 255, 255, .4);
                }

                .audio_duration {
                    color: $white;
                    @extend .d-flex;
                    @extend .justify-content-space-between;
                    @extend .font_20;
                }
            }
        }
    }

    .lesson_view_image {
        @media screen and (max-width: 1280px) {
            // zoom: .7;
            @include zoom(.7);
        }

        .lesson_view_image_card {
            img {
                height: 53rem;
                width: 100%;
                @include img_object_fit_center;

                @media screen and (max-width: 767px) {
                    height: auto;
                    width: 100%;
                    max-height: 53rem;
                }
            }
        }
    }

    .card {
        background: $white;
        filter: drop-shadow(0 0.3rem 0.7rem rgba($dark_black, 0.1));

        &.practice_stepper {
            background: $gray;
            filter: unset;

            .asign_practice_stepper {
                li {
                    width: 50%;

                    &:before {
                        background-color: $dark_gray;
                    }

                    .progress-step {
                        border-top: 0.3rem solid #ff0000;
                    }
                }

                &+.form-step {
                    .course_list {
                        .card {
                            &.selected {
                                filter: drop-shadow(0 0.0rem 0.4rem rgba(255, 0, 0, 0.5));
                            }
                        }
                    }
                }


            }
        }

        ul {
            li {
                list-style: none;

                &:before {
                    content: '-';
                    position: absolute;
                    left: 0.6rem;
                }
            }
        }
    }

    .lesson_files_links {
        .file_icons {
            width: 3.5rem;

            .badge {
                background: $red_highlighted;
                color: $white;
                @extend .position-absolute;
                right: -0.2rem;
                top: -1.2rem;
                @extend .radius_full;
                border: .2rem solid $white;
            }
        }

        .link_sm_fullWidth {
            @media screen and (max-width: 680px) {
                width: 100%;
                float: left;
                margin-top: 20px;
            }
        }
    }
}

.lesson_list_addOns {
    width: 47.5rem;
    @extend .float-left;
    padding: 0 2rem;

    @media screen and (max-width: 1536px) {
        width: 35rem;
        padding-right: 0;
    }

    @media screen and (max-width: 768px) {
        width: 100%;
        padding-right: 0;
    }

    @media screen and (max-width: 576px) {
        width: 100%;
        padding-left: .5rem;
    }

    .lesson_video_lists {

        .active_lesson_viewmore {
            padding: 1.5rem 1.5rem 0rem 1.5rem;

            .play_icon {
                span {
                    background: $red_highlighted;
                }
            }

            .active_lesson_name {
                @extend .poppins_600;
                color: $red_highlighted;
            }

            .view_more_lesson {
                img {
                    transform: rotate(0deg);
                    @include transition(all .3s ease-in-out);
                }
            }
        }

        .play_icon {
            width: 100%;
            max-width: 5.5rem;

            @media screen and (max-width: 576px) {
                max-width: 4.5rem;
            }

            span {
                @include d_flex_center;
                height: 3.5rem;
                width: 3.5rem;
                background: $dark_green;
                @extend .radius_full;
                @include transition(all .3s ease-in-out);
                ;
            }

            img {
                width: 1.3rem;
            }
        }

        ul {
            max-height: 43rem;
            overflow-y: auto;
            @include transition(all 0.3s ease-in-out);

            li {
                @extend .fullWidth;

                .lesson_video_list {
                    @extend .fullWidth;
                    background: $white;
                    padding: .9rem 1rem;
                    border-radius: 2rem;
                    @include transition(all .3s ease-in-out);

                    .lesson_name_number {
                        width: calc(100% - 5.5rem);
                        color: $dark_gray;

                        @media screen and (max-width: 576px) {
                            width: calc(100% - 4.5rem);
                        }

                        .lesson_name {
                            @extend .font_18;
                            line-height: 2.4rem;
                            @extend .poppins_600;
                            @extend .text_ellipsis;

                            span {
                                &:first-child {
                                    @include text_ellipsis;
                                    width: calc(100% - 110px);

                                    float: left;
                                    // overflow: hidden;
                                    // text-overflow: ellipsis;
                                }
                            }
                        }
                    }

                    &.active,
                    &:hover {
                        background: $light_pink;

                        .play_icon {
                            span {
                                background: $red_highlighted;
                            }
                        }
                    }
                }
            }
        }

        &.list_shadow {
            background: $white;
            @include boxShadow(0 .4rem 3rem rgba(163, 163, 163, .25));
            border-radius: 2rem;
            padding-bottom: 1.5rem;
        }

        .active_lesson_viewmore+ul {
            display: none;
        }

        .toggled {
            &+ul {
                display: block;

                li:first-child {
                    margin-top: 3rem;
                }
            }

            .view_more_lesson {
                img {
                    transform: rotate(180deg);
                }
            }
        }
    }

    &.practice_addOns {
        @media screen and (max-width: 992px) {
            width: 100%;
            padding: 0;
        }

        .lesson_video_list {
            .lesson_name {
                span {
                    img {
                        filter: grayscale(100%);
                    }
                }
            }

            &.active,
            &:hover {
                .lesson_name {
                    span {
                        img {
                            filter: grayscale(0%);
                        }
                    }
                }
            }
        }
    }

    .practice_tabs {
        .table {

            th,
            td {
                @extend .border-0;

                h2 {
                    word-break: break-word;
                }
            }

            th {
                align-items: top;
            }
        }
    }
}

.flex {
    margin-top: 25px;
    cursor: pointer;
    flex-direction: row;
    display: flex;
    gap: 15px;
    align-items: center;
}

.mb-25 {
    margin-bottom: 25px;
}