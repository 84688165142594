@import '~bootstrap-scss/bootstrap.scss';
@import 'poppins-fonts';
@import 'font-awesome';
@import 'variables';
@import 'keyframes';
@import 'mixin';
@import 'classes';
@import 'blocks';
@import 'common';
@import 'table';
@import 'modal';
@import 'header';
@import 'footer';
@import 'sidebar';
@import 'dashboard';
@import 'members';
@import 'sub_admin';
@import 'teachers';
@import 'students';
@import 'parents';
@import 'groups';
@import 'school';
@import 'viewProfile';
@import 'chat';
@import 'login';
@import 'learning';
@import 'courseSteps';
@import 'assignPractice';
@import 'lesson_details';
@import 'library';
@import 'general';
@import 'report';
@import 'payroll';
@import 'store';
@import 'productDetails';
@import 'schedule';
@import 'student_billing';
@import 'media';
@import 'userProfile';
@import 'invoice';
@import 'notification';
@import 'extra';
@import 'paymentDetils';
@import 'antd';

// @import '~bootstrap/dist/css/bootstrap.min.css';
@import '~react-circular-progressbar/dist/styles.css';
@import '~sweetalert2/src/sweetalert2.scss';
@import '~react-bootstrap-typeahead/css/Typeahead.css';
@import "~react-datepicker/dist/react-datepicker.css";
@import '~react-big-calendar/lib/css/react-big-calendar.css';
// @import '~react-big-calendar/lib/sass/styles';
@import "~react-image-crop/dist/ReactCrop.css";
// @import "~@fullcalendar/daygrid/main.css";
// @import "~@fullcalendar/timegrid/main.css";
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

@import '~emoji-mart/css/emoji-mart.css';
@import 'react-toastify/dist/ReactToastify.css';